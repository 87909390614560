import { useEffect, useState } from 'react';

import EmptyIcon from '@Assets/Images/empty-box.svg';
import AlertServices from '@Services/Alert.services';
import CustomBreadcrumbs from '@Shared/Breadcrumbs/Breadcrumbs';
import Loading from '@Shared/Loading/Loading';
import ServerError from '@Shared/ServerError/ServerError';
import CustomSnackbar from '@Shared/Snackbar/Snackbar';
import Table from '@Shared/Table/Table';
import { showErrorAlert } from '@Utils/alerts';
import { NOTIFICATIONS_COLUMNS, PAGE_PROP, PARSE_INT_BASE_NUMBER, PATHS } from '@Utils/constants';
import { getDateString } from '@Utils/dates';
import { LABELS } from '@Utils/labels';
import { AlertColor, Grid } from '@mui/material';
import { useLocation, useSearchParams } from 'react-router-dom';

const { EMPTY_MESSAGES } = LABELS;

const Notifications = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isMessageOpen, setIsMessageOpen] = useState(false);
    const [notificationsInfo, setNotificationsInfo] = useState({ docs: [], totalPages: 0 });
    const [dataObtainFailed, setDataObtainFailed] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState(LABELS.ALERTS.SEVERITIES.SUCCESS);
    const [searchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(1);
    const location = useLocation();

    const showMessage = (pMessage: string, pSeverity: string) => {
        setAlertMessage(pMessage);
        setAlertSeverity(pSeverity);
        setIsMessageOpen(true);
    };

    const prepNotifications = (notifications: any) => {
        notifications.date = getDateString(notifications.date);
        return notifications;
    };

    const getNotificationsInfo = () => {
        setDataObtainFailed(false);
        setIsLoading(true);
        AlertServices.updateAlerts(currentPage, true)
            .then(({ data }) => {
                data.docs = data.docs.map(prepNotifications);
                setNotificationsInfo(data);
            })
            .catch(({ response }) => {
                showErrorAlert(showMessage, response);
                setDataObtainFailed(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getNotificationsInfo();
    }, [currentPage]);

    useEffect(() => {
        if (searchParams.get(PAGE_PROP)) {
            setCurrentPage(parseInt(searchParams.get(PAGE_PROP) || '', PARSE_INT_BASE_NUMBER));
        }
    }, [location]);

    const handleCloseMessage = () => {
        setIsMessageOpen(false);
    };

    const renderMainComponent = (loading: boolean, failed: boolean) => {
        if (loading) {
            return <Loading />;
        }
        if (failed) {
            return <ServerError />;
        }
        return (
            <Grid item xs={12} sm={12} md={12}>
                <Table
                    columns={NOTIFICATIONS_COLUMNS}
                    details
                    detailsLabel={LABELS.NOTIFICATIONS.TABLE.DETAILS}
                    rows={notificationsInfo.docs}
                    emptyMessage={EMPTY_MESSAGES.NOTIFICATIONS}
                    emptyIcon={EmptyIcon}
                    detailsPath={PATHS.ASSETS}
                    idField="asset"
                    page={currentPage}
                    updatePage={setCurrentPage}
                    itemCount={notificationsInfo.totalPages}
                />
            </Grid>
        );
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
                <CustomBreadcrumbs
                    current={LABELS.NOTIFICATIONS.TITLE}
                    previous={{ route: PATHS.HOME, name: LABELS.MAINSCREEN.REDIRECT_TITLE }}
                />
                <h1>{LABELS.NOTIFICATIONS.TITLE}</h1>
            </Grid>
            {renderMainComponent(isLoading, dataObtainFailed)}
            <CustomSnackbar
                isOpen={isMessageOpen}
                handleClose={handleCloseMessage}
                alertMessage={alertMessage}
                alertSeverity={alertSeverity as AlertColor}
            />
        </Grid>
    );
};

export default Notifications;
