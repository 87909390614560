import { ExcelAsset } from '@Types/Asset.types';
import CustomModal from '@Shared/Modal/Modal';
import './AssetUploadModal.scss';
import { ReactNode, useEffect, useRef, useState } from 'react';
import AssetServices from '@Services/Asset.services';
import { LABELS } from '@Utils/labels';
import { ALERT_SEVERITIES, EXCEL_ASSET_FIELD_NAMES } from '@Utils/constants';
import { CheckCircleOutline, ErrorOutline, HourglassEmpty } from '@mui/icons-material';
import CustomButton from '@Shared/Button/Button';

type Props = {
    data: Partial<ExcelAsset>[] | null;
    isOpen: boolean;
    onClose: (...props: any) => any;
    setAlert: (...props: any) => any;
    onCreate: (...props: any) => any;
};

const { UPLOAD_ASSET, BUTTONS } = LABELS;

const AlwaysScrollToBottom = () => {
    const elementRef = useRef<null | HTMLDivElement>(null);
    useEffect(() => elementRef.current?.scrollIntoView());
    return <div ref={elementRef} />;
};

const AssetUploadModal = ({ data, isOpen, onClose, setAlert, onCreate }: Props) => {
    const block = 'upload_modal';
    const [progress, setProgress] = useState<string>('');
    const [statuses, setStatuses] = useState<{ [key: number]: ReactNode }>({});
    const [allowClose, setAllowClose] = useState<boolean>(false);

    useEffect(() => {
        if (!isOpen) {
            reset();
            return;
        }

        if (!data || data.length === 0) {
            setAlert(UPLOAD_ASSET.ERROR.NO_DATA, ALERT_SEVERITIES.ERROR);
            onClose();
            return;
        }

        setAllowClose(false);

        const runQueue = async () => {
            let successful = 0;
            for (let index = 0; index < data.length; index++) {
                setProgress(
                    formatProgress(UPLOAD_ASSET.PROCESSING, [
                        (index + 1).toString(),
                        data.length.toString()
                    ])
                );
                const asset = data[index];
                const success = await processAsset(asset, index);

                if (success) successful++;
            }
            const progress = formatProgress(UPLOAD_ASSET.COMPLETE, [
                successful.toString(),
                data.length.toString(),
                (data.length - successful).toString()
            ]);

            setProgress(progress);
            setAllowClose(true);
        };

        runQueue().catch((err) => console.log(err));
    }, [data, isOpen]);

    const processAsset = async (asset: any, index: number) => {
        const currentAsset = `Asset #${index + 1}`;
        setStatuses((prev) => ({
            ...prev,
            [index]: (
                <div className={`${block}__status`}>
                    <h3 className={`${block}__status`}>
                        <HourglassEmpty /> {currentAsset}:
                    </h3>
                    <h4> Processing...</h4>
                </div>
            )
        }));

        await new Promise((r) => setTimeout(r, 800)); // Wait before sending next request
        try {
            const response = await AssetServices.uploadAsset(asset);

            onCreate();
            setStatuses((prev) => ({
                ...prev,
                [index]: (
                    <div className={`${block}__status`}>
                        <h3 className={`${block}__status`}>
                            <CheckCircleOutline /> {currentAsset}:
                        </h3>
                        <h4>{response.data.message}</h4>
                    </div>
                )
            }));
            return true;
        } catch (err: any) {
            setStatuses((prev) => ({
                ...prev,
                [index]: (
                    <div className={`${block}__status`}>
                        <h3 className={`${block}__status`}>
                            <ErrorOutline /> {currentAsset}:
                        </h3>
                        <h4>
                            {formatErrorMessage(
                                err.response.data.message,
                                err.response.data.fields
                            )}
                        </h4>
                    </div>
                )
            }));
            return false;
        }
    };

    const formatProgress = (msg: string, values: string[]) => {
        let result = msg;
        values.forEach((value, index) => {
            result = result.replace(`$${index + 1}`, value);
        });
        return result;
    };

    const formatErrorMessage = (errorMessage: string, fields: string[]) => {
        let message = errorMessage;

        const split = errorMessage.split(': ');
        if (split.length > 1) {
            message = `${split[0]}:`;
        }

        const mappedFields = fields
            .map((field) => {
                if (EXCEL_ASSET_FIELD_NAMES[field]) return EXCEL_ASSET_FIELD_NAMES[field];
                return field;
            })
            .join(', ');

        return `${message} ${mappedFields}`;
    };

    const reset = () => {
        setProgress('');
        setStatuses({});
        setAllowClose(false);
    };

    return (
        <CustomModal
            title={UPLOAD_ASSET.TITLE}
            size="md"
            open={isOpen}
            handleClose={onClose}
            allowClose={allowClose}
        >
            <div className={`${block}__base`}>
                <h2>{progress}</h2>

                <div className={`${block}__content`}>
                    {Object.entries(statuses).map((value, index) => {
                        return <div key={index}>{value[1]}</div>;
                    })}
                    <AlwaysScrollToBottom />
                </div>
                <CustomButton
                    label={BUTTONS.CLOSE}
                    variant="contained"
                    uiType="secondary"
                    onClick={onClose}
                    disabled={!allowClose}
                />
            </div>
        </CustomModal>
    );
};
export default AssetUploadModal;
