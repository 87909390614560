import './LocationForm.scss';
import CustomButton from '@Shared/Button/Button';
import CustomTextField from '@Shared/TextField/TextField';
import { VALIDATION_RULES } from '@Utils/constants';
import { LABELS } from '@Utils/labels';
import CheckIcon from '@mui/icons-material/Check';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { SettingsFormProps } from '../../Settings.type';

const { LOCATION, BUTTONS } = LABELS;

const { NAME_FIELD, LOCATION_FIELD, COUNTRY_FIELD, DETAILS_FIELD } = LOCATION.FORM;
const { LOCATION_RULES } = VALIDATION_RULES;

const LocationForm = ({ handlerSubmit, defaultValues }: SettingsFormProps) => {
    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm();

    return (
        <form onSubmit={handleSubmit(handlerSubmit)}>
            <Grid container>
                <CustomTextField
                    name={NAME_FIELD.NAME}
                    control={control}
                    label={NAME_FIELD.LABEL}
                    helper={errors[NAME_FIELD.NAME]?.message}
                    defaultValue={defaultValues[NAME_FIELD.NAME]}
                    fullWidth
                    classname="field"
                    rules={LOCATION_RULES.NAME}
                />

                <CustomTextField
                    label={LOCATION_FIELD.LABEL}
                    name={LOCATION_FIELD.NAME}
                    control={control}
                    helper={errors[LOCATION_FIELD.NAME]?.message}
                    defaultValue={defaultValues[LOCATION_FIELD.NAME]}
                    fullWidth
                    classname="field"
                    rules={LOCATION_RULES.LOCATION}
                />

                <CustomTextField
                    label={COUNTRY_FIELD.LABEL}
                    name={COUNTRY_FIELD.NAME}
                    control={control}
                    helper={errors[COUNTRY_FIELD.NAME]?.message}
                    fullWidth
                    defaultValue={defaultValues[COUNTRY_FIELD.NAME]}
                    classname="field"
                    rules={LOCATION_RULES.COUNTRY}
                />

                <CustomTextField
                    label={DETAILS_FIELD.LABEL}
                    name={DETAILS_FIELD.NAME}
                    control={control}
                    helper={errors[DETAILS_FIELD.NAME]?.message}
                    fullWidth
                    multiline
                    defaultValue={defaultValues[DETAILS_FIELD.FIELD]}
                    classname="field"
                    rules={LOCATION_RULES.DETAILS}
                />

                <CustomButton
                    label={BUTTONS.CONFIRMATION}
                    type="submit"
                    variant="contained"
                    uiType="secondary"
                    classes="ac2-button-field"
                    startIcon={<CheckIcon />}
                />
            </Grid>
        </form>
    );
};

export default LocationForm;
