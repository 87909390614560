import { BTN_SIZE, FILE_VALIDATION } from '@Utils/constants';
import { LABELS } from '@Utils/labels';
import { Button, Input } from '@mui/material';
import cx from 'classnames';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import './FileUploadButton.scss';
import { FileUploadButtonProps } from './FileUploadButton.type';
import { ChangeEvent } from 'react';

const FileUploadButton = ({
    id,
    name,
    control,
    classes,
    disabled,
    label,
    variant,
    size,
    startIcon,
    uiType,
    endIcon,
    component,
    onFileSelect,
    setAlert
}: FileUploadButtonProps) => {
    const onSelect = () => {
        if (onFileSelect) {
            onFileSelect();
        }
    };

    const onFileChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        currentFiles: any,
        onChange: any
    ) => {
        const target = event.target as HTMLInputElement;
        const selectedFiles = target.files ? Array.from(target.files) : [];
        event.target.value = ''; // resets actual values in order to fix selection of previously selected files

        selectedFiles?.forEach((file, index) => {
            if (file.size > FILE_VALIDATION.MAX_SIZE) {
                setAlert(LABELS.REPORTS.FILE_UPLOAD.SIZE_ERROR, LABELS.ALERTS.SEVERITIES.ERROR);
                selectedFiles.splice(index, 1);
            }
        });

        if (currentFiles) {
            const newFiles = selectedFiles.filter(
                (newFile) =>
                    currentFiles.findIndex((file: any) => {
                        let fileSlicedName = file.filename;
                        if (file.filename) {
                            fileSlicedName = file.filename.slice(file.filename.indexOf('/') + 1);
                        }
                        return fileSlicedName === newFile.name || file.name === newFile.name;
                    }) === -1
            );
            onChange(currentFiles.concat(newFiles));
        } else {
            onChange(selectedFiles);
        }

        onSelect();
    };

    const render = ({ field }: { field: ControllerRenderProps }) => (
        <label htmlFor={id}>
            <Input
                id={id}
                type="file"
                inputProps={{
                    multiple: true,
                    accept: FILE_VALIDATION.ALLOWED_EXTENSIONS
                }}
                style={{ display: 'none' }}
                onChange={(e) => {
                    onFileChange(e, field.value, field.onChange);
                }}
            />
            <Button
                className={cx('ac2-upload-btn', `ac2-btn--${uiType}`, classes)}
                endIcon={endIcon || null}
                disableElevation
                disableRipple
                size={size || BTN_SIZE.MEDIUM}
                startIcon={startIcon || null}
                variant={variant}
                type="submit"
                component={component || 'span'}
                disabled={disabled || false}
            >
                {label}
            </Button>
        </label>
    );

    return <Controller name={name} control={control} render={render} />;
};

export default FileUploadButton;
