import { InputAdornment, TextField } from '@mui/material';
import cx from 'classnames';
import { Controller } from 'react-hook-form';

import { formatLabel } from '../Form/FormInput.utils';

import './TextField.scss';
import { CustomTextFieldProps, TextFieldType } from './TextField.type';

export const CustomTextField = ({
    name,
    control,
    label,
    classname,
    classes,
    inputClasses,
    type,
    fullWidth,
    autoFocus,
    multiline,
    rows,
    color,
    shrink,
    mode,
    helper,
    rules,
    size,
    autoComplete,
    iconEnd,
    defaultValue,
    disabled
}: CustomTextFieldProps) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                    label={formatLabel(label, rules)}
                    className={cx(`ac2-input-${classname}`, 'ac2-input-field', classes)}
                    variant="outlined"
                    value={value}
                    type={type}
                    size={size}
                    autoComplete={autoComplete}
                    fullWidth={fullWidth}
                    autoFocus={autoFocus}
                    multiline={multiline}
                    rows={rows}
                    color={color}
                    InputLabelProps={{
                        shrink,
                        inputMode: mode as TextFieldType,
                        className: 'ac2-input-label'
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{iconEnd}</InputAdornment>,
                        className: cx('ac2-fieldset', inputClasses)
                    }}
                    FormHelperTextProps={{ className: 'ac2-helper' }}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? helper : null}
                    disabled={disabled || false}
                />
            )}
            rules={rules}
        />
    );
};

export default CustomTextField;
