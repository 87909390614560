import { useEffect, useMemo } from 'react';

import { CustomTextField } from '@Shared/TextField/TextField';
import { DEBOUNCE_MILISECONDS, PAGE_PROP, SEARCH_PROP } from '@Utils/constants';
import { LABELS } from '@Utils/labels';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, IconButton } from '@mui/material';
import debounce from 'lodash/debounce';
import pickBy from 'lodash/pickBy';
import { useForm } from 'react-hook-form';
import { useLocation, useSearchParams } from 'react-router-dom';

import './SearchBar.scss';
import { SearchBarProps, SearchFormValuesProps, SearchValuesProps } from './SearchBar.type';

const { SEARCH_BAR } = LABELS;

const parseValues = (searchValues: Omit<SearchValuesProps, 'pageUrl'>) => {
    const sanitizedValues = pickBy(searchValues, (value) => value.length > 0);

    if (sanitizedValues.year !== '' && sanitizedValues.year !== undefined) {
        Object.assign(sanitizedValues, {
            assetYear: sanitizedValues.year,
            purchaseDate: sanitizedValues.year,
            assetLogLoggedAt: sanitizedValues.year,
            startDate: sanitizedValues.year,
            endDate: sanitizedValues.year
        });
        delete sanitizedValues.year;
        return sanitizedValues;
    }
    return sanitizedValues;
};

const SearchBar = ({
    setSearchResults,
    setIsLoading,
    searchService,
    setServerError,
    disabled
}: SearchBarProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();

    const { handleSubmit, control, reset, getValues, setValue } = useForm<SearchFormValuesProps>({
        defaultValues: {
            search: '',
            year: ''
        }
    });

    const onSubmit = (searchValues: SearchValuesProps) => {
        if (!disabled) {
            const parsedValues = parseValues(searchValues);

            if (searchValues.pageUrl) {
                parsedValues.page = searchParams.get(PAGE_PROP) || '';
            } else {
                parsedValues.page = '1';
            }
            setSearchParams(parsedValues);
            if (setIsLoading) {
                setIsLoading(true);
            }
            if (setServerError) {
                setServerError(false);
            }
            searchService(parsedValues)
                .then((response) => {
                    setSearchResults(response.data);
                })
                .catch((err) => {
                    if (setServerError) {
                        setServerError(true);
                    }
                    return err;
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const debouncedOnSubmit = useMemo(() => debounce(onSubmit, DEBOUNCE_MILISECONDS), []);

    const onClear = () => {
        reset();
        onSubmit({
            search: ''
        });
        setSearchParams({ page: '1' });
    };

    useEffect(() => {
        if (searchParams.get(SEARCH_PROP)) {
            const query = searchParams.get(SEARCH_PROP) || '';
            setValue(SEARCH_PROP, query);
            onSubmit({ search: query, pageUrl: true });
        }
    }, []);

    useEffect(() => {
        if (searchParams.get(SEARCH_PROP) === null && getValues(SEARCH_PROP) !== '') {
            reset();
            onSubmit({ search: '' });
            if (searchParams.get(PAGE_PROP))
                setSearchParams({ page: searchParams.get(PAGE_PROP) || '' });
        }
    }, [location]);

    return (
        <form onSubmit={handleSubmit(debouncedOnSubmit)}>
            <Grid container spacing={1} justifyContent="flex-end">
                <Grid item xs={12} sm={8} md={6}>
                    <CustomTextField
                        name={SEARCH_BAR.SEARCH_FIELD}
                        control={control}
                        label={SEARCH_BAR.SEARCH_BAR_TITLE}
                        size="small"
                        fullWidth
                        color="secondary"
                        autoComplete="off"
                        rules={{}}
                        iconEnd={
                            <>
                                {(getValues(
                                    SEARCH_BAR.SEARCH_FIELD as keyof SearchFormValuesProps
                                ) ||
                                    getValues(
                                        SEARCH_BAR.YEAR_FIELD as keyof SearchFormValuesProps
                                    )) && (
                                    <IconButton
                                        onClick={onClear}
                                        edge="end"
                                        className="ac2-search-icon"
                                        aria-label={SEARCH_BAR.ALT_SEARCH_BUTTON}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                )}
                                <IconButton
                                    type="submit"
                                    edge="end"
                                    disabled={disabled || false}
                                    className="ac2-search-icon"
                                    aria-label={SEARCH_BAR.ALT_CLOSE_BUTTON}
                                >
                                    <SearchIcon />
                                </IconButton>
                            </>
                        }
                        disabled={disabled || false}
                    />
                </Grid>
            </Grid>
        </form>
    );
};

export default SearchBar;
