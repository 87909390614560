import './Tabs.scss';
import Button from '@Shared/Button/Button';
import { Grid } from '@mui/material';
import cx from 'classnames';
import PropTypes from 'prop-types';

const Tabs = (props) => {
    const { tabs, selectedTabValue, updateTab } = props;

    return (
        <Grid container justifyContent="flex-start">
            <Grid container gap={0.3}>
                {tabs.map((tab) => (
                    <Button
                        key={`${tab.label}-${tab.value}`}
                        variant="text"
                        classes={cx('ac2-tabs-buttons', {
                            'ac2-tabs-buttons--other': !(tab.value === selectedTabValue)
                        })}
                        label={tab.label}
                        onClick={() => updateTab(tab.value)}
                    />
                ))}
            </Grid>
            <Grid container justifyContent="flex-start" className="ac2-tabs-container">
                {tabs.find((tab) => tab.value === selectedTabValue).content}
            </Grid>
        </Grid>
    );
};
export default Tabs;

Tabs.propTypes = {
    tabs: PropTypes.array.isRequired,
    selectedTabValue: PropTypes.number.isRequired,
    updateTab: PropTypes.func.isRequired
};
