import React, { useEffect, useState } from 'react';

import './Reports.scss';
import EmptyIcon from '@Assets/Images/empty-box.svg';
import ReportService from '@Services/Report.service';
import CustomButton from '@Shared/Button/Button';
import Loading from '@Shared/Loading/Loading';
import SearchBar from '@Shared/SearchBar/SearchBar';
import ServerError from '@Shared/ServerError/ServerError';
import CustomSnackbar from '@Shared/Snackbar/Snackbar';
import Table from '@Shared/Table/Table';
import { showErrorAlert } from '@Utils/alerts';
import {
    FIRST_PAGE_NUMBER,
    PAGE_PROP,
    PARSE_INT_BASE_NUMBER,
    PATHS,
    REPORT_COLUMNS,
    SEARCH_PROP
} from '@Utils/constants';
import { LABELS } from '@Utils/labels';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { AlertColor, Grid } from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import ReportInputModal from './NewReport/ReportInputModal';
import { formatValues } from './Reports.utils';

const { ALERTS, EMPTY_MESSAGES, REPORTS } = LABELS;

const Reports = () => {
    const navigate = useNavigate();
    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [reports, setReports] = useState({ docs: [], totalPages: 0 });
    const [areReportsLoading, setAreReportsLoading] = useState(true);
    const [snackbarIsOpen, setSnackbarIsOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState(ALERTS.SEVERITIES.SUCCESS);
    const [dataObtainFailed, setDataObtainFailed] = useState(false);
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(1);

    const setAlert = (message: string, severity = ALERTS.SEVERITIES.SUCCESS) => {
        setAlertMessage(message);
        setAlertSeverity(severity);
        setSnackbarIsOpen(true);
    };

    const getReports = () => {
        if (!searchParams.get(SEARCH_PROP)) {
            setDataObtainFailed(false);
            setAreReportsLoading(true);
            ReportService.getReports(currentPage, true)
                .then(({ data }) => {
                    data.docs = formatValues(data.docs);
                    setReports(data);
                })
                .catch(({ response }) => {
                    showErrorAlert(setAlert, response);
                    setDataObtainFailed(true);
                })
                .finally(() => {
                    setAreReportsLoading(false);
                });
        }
    };

    useEffect(() => {
        getReports();
    }, [currentPage]);

    const onSearch = (values: any) => {
        values.docs = formatValues(values.docs);
        setReports(values);
    };

    useEffect(() => {
        if (searchParams.get(PAGE_PROP)) {
            setCurrentPage(parseInt(searchParams.get(PAGE_PROP) || '', PARSE_INT_BASE_NUMBER));
        }
    }, [location]);

    const toggleCreateModal = () => {
        setCreateModalIsOpen(!createModalIsOpen);
    };

    const createSubmitHandler = (reportFormData: any) => ReportService.createReport(reportFormData);

    const onCreationSuccess = () => {
        setAreReportsLoading(true);
        getReports();
        setAreReportsLoading(false);
    };

    const handleSnackbarClose = () => {
        setSnackbarIsOpen(false);
    };

    const downloadHandler = (row: any) => {
        const { generatedDocument } = row;
        window.open(generatedDocument.url, '_blank');
    };

    const clickHandler = () => {
        navigate(`${PATHS.SCANNER_LOG}?${PAGE_PROP}=${FIRST_PAGE_NUMBER}`);
    };

    const renderMainComponent = (loading: boolean, failed: boolean) => {
        if (loading) {
            return <Loading />;
        }
        if (failed) {
            return <ServerError />;
        }
        return (
            <Grid item xs={12} sm={12} md={12}>
                <Table
                    columns={REPORT_COLUMNS}
                    rows={reports.docs}
                    emptyMessage={EMPTY_MESSAGES.REPORTS}
                    emptyIcon={EmptyIcon}
                    page={currentPage}
                    searchParam={searchParams.get(SEARCH_PROP) || ''}
                    updatePage={setCurrentPage}
                    details
                    detailsLabel={REPORTS.DETAILS}
                    detailsPath={PATHS.REPORTS}
                    idField="_id"
                    itemCount={reports.totalPages}
                />
            </Grid>
        );
    };

    return (
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
                <h1 className="ac2-title">{REPORTS.TITLE}</h1>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <CustomButton
                    label={REPORTS.BUTTON_NEW}
                    uiType="secondary"
                    variant="contained"
                    classes="ac2-button-new"
                    onClick={toggleCreateModal}
                    startIcon={<AddOutlinedIcon />}
                />
                <CustomButton
                    label={REPORTS.BUTTON_LOG}
                    uiType="secondary"
                    variant="contained"
                    onClick={clickHandler}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <SearchBar
                    setSearchResults={onSearch}
                    searchService={(param) => ReportService.getReportsSearch(param, currentPage)}
                    setIsLoading={setAreReportsLoading}
                    setServerError={setDataObtainFailed}
                />
            </Grid>
            {renderMainComponent(areReportsLoading, dataObtainFailed)}
            {createModalIsOpen && (
                <ReportInputModal
                    modalIsOpen={createModalIsOpen}
                    handleClose={toggleCreateModal}
                    setAlert={setAlert}
                    onSubmit={createSubmitHandler}
                    onSuccess={onCreationSuccess}
                    successMessage={LABELS.REPORTS.MODAL.ALERT_MESSAGES.SUCCES}
                />
            )}
            <CustomSnackbar
                isOpen={snackbarIsOpen}
                handleClose={handleSnackbarClose}
                alertMessage={alertMessage}
                alertSeverity={alertSeverity as AlertColor}
            />
        </Grid>
    );
};

export default Reports;
