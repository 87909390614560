import React, { useContext, useEffect } from 'react';

import '@kg-internal/pv3-uikit/dist/style.css';
import { Footer, Navbar } from '@Components/exporter';
import UserContext from '@Context/userContext';
import AuthServices from '@Services/Auth.services';
import { LogoLoader } from '@kg-internal/pv3-uikit';
import { Outlet, useNavigate } from 'react-router';

const ProtectedRoutes = () => {
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        AuthServices.validateUser()
            .then(({ data }) => {
                setUser(data);
            })
            .catch(() => navigate('/unauthorized'));
    }, []);

    return user ? (
        <div className="ac2-app-container">
            <Navbar />
            <main>
                <div className="ac2-component-container">
                    <Outlet />
                </div>
            </main>
            <Footer />
        </div>
    ) : (
        <div className="ac2-loading-container">
            <LogoLoader />
        </div>
    );
};

export default ProtectedRoutes;
