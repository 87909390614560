import { API_ROUTES, HTTP_METHODS, PAGE_SIZE } from '@Utils/constants';
import { sendCall } from '@Utils/requestPrep';

const { ACCESSORIES } = API_ROUTES;

class AccessoriesServices {
    createAccessory = (params) =>
        sendCall(ACCESSORIES.POST, {
            data: params,
            method: HTTP_METHODS.POST
        });

    getAccessories = (page, pagination) =>
        sendCall(`${ACCESSORIES.GET}?page=${page}&limit=${PAGE_SIZE}&pagination=${pagination}`, {
            method: HTTP_METHODS.GET
        });

    getAccessory = (id) => sendCall(`${ACCESSORIES.GET}/${id}`, { method: HTTP_METHODS.GET });

    updateAccessory = (id, params) =>
        sendCall(`${ACCESSORIES.PUT}/${id}`, {
            data: params,
            method: HTTP_METHODS.PUT
        });

    disableAccessory = (id) =>
        sendCall(`${ACCESSORIES.DISABLE}/${id}`, {
            method: HTTP_METHODS.POST
        });

    searchAccessories = (params, page) =>
        sendCall(`${ACCESSORIES.SEARCH_POST}?page=${page}&limit=${PAGE_SIZE}`, {
            data: params,
            method: HTTP_METHODS.POST
        });

    assignAccessory = (id, params) =>
        sendCall(`${ACCESSORIES.ASSIGN}/${id}`, {
            data: params,
            method: HTTP_METHODS.PUT
        });

    unassignAccessory = (id, params) =>
        sendCall(`${ACCESSORIES.UNASSIGN}/${id}`, {
            data: params,
            method: HTTP_METHODS.PUT
        });
}

export default new AccessoriesServices();
