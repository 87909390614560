import { useContext, useEffect, useState } from 'react';

import UserContext from '@Context/userContext';
import AssetStatusService from '@Services/AssetStatus.services';
import AssetTypeService from '@Services/AssetType.services';
import LocationService from '@Services/Location.services';
import TaxStatusService from '@Services/TaxStatus.services';
import CustomModal from '@Shared/Modal/Modal';
import { showErrorAlert } from '@Utils/alerts';
import { LABELS } from '@Utils/labels';

import NewReport from './NewReport';
import SelectFilters from './SelectFilters';
import {
    createNewInfo,
    FILTERS_VALUES,
    REPORT_VALUES,
    restoreFilter,
    sanitizedFilter
} from '../Reports.utils';
import { ReportInputModalProps } from './NewReport.type';

const { ALERTS, ASSET_MODAL } = LABELS;

const ReportInputModal = ({
    modalIsOpen,
    handleClose,
    setAlert,
    successMessage,
    onSubmit,
    onSuccess
}: ReportInputModalProps) => {
    const [newReportOpen, setNewReportOpen] = useState(false);
    const [selectFiltersOpen, setSelectFiltersOpen] = useState(false);

    const [reportInfo, setReportInfo] = useState(REPORT_VALUES);
    const [filterInfo, setFilterInfo] = useState(FILTERS_VALUES);
    const [allInfo, setAllInfo] = useState([]);

    const { user } = useContext(UserContext);

    useEffect(() => {
        const getStatus = async () => {
            Promise.all([
                AssetStatusService.getAssetStatus(null, false),
                AssetTypeService.getAssetTypes(null, false),
                LocationService.getLocations(null, false),
                TaxStatusService.getTaxStatus(null, false)
            ])
                .then(([assetStatus, assetTypes, location, taxStatus]) => {
                    setAllInfo(createNewInfo(assetStatus, assetTypes, location, taxStatus));
                    restoreFilter(filterInfo);
                    setNewReportOpen(true);
                })
                .catch(() => {
                    setAlert(ASSET_MODAL.DROPDOWN_OPTIONS_FETCH_ERROR, ALERTS.SEVERITIES.ERROR);
                });
        };
        getStatus();
    }, []);

    const reportAddFilterHandler = (data: any) => {
        setReportInfo(data);
        setNewReportOpen(false);
        setSelectFiltersOpen(true);
    };

    const confirmFilterHandler = (data: any) => {
        setFilterInfo(data);
        setSelectFiltersOpen(false);
        setNewReportOpen(true);
    };

    const cancelFilterHandler = () => {
        setSelectFiltersOpen(false);
        setNewReportOpen(true);
    };

    const submitHandler = (report: any) => {
        const filter = sanitizedFilter(filterInfo);
        const reportData = { filter, ...report };

        handleClose();
        onSubmit(reportData)
            .then(() => {
                onSuccess();
                setNewReportOpen(false);
                handleClose();
                setAlert(successMessage);
            })
            .catch((data: any) => {
                showErrorAlert(setAlert, data.response);
            })
            .finally(() => restoreFilter(filterInfo));
    };

    return (
        <CustomModal
            title={
                newReportOpen
                    ? LABELS.REPORTS.MODAL.TITLES.CREATE_REPORT
                    : LABELS.REPORTS.MODAL.TITLES.SELECT_FILTERS
            }
            size="sm"
            open={modalIsOpen}
            handleClose={handleClose}
        >
            <>
                {newReportOpen && (
                    <NewReport
                        reportInfo={reportInfo}
                        filterInfo={filterInfo}
                        nextHandler={reportAddFilterHandler}
                        submitHandler={submitHandler}
                        user={user}
                        setFilterInfo={setFilterInfo}
                    />
                )}
                {selectFiltersOpen && (
                    <SelectFilters
                        allInfo={allInfo}
                        filterInfo={filterInfo}
                        filterType={reportInfo.reportType}
                        previousHandler={confirmFilterHandler}
                        cancelHandler={cancelFilterHandler}
                    />
                )}
            </>
        </CustomModal>
    );
};

export default ReportInputModal;
