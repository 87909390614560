import { Alert, Snackbar } from '@mui/material';
import { SNACKBAR_DURATION } from '@Utils/constants';

import './Snackbar.scss';
import { CustomSnackbarProps, SNACKBAR_POSITIONING } from './Snackbar.type';

const CustomSnackbar = ({
    isOpen,
    handleClose,
    alertSeverity,
    alertMessage
}: CustomSnackbarProps) => {
    return (
        <Snackbar
            open={isOpen}
            autoHideDuration={SNACKBAR_DURATION}
            anchorOrigin={SNACKBAR_POSITIONING}
        >
            <Alert severity={alertSeverity} onClose={handleClose}>
                {alertMessage}
            </Alert>
        </Snackbar>
    );
};

export default CustomSnackbar;
