import { AlertColor, SnackbarOrigin } from '@mui/material';
import { EventHandler, SyntheticEvent } from 'react';

export type SnackBarProps = {
    alertSeverity: AlertColor;
    alertMessage: string;
};

export type CustomSnackbarProps = {
    handleClose: EventHandler<SyntheticEvent>;
    isOpen: boolean;
} & SnackBarProps;

export const SNACKBAR_POSITIONING: SnackbarOrigin = {
    vertical: 'top',
    horizontal: 'right'
};
