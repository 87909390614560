import axios from 'axios';

import { ENVIRONMENTS } from './constants';

const { VITE_APP_DEPLOY_ENV, VITE_APP_FAKE_LOGIN_EMAIL } = import.meta.env;

export const sendCall = (url, config) => {
    const withCredentials = VITE_APP_DEPLOY_ENV !== ENVIRONMENTS.DEV;
    let finalConfig = { ...config, ...{ withCredentials } };

    if (VITE_APP_DEPLOY_ENV === ENVIRONMENTS.DEV) {
        finalConfig = { ...finalConfig, ...{ headers: { email: VITE_APP_FAKE_LOGIN_EMAIL } } };
    }

    return axios(url, finalConfig);
};
