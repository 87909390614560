import { ASSET_FORM_DATA_KEYS, ASSET_IDENTIFIERS, DEFAULT_OPTIONS } from '@Utils/constants';
import { addZeroToDate, getDateString } from '@Utils/dates';
import { isString } from 'lodash';
import { DeviceInfoProps } from './Modals/AssetInputModal.type';
import { ExcelAsset } from '@Types/Asset.types';

const filterObject = (object: any) =>
    Object.fromEntries(Object.entries(object).filter(([, value]) => value !== undefined));

const formatDateTime = (dateTime: any) => {
    if (dateTime) {
        const [date] = dateTime.split('T');
        return date;
    }
    return dateTime;
};

export const divideAsset = (asset: any) => {
    const {
        serialNumber,
        assetTag,
        rfid,
        assetType,
        officeName,
        locationDetail,
        inOffice,
        assetStatus,
        taxStatus,
        assetModel,
        assetMake,
        assetYear,
        descriptionDetails,
        vendor,
        purchaseDate,
        warrantyDuration,
        osVersion,
        sharedDevice,
        documents,
        insideRegime,
        invoiceNumber
    } = asset;

    const rawDeviceInfo = {
        assetMake,
        assetModel,
        assetYear,
        descriptionDetails,
        assetType,
        assetStatus,
        osVersion,
        sharedDevice
    };

    const rawLocationInfo = { officeId: officeName, locationDetail, inOffice };

    const rawLegalInfo = {
        serialNumber,
        assetTag,
        rfid,
        taxStatus,
        vendor,
        purchaseDate: formatDateTime(purchaseDate),
        warrantyDuration,
        documents,
        insideRegime,
        invoiceNumber
    };

    const deviceInfo = filterObject(rawDeviceInfo);
    const locationInfo = filterObject(rawLocationInfo);
    const legalInfo = filterObject(rawLegalInfo);

    return { deviceInfo, locationInfo, legalInfo };
};

export const ASSET_MODAL_STEPS = {
    DEVICE_INFO: 0,
    LOCATION_INFO: 1,
    LEGAL_INFO: 2
};

const convertToInt = (value: any) => (value !== '' ? +value : undefined);

export const parseDateDetails = (info: any) => {
    if (!Number.isNaN(Date.parse(info))) {
        const date = info.split('/');
        const strDay = addZeroToDate(date[1]);
        const strMonth = addZeroToDate(date[0]);
        const parsedDate = `${strDay}/${strMonth}/${date[2]}`;
        return parsedDate;
    }
    return info;
};

export const parseDate = (info: any) => {
    const dateToParse = isString(info) ? info : info.purchaseDate;

    if (dateToParse) {
        const date = dateToParse.split('-');
        const parsedDate = `${date[1]}/${date[2]}/${date[0]}`;

        return parsedDate;
    }
    return dateToParse;
};

export const parseDateInput = (asset: any) => {
    const dateToParse = { ...asset };

    if (dateToParse.purchaseDate) {
        const date = dateToParse.purchaseDate.split('/');
        const strDay = addZeroToDate(date[1]);
        const strMonth = addZeroToDate(date[0]);

        dateToParse.purchaseDate = `${date[2]}-${strMonth}-${strDay}`;

        return dateToParse;
    }
    return dateToParse;
};

export enum FIELD_NAMES {
    ASSET_MAKE = 'assetMake',
    ASSET_MODEL = 'assetModel',
    ASSET_YEAR = 'assetYear',
    DESCRIPTION_DETAILS = 'descriptionDetails',
    ASSET_TYPE = 'assetType',
    ASSET_STATUS = 'assetStatus',
    OS_VERSION = 'osVersion',
    SHARED_DEVICE = 'sharedDevice',
    OFFICE_ID = 'officeId',
    OFFICE_NAME = 'officeName',
    LOCATION_DETAIL = 'locationDetail',
    IN_OFFICE = 'inOffice',
    SERIAL_NUMBER = 'serialNumber',
    ASSET_TAG = 'assetTag',
    RFID = 'rfid',
    TAX_STATUS = 'taxStatus',
    VENDOR = 'vendor',
    PURCHASE_DATE = 'purchaseDate',
    WARRANTY_DURATION = 'warrantyDuration',
    DOCUMENTS = 'documents',
    INVOICE_NUMBER = 'invoiceNumber',
    INSIDE_REGIME = 'insideRegime',
    OWNER = 'owner'
}

export const DEVICE_VALUES: DeviceInfoProps = {
    assetMake: '',
    assetModel: '',
    assetYear: '',
    descriptionDetails: '',
    assetType: '',
    assetStatus: '',
    osVersion: '',
    sharedDevice: false,
    assetDetails: ''
};

export const LOCATION_VALUES = {
    officeId: '',
    locationDetail: '',
    inOffice: true
};

export const LEGAL_VALUES = {
    serialNumber: '',
    assetTag: '',
    rfid: '',
    taxStatus: '',
    vendor: '',
    purchaseDate: '',
    warrantyDuration: '',
    insideRegime: false,
    invoiceNumber: ''
};

export const formatCreateValues = (submitInfo: any) => {
    submitInfo[FIELD_NAMES.WARRANTY_DURATION] = convertToInt(
        submitInfo[FIELD_NAMES.WARRANTY_DURATION]
    );

    const { documents } = submitInfo;
    delete submitInfo.documents;
    delete submitInfo.deletedDocuments;

    const prunedSubmitInfo = Object.fromEntries(
        Object.entries(submitInfo).filter(([, value]) => value !== '')
    );

    const formData = new FormData();
    formData.append(ASSET_FORM_DATA_KEYS.FIELDS, JSON.stringify(prunedSubmitInfo));
    if (documents) {
        documents.forEach((doc: any) => {
            formData.append(ASSET_FORM_DATA_KEYS.DOCUMENTS, doc);
        });
    }

    return formData;
};

export const formatEditValues = (submitInfo: any) => {
    const formData = new FormData();

    submitInfo[FIELD_NAMES.WARRANTY_DURATION] = convertToInt(
        submitInfo[FIELD_NAMES.WARRANTY_DURATION]
    );

    if (submitInfo.deletedDocuments.length === 0) {
        delete submitInfo.deletedDocuments;
    }

    if (submitInfo.purchaseDate === '') {
        delete submitInfo.purchaseDate;
    } else {
        parseDate(submitInfo.purchaseDate);
    }

    const { documents } = submitInfo;

    if (documents) {
        const newDocuments = documents.filter((doc: any) => typeof doc.name === 'string');

        newDocuments.forEach((doc: any) => {
            formData.append(ASSET_FORM_DATA_KEYS.DOCUMENTS, doc);
        });

        submitInfo.documents = submitInfo.documents.filter(
            (doc: any) => !newDocuments.includes(doc)
        );
    }

    formData.append(ASSET_FORM_DATA_KEYS.FIELDS, JSON.stringify(submitInfo));

    return formData;
};

export const getDocumentName = (doc: any) =>
    typeof doc.name === 'string' ? doc.name : doc.filename;

export const getAssetTypeByID = (conditionalInfo: any, assetTypeInfo: any) =>
    assetTypeInfo.find((assetType: any) => assetType.id === conditionalInfo);

export const getTaxStatusByID = (taxStatusID: any, taxStatusInfo: any) =>
    taxStatusInfo.find((taxStatus: any) => taxStatus.id === taxStatusID);

export const formatAssetStatus = (result: any) =>
    result.map((fetchedAssetStatus: any) => ({
        id: fetchedAssetStatus._id,
        description: fetchedAssetStatus.description
    }));

export const formatAssetType = (result: any) =>
    result.map((fetchedAssetType: any) => ({
        id: fetchedAssetType._id,
        description: fetchedAssetType.description,
        assetCode: fetchedAssetType.assetCode
    }));

export const formatLocation = (result: any) =>
    result.map((fetchedLocation: any) => ({
        id: fetchedLocation._id,
        country: fetchedLocation.country,
        details: fetchedLocation.description,
        description: fetchedLocation.location,
        name: fetchedLocation.name
    }));

export const formatTaxStatus = (result: any) =>
    result.map((fetchedTaxStatus: any) => ({
        id: fetchedTaxStatus._id,
        description: fetchedTaxStatus.description
    }));

export const prepAssignments = (pAssignments: any) =>
    pAssignments
        .sort((firstDate: any, secondDate: any) => {
            const date = new Date(secondDate.assignmentDate);
            const date2 = new Date(firstDate.assignmentDate);
            return new Date(date.getTime() - date2.getTime());
        })
        .map((assignment: any) => ({
            assignmentDate: parseDateDetails(getDateString(assignment.assignmentDate)),
            employeeEmail: assignment.employeeEmail || 'Unassigned',
            _id: `${assignment.assignmentDate}-${assignment.employeeEmail}`
        }));

export const prepChanges = (pChanges: any) =>
    pChanges
        .sort((firstDate: any, secondDate: any) => {
            const date = new Date(secondDate.createdAt);
            const date2 = new Date(firstDate.createdAt);
            return new Date(date.getTime() - date2.getTime());
        })
        .map((change: any) => {
            change.createdAt = parseDateDetails(getDateString(change.createdAt));
            change._id = `${change.createdAt}-${change.changeType}`;
            return change;
        });

export const formatAssetTag = (data: any) => {
    const { assetTag, assetTypeCode, taxStatus } = data;

    if (assetTag && assetTypeCode && taxStatus) {
        return `${assetTag} - ${assetTypeCode} ${taxStatus}`;
    }
    return '';
};

export const formatAllAssetTag = (allData: any) =>
    allData.map((asset: any) => ({ ...asset, assetTag: formatAssetTag(asset) }));

export const getIdentifier = (asset: any) => {
    let identifier = DEFAULT_OPTIONS.ASSET_ID;
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < ASSET_IDENTIFIERS.length; index++) {
        const assetIdentifier = ASSET_IDENTIFIERS[index];
        if (asset[assetIdentifier]) {
            identifier = asset[assetIdentifier];
            break;
        }
    }

    return identifier;
};

export const formatBreadcrumb = (asset: any) => getIdentifier(asset);

const EXCEL_VALUE_MAP = new Map<number, FIELD_NAMES>([
    [0, FIELD_NAMES.ASSET_MAKE],
    [1, FIELD_NAMES.ASSET_MODEL],
    [2, FIELD_NAMES.ASSET_YEAR],
    [3, FIELD_NAMES.ASSET_TYPE],
    [4, FIELD_NAMES.ASSET_STATUS],
    [5, FIELD_NAMES.OS_VERSION],
    [6, FIELD_NAMES.DESCRIPTION_DETAILS],
    [7, FIELD_NAMES.SHARED_DEVICE],
    [8, FIELD_NAMES.OFFICE_NAME],
    [9, FIELD_NAMES.LOCATION_DETAIL],
    [10, FIELD_NAMES.IN_OFFICE],
    [11, FIELD_NAMES.SERIAL_NUMBER],
    [12, FIELD_NAMES.ASSET_TAG],
    [13, FIELD_NAMES.RFID],
    [14, FIELD_NAMES.TAX_STATUS],
    [15, FIELD_NAMES.VENDOR],
    [16, FIELD_NAMES.PURCHASE_DATE],
    [17, FIELD_NAMES.WARRANTY_DURATION],
    [18, FIELD_NAMES.INVOICE_NUMBER],
    [19, FIELD_NAMES.INSIDE_REGIME],
    [20, FIELD_NAMES.OWNER]
]);

const NON_STRING_FIELDS = [
    FIELD_NAMES.SHARED_DEVICE,
    FIELD_NAMES.IN_OFFICE,
    FIELD_NAMES.PURCHASE_DATE,
    FIELD_NAMES.WARRANTY_DURATION,
    FIELD_NAMES.INSIDE_REGIME
];

export const formatExcelData = (rows: any[][]) => {
    const data = [];

    for (const row of rows) {
        const splicedValues = row.slice(0, 21); // Remove unneeded fields
        const isEmpty = !splicedValues.every((value) => value === null);

        if (isEmpty) {
            const excelAsset: Partial<ExcelAsset> = {};
            for (let i = 0; i < 21; i++) {
                const field = EXCEL_VALUE_MAP.get(i);
                if (field) {
                    excelAsset[field as keyof ExcelAsset] = splicedValues[i];
                }
            }

            // Remove empty values
            const prunedInfo = Object.fromEntries(
                Object.entries(excelAsset).filter(([, value]) => value !== '' && value !== null)
            );

            // Ensure string types
            Object.keys(prunedInfo).forEach(function (key, index) {
                prunedInfo[key] = NON_STRING_FIELDS.includes(key as FIELD_NAMES)
                    ? prunedInfo[key]
                    : prunedInfo[key].toString();
            });

            data.push(prunedInfo);
        }
    }
    data.shift();

    return data;
};
