import { LABELS } from '@Utils/labels';
import './DownloadButton.scss';
import cx from 'classnames';
import DownloadIcon from '@mui/icons-material/Download';
import React from 'react';

type Props = {
    filename: string;
    href: string;
    classes?: string;
};

const DownloadButton = ({ filename, href, classes }: Props) => {
    return (
        <a className={cx('download-btn', classes)} href={href} download={filename} target="_blank">
            <span className="download-btn__icon">
                <DownloadIcon />
            </span>{' '}
            {LABELS.CREATE_ASSET.TEMPLATE}
        </a>
    );
};
export default DownloadButton;
