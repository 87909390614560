import { Breadcrumbs, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { BreadcrumbProps } from './Breadcrumbs.type';

const CustomBreadcrumbs = ({ previous: { route, name }, current }: BreadcrumbProps) => {
    return (
        <Breadcrumbs>
            <Link
                underline="hover"
                color="inherit"
                to={route}
                component={RouterLink}
                className="ac2-link"
            >
                {name}
            </Link>
            <Typography color="text.primary">{current}</Typography>
        </Breadcrumbs>
    );
};

export default CustomBreadcrumbs;
