import { useState } from 'react';

import { LABELS } from '@Utils/labels';
import { InfoOutlined } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, Grid, Tooltip } from '@mui/material';
import cx from 'classnames';

import './TableOptions.scss';
import CustomIconButton from '../IconButton/IconButton';
import { TableOptionsProps } from './Table.type';

const { BUTTONS } = LABELS;

const TableOptions = ({
    rowId,
    edit,
    disable,
    info,
    infoHandler,
    editHandler,
    disableHandler,
    disableConfirmationMessage,
    download,
    downloadHandler,
    type
}: TableOptionsProps) => {
    const [isAdminDisable, setIsAdminDisable] = useState(false);

    const toggleAdminDisable = () => {
        setIsAdminDisable(!isAdminDisable);
    };

    const onDisableConfirm = () => {
        disableHandler && disableHandler(rowId);
        setIsAdminDisable(false);
    };

    return (
        <>
            {!isAdminDisable && (
                <Grid
                    container
                    className={cx('ac2-option-container', `ac2-option-container__${type}`)}
                >
                    {edit && (
                        <CustomIconButton
                            tooltip={BUTTONS.EDIT}
                            onClick={editHandler}
                            variant="primary"
                            icon={<EditOutlinedIcon />}
                            classes={cx('ac2-icon-button--left', 'ac2-icon-button')}
                        />
                    )}
                    {disable && (
                        <CustomIconButton
                            tooltip={BUTTONS.DELETE}
                            onClick={toggleAdminDisable}
                            variant="primary"
                            icon={<DeleteOutlinedIcon />}
                            classes="ac2-icon-button"
                        />
                    )}
                    {download && (
                        <CustomIconButton
                            tooltip={BUTTONS.DOWNLOAD}
                            onClick={downloadHandler}
                            variant="primary"
                            icon={<DownloadIcon />}
                            classes="ac2-icon-button"
                        />
                    )}
                    {info && (
                        <CustomIconButton
                            tooltip={BUTTONS.INFO}
                            onClick={infoHandler}
                            variant="primary"
                            icon={<InfoOutlined />}
                            classes="ac2-icon-button"
                        />
                    )}
                </Grid>
            )}
            {isAdminDisable && (
                <Grid container className="ac2-confirmation-container" wrap="nowrap">
                    <Tooltip
                        open
                        title={disableConfirmationMessage}
                        placement="top"
                        arrow
                        describeChild
                        PopperProps={{ style: { zIndex: 1200 } }}
                    >
                        <Box sx={{ justifyContent: 'center', display: 'flex', flexWrap: 'nowrap' }}>
                            <CustomIconButton
                                tooltip={BUTTONS.CANCEL}
                                onClick={toggleAdminDisable}
                                variant="primary"
                                icon={<CloseIcon />}
                                classes={cx('ac2-icon-button--left', 'ac2-icon-button')}
                            />
                            <CustomIconButton
                                tooltip={BUTTONS.CONFIRMATION}
                                onClick={onDisableConfirm}
                                variant="inverted-primary"
                                icon={<CheckIcon />}
                                classes="ac2-icon-button"
                            />
                        </Box>
                    </Tooltip>
                </Grid>
            )}
        </>
    );
};

export default TableOptions;
