import { gql } from '@apollo/client';

const GET_CR_EMPLOYEES = gql`
    query getPeopleFromCRV2($office: Int) {
        peopleV2(office: $office, inactive: false) {
            id
            fullName
            nameLast
            nameFirst
            email
            photoUrl(size: medium)
            office {
                Name
            }
            company {
                id
                Name
            }
        }
    }
`;

export default GET_CR_EMPLOYEES;
