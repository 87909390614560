import React, { useEffect, useState } from 'react';

import ReportService from '@Services/Report.service';
import CustomBreadcrumbs from '@Shared/Breadcrumbs/Breadcrumbs';
import CustomButton from '@Shared/Button/Button';
import { formatLabel } from '@Shared/Form/FormInput.utils';
import Loading from '@Shared/Loading/Loading';
import CustomSnackbar from '@Shared/Snackbar/Snackbar';
import Table from '@Shared/Table/Table';
import {
    CLEAR,
    LIMIT_TAG,
    PAGE_PROP,
    PATHS,
    REPORT_BREADCRUMBS,
    REPORT_TYPE,
    SEARCH_PROP
} from '@Utils/constants';
import { LABELS } from '@Utils/labels';
import DownloadIcon from '@mui/icons-material/Download';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Autocomplete, Chip, Grid, TextField } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
    ASSET_LOGS_REPORT_DEFAULT_FILTERS,
    ASSET_LOGS_REPORT_FILTER_COLUMNS,
    ASSET_LOGS_REPORT_FILTERS,
    ASSET_REPORT_DEFAULT_FILTERS,
    ASSET_REPORT_FILTER_COLUMNS,
    ASSET_REPORT_FILTERS,
    formatBreadcrumb,
    REPORT_DETAILS_COLUMNS
} from '../Reports.utils';
import './ReportDetails.scss';

const { ALERTS, REPORTS, BUTTONS } = LABELS;
const { PREVIOUS_CRUMB, PREVIOUS_CRUMB_ROUTE } = REPORT_BREADCRUMBS;

const {
    SERIAL_NUMBER,
    ASSET_TAG,
    ASSET_TYPE,
    ASSET_MODEL,
    RFID,
    LOGGED_AT,
    LOG_TYPE,
    OFFICE_NAME,
    OWNER_EMAIL
} = REPORT_DETAILS_COLUMNS;

const ReportDetails = () => {
    const [reportDetails, setReportDetails] = useState({ _id: '-1', data: [], name: 'Loading...' });
    const [isLoading, setIsLoading] = useState(true);
    const [isMessageOpen, setIsMessageOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState(ALERTS.SEVERITIES.SUCCESS);
    const [columns, setColumns] = useState([RFID]);
    const [filters, setFilters] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [tableData, setTableData] = useState({ docs: [], totalPages: 0 });
    const { state } = useLocation();

    const { id } = useParams();
    const navigate = useNavigate();
    const setAlert = (message, severity = ALERTS.SEVERITIES.SUCCESS) => {
        setAlertMessage(message);
        setAlertSeverity(severity);
        setIsMessageOpen(true);
    };

    const getReport = () => {
        Promise.all([ReportService.getReport(id), ReportService.getReportPageData(id, currentPage)])
            .then(([reportData, pageData]) => {
                const report = reportData.data[0];
                if (report.generatedDocument.url !== '') {
                    window.open(report.generatedDocument.url, '_blank');
                    navigate(PATHS.REPORTS);
                }
                setReportDetails(report);
                setIsLoading(false);
                if (report.reportType === REPORT_TYPE.ASSET) {
                    setColumns([SERIAL_NUMBER, ASSET_TAG, ASSET_TYPE, ASSET_MODEL, OWNER_EMAIL]);
                    setFilters(ASSET_REPORT_FILTERS);
                    setSelectedFilters(ASSET_REPORT_DEFAULT_FILTERS);
                } else if (report.reportType === REPORT_TYPE.LOG) {
                    setColumns([LOGGED_AT, LOG_TYPE, RFID, OFFICE_NAME]);
                    setFilters(ASSET_LOGS_REPORT_FILTERS);
                    setSelectedFilters(ASSET_LOGS_REPORT_DEFAULT_FILTERS);
                }
                setTableData(pageData.data);
            })
            .catch(() => {
                setAlert(REPORTS.FETCH_ERROR, ALERTS.SEVERITIES.ERROR);
            });
    };

    useEffect(() => {
        getReport();
    }, []);

    useEffect(() => {
        ReportService.getReportPageData(id, currentPage)
            .then((data) => {
                setTableData(data.data);
            })
            .catch(() => {
                setAlert(REPORTS.FETCH_ERROR, ALERTS.SEVERITIES.ERROR);
            });
    }, [currentPage]);

    const handleCloseMessage = () => {
        setIsMessageOpen(false);
    };

    const generateExcel = async () => {
        ReportService.getExcel(id).then((response) => {
            const href = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `${reportDetails.name}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });
    };

    const getRoute = () => {
        const route = PREVIOUS_CRUMB_ROUTE;
        const params = [];
        if (state?.searchParam) {
            params.push(`${SEARCH_PROP}=${state.searchParam}`);
        }
        if (state?.page) {
            params.push(`${PAGE_PROP}=${state.page}`);
        }
        return `${route}?${params.join('&')}`;
    };

    const renderOptionLabel = (option) => `[${option.frag}] ${option.label}`;

    const handleFilterChange = (event, values, reason) => {
        if (reason === CLEAR) {
            setSelectedFilters([filters[0]]);
            setColumns([columns[0]]);
        } else {
            const difference = selectedFilters
                .filter((filter) => !values.includes(filter))
                .concat(values.filter((filter) => !selectedFilters.includes(filter)))[0];
            setSelectedFilters(values);
            let filterList = [];
            if (reportDetails.reportType === REPORT_TYPE.ASSET) {
                filterList = ASSET_REPORT_FILTER_COLUMNS;
            } else {
                filterList = ASSET_LOGS_REPORT_FILTER_COLUMNS;
            }
            const foundFilter = filterList.find((filter) => filter.field === difference.id);

            if (columns.includes(foundFilter)) {
                setColumns(columns.filter((col) => col.field !== foundFilter.field));
            } else {
                setColumns([...columns, foundFilter]);
            }
        }
    };

    return (
        <>
            <CustomBreadcrumbs
                current={formatBreadcrumb(reportDetails, true)}
                previous={{
                    route: getRoute(),
                    name: PREVIOUS_CRUMB
                }}
            />
            <Grid
                item
                xs={12}
                container
                alignItems="center"
                justifyContent="space-between"
                className="ac2-asset-toolbar"
            >
                <Grid item xs={12} md={4}>
                    <h1 className="ac2-title">{REPORTS.SUBTITLE}</h1>
                </Grid>
                <Grid item container xs={12} md={8} justifyContent="flex-end" alignItems="center">
                    <Grid item xs={12} md={9}>
                        <Autocomplete
                            multiple
                            limitTags={LIMIT_TAG}
                            popupIcon={<KeyboardArrowDownIcon color="secondary" />}
                            getOptionLabel={renderOptionLabel}
                            renderTags={(values, getTagProps) => (
                                <div>
                                    {values.map((option, index) => (
                                        <Chip
                                            variant="outlined"
                                            label={renderOptionLabel(option)}
                                            {...getTagProps({ index })}
                                            disabled={index === 0}
                                        />
                                    ))}
                                </div>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className="ac2-form-input"
                                    variant="outlined"
                                    label={formatLabel(REPORTS.FILTER, null)}
                                    placeholder={REPORTS.FILTER}
                                />
                            )}
                            options={filters}
                            value={selectedFilters}
                            onChange={handleFilterChange}
                            getOptionDisabled={(option) => option === selectedFilters[0]}
                        />
                    </Grid>

                    <CustomButton
                        label={BUTTONS.DOWNLOAD}
                        uiType="primary--outlined"
                        variant="outlined"
                        onClick={generateExcel}
                        startIcon={<DownloadIcon />}
                        classes="ac2-asset-btn"
                    />
                </Grid>
            </Grid>

            <Grid container justifyContent="flex-start" className="ac2-asset-details-container">
                <Grid item xs={12}>
                    <h2 className="ac2-subtitle">{reportDetails.name}</h2>
                </Grid>
                <Grid item xs={12}>
                    {!isLoading ? (
                        <Table
                            rows={tableData.docs}
                            columns={columns}
                            itemCount={tableData.totalPages}
                            page={currentPage}
                            updatePage={setCurrentPage}
                            idField="_id"
                        />
                    ) : (
                        <Loading />
                    )}
                </Grid>
            </Grid>

            <CustomSnackbar
                isOpen={isMessageOpen}
                handleClose={handleCloseMessage}
                alertMessage={alertMessage}
                alertSeverity={alertSeverity}
            />
        </>
    );
};

export default ReportDetails;
