import CustomButton from '@Shared/Button/Button';
import CustomTextField from '@Shared/TextField/TextField';
import { VALIDATION_RULES } from '@Utils/constants';
import { LABELS } from '@Utils/labels';
import CheckIcon from '@mui/icons-material/Check';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { SettingsFormProps } from '../../Settings.type';

const { ASSET_TYPE, BUTTONS } = LABELS;

const { CODE_FIELD, DESCRIPTION_FIELD } = ASSET_TYPE.FORM;
const { ASSET_TYPE_RULES } = VALIDATION_RULES;

const AssetTypeForm = ({ handlerSubmit, defaultValues }: SettingsFormProps) => {
    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm();

    return (
        <form onSubmit={handleSubmit(handlerSubmit)}>
            <Grid container>
                <CustomTextField
                    label={CODE_FIELD.LABEL}
                    name={CODE_FIELD.FIELD}
                    control={control}
                    helper={errors[CODE_FIELD.FIELD]?.message}
                    defaultValue={defaultValues[CODE_FIELD.FIELD]}
                    fullWidth
                    classname="field"
                    rules={ASSET_TYPE_RULES.CODE}
                />

                <CustomTextField
                    label={DESCRIPTION_FIELD.LABEL}
                    name={DESCRIPTION_FIELD.FIELD}
                    control={control}
                    helper={errors[DESCRIPTION_FIELD.FIELD]?.message}
                    defaultValue={defaultValues[DESCRIPTION_FIELD.FIELD]}
                    fullWidth
                    classname="field"
                    rules={ASSET_TYPE_RULES.DESCRIPTION}
                />

                <CustomButton
                    label={BUTTONS.CONFIRMATION}
                    type="submit"
                    variant="contained"
                    uiType="secondary"
                    classes="ac2-button-field"
                    startIcon={<CheckIcon />}
                />
            </Grid>
        </form>
    );
};

AssetTypeForm.propTypes = {
    defaultValues: PropTypes.object,
    handlerSubmit: PropTypes.func
};

export default AssetTypeForm;
