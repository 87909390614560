import { useEffect, useState } from 'react';

import EmptyIcon from '@Assets/Images/empty-settings.svg';
import LocationServices from '@Services/Location.services';
import CustomBreadcrumbs from '@Shared/Breadcrumbs/Breadcrumbs';
import CustomButton from '@Shared/Button/Button';
import Loading from '@Shared/Loading/Loading';
import CustomModal from '@Shared/Modal/Modal';
import SearchBar from '@Shared/SearchBar/SearchBar';
import ServerError from '@Shared/ServerError/ServerError';
import CustomSnackbar from '@Shared/Snackbar/Snackbar';
import CustomTable from '@Shared/Table/Table';
import { showErrorAlert } from '@Utils/alerts';
import { LOCATION_COLUMNS, SETTINGS_BREADCRUMBS } from '@Utils/constants';
import { LABELS } from '@Utils/labels';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { AlertColor, Grid } from '@mui/material';
import './Location.scss';

import LocationForm from './LocationForm/LocationForm';
import { SnackBarProps } from '~/Components/Shared/Snackbar/Snackbar.type';

const { BUTTONS, LOCATION, ALERTS, EMPTY_MESSAGES } = LABELS;
const { PREVIOUS_CRUMB, PREVIOUS_CRUMB_ROUTE, LOCATION_CRUMB } = SETTINGS_BREADCRUMBS;

const Location = () => {
    const [locations, setLocations] = useState<any[]>([]);
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isMessageOpen, setIsMessageOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<SnackBarProps>();
    const [defaultValues, setDefaultValues] = useState<any>();
    const [dataObtainFailed, setDataObtainFailed] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const showMessage = (pMessage: string, pSeverity: string) => {
        setIsMessageOpen(true);
        setMessage({
            alertSeverity: pSeverity as AlertColor,
            alertMessage: pMessage
        });
    };

    const getLocations = async () => {
        setIsLoading(true);
        setDataObtainFailed(false);
        LocationServices.getLocations(currentPage, true)
            .then(({ data }) => {
                setLocations(data);
            })
            .catch(({ response }) => {
                showErrorAlert(showMessage, response);
                setDataObtainFailed(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getLocations();
    }, [currentPage]);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleCloseMessage = () => {
        setIsMessageOpen(false);
    };

    const handleCreation = () => {
        setIsUpdate(false);
        setDefaultValues({});
        toggleModal();
    };

    const handleUpdate = (data: any) => {
        setIsUpdate(true);
        setDefaultValues(data);
        toggleModal();
    };

    const updateLocation = (data: any) => {
        LocationServices.updateLocation(defaultValues._id, data)
            .then(() => {
                showMessage(ALERTS.UPDATE_SUCCESS, ALERTS.SEVERITIES.SUCCESS);
                setCurrentPage(1);
                toggleModal();
                getLocations();
            })
            .catch(({ response }) => showErrorAlert(showMessage, response));
    };

    const createLocation = (data: any) => {
        LocationServices.createLocation(data)
            .then(() => {
                showMessage(ALERTS.CREATE_SUCCESS, ALERTS.SEVERITIES.SUCCESS);
                setCurrentPage(1);
                getLocations();
                toggleModal();
            })
            .catch(({ response }) => showErrorAlert(showMessage, response));
    };

    const disableLocation = (data: any) => {
        LocationServices.disableLocation(data._id)
            .then(() => {
                showMessage(ALERTS.DISABLED_SUCCESS, ALERTS.SEVERITIES.SUCCESS);
                setCurrentPage(1);
                getLocations();
            })
            .catch(({ response }) => showErrorAlert(showMessage, response));
    };

    const onSearch = (values: any) => {
        setLocations(values || []);
    };

    const renderMainComponent = (loading: boolean, failed: boolean) => {
        if (loading) {
            return (
                <Grid item xs={12}>
                    <Loading />
                </Grid>
            );
        }
        if (failed) {
            return <ServerError />;
        }
        return (
            <Grid item xs={12}>
                <CustomTable
                    columns={LOCATION_COLUMNS}
                    rows={locations.docs}
                    edit
                    editHandler={handleUpdate}
                    disable
                    disableHandler={disableLocation}
                    disableConfirmationMessage={LOCATION.DISABLE_MSG}
                    emptyMessage={EMPTY_MESSAGES.LOCATION}
                    emptyIcon={EmptyIcon}
                    itemCount={locations.totalPages}
                    page={currentPage}
                    updatePage={setCurrentPage}
                />
            </Grid>
        );
    };

    return (
        <>
            <CustomBreadcrumbs
                current={LOCATION_CRUMB}
                previous={{ route: PREVIOUS_CRUMB_ROUTE, name: PREVIOUS_CRUMB }}
            />

            <Grid item xs={12}>
                <h1 className="ac2-title">{LOCATION.TITLE}</h1>
            </Grid>

            <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
                <Grid item xs={6}>
                    <CustomButton
                        label={BUTTONS.NEW}
                        uiType="secondary"
                        variant="contained"
                        onClick={handleCreation}
                        startIcon={<AddOutlinedIcon />}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <SearchBar
                        setSearchResults={onSearch}
                        searchService={(param) =>
                            LocationServices.searchLocation(param, currentPage)
                        }
                        setIsLoading={setIsLoading}
                        setServerError={setDataObtainFailed}
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
                {renderMainComponent(isLoading, dataObtainFailed)}
            </Grid>
            <CustomModal
                open={isModalOpen}
                handleClose={toggleModal}
                size="xs"
                title={isUpdate ? LOCATION.FORM.UPDATE_TITLE : LOCATION.FORM.CREATE_TITLE}
            >
                <div>
                    {isModalOpen && (
                        <LocationForm
                            handlerSubmit={isUpdate ? updateLocation : createLocation}
                            defaultValues={defaultValues}
                        />
                    )}
                </div>
            </CustomModal>
            {message && (
                <CustomSnackbar
                    isOpen={isMessageOpen}
                    handleClose={handleCloseMessage}
                    alertMessage={message.alertMessage}
                    alertSeverity={message.alertSeverity}
                />
            )}
        </>
    );
};

export default Location;
