import { useContext, useEffect, useState } from 'react';

import EmptyAssignments from '@Assets/Images/empty-assigments.svg';
import EmptyDocuments from '@Assets/Images/empty-documents.svg';
import EmptyChanges from '@Assets/Images/none-changes.svg';
import UserContext from '@Context/userContext';
import AssetServices from '@Services/Asset.services';
import CustomBreadcrumbs from '@Shared/Breadcrumbs/Breadcrumbs';
import CustomButton from '@Shared/Button/Button';
import CustomIconButton from '@Shared/IconButton/IconButton';
import Loading from '@Shared/Loading/Loading';
import CustomModal from '@Shared/Modal/Modal';
import CustomSnackbar from '@Shared/Snackbar/Snackbar';
import CustomTable from '@Shared/Table/Table';
import CustomTextField from '@Shared/TextField/TextField';
import { checkRoles } from '@Utils/auth';
import {
    ADMIN_ROLES,
    ASSET_BREADCRUMBS,
    ASSET_CHANGES_HISTORY_COLUMNS,
    ASSET_DETAILS_COLUMNS,
    ASSET_OWNER_HISTORY_COLUMNS,
    COLUMNS_DEFAULT_VALUE,
    FILENAME_REGEX,
    PAGE_PROP,
    SEARCH_PROP,
    SIZES,
    VALIDATION_RULES
} from '@Utils/constants';
import { LABELS } from '@Utils/labels';
import { applyRegex } from '@Utils/text';
import { Description, EditOutlined, PersonRemove, VisibilityOutlined } from '@mui/icons-material';
import { AlertColor, Grid } from '@mui/material';
import cx from 'classnames';
import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';

import {
    FIELD_NAMES,
    formatAssetTag,
    formatBreadcrumb,
    formatEditValues,
    parseDateDetails,
    parseDateInput,
    prepAssignments,
    prepChanges
} from './Assets.utils';
import AssetInputModal from './Modals/AssetInputModal';

import './AssetDetails.scss';
import { SnackBarProps } from '../Shared/Snackbar/Snackbar.type';

const { ALERTS, ASSETS, BUTTONS, EMPTY_MESSAGES, EDIT_ASSET, ASSET_DETAILS, UNASSIGN_OWNER } =
    LABELS;
const { PREVIOUS_CRUMB, PREVIOUS_CRUMB_ROUTE } = ASSET_BREADCRUMBS;

const AssetDetails = () => {
    const [assetDetails, setAssetDetails] = useState<any>({});
    const { user } = useContext(UserContext);
    const [assignments, setAssignments] = useState([]);
    const [changes, setChanges] = useState([]);
    const [snackbar, setSnackbar] = useState<SnackBarProps>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isMessageOpen, setIsMessageOpen] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState<boolean>(false);
    const [unassingModalOpen, setUnassingModalOpen] = useState<boolean>(false);
    const { state } = useLocation();

    const { id } = useParams();
    const {
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm();

    const prepAsset = (asset: any) => {
        asset.id = asset._id;
        delete asset._id;
        return asset;
    };

    const setAlert = (message: string, severity = ALERTS.SEVERITIES.SUCCESS) => {
        setSnackbar({ alertMessage: message, alertSeverity: severity as AlertColor });
        setIsMessageOpen(true);
    };

    const getAsset = () => {
        AssetServices.getAsset(id)
            .then(({ data }) => {
                const asset = prepAsset(data[0]);
                setAssetDetails(asset);
                setAssignments(prepAssignments(asset.assignments));
                setChanges(prepChanges(asset.assetChanges));
                setIsLoading(false);
            })
            .catch(() => {
                setAlert(ASSET_DETAILS.FETCH_ERROR, ALERTS.SEVERITIES.ERROR);
            });
    };

    useEffect(() => {
        getAsset();
    }, []);

    const toggleEditModal = () => {
        setEditModalIsOpen(!editModalIsOpen);
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const toggleUnassignModal = () => {
        setUnassingModalOpen(!unassingModalOpen);
    };

    const editSubmitHandler = (assetFormData: any) => AssetServices.editAsset(id, assetFormData);

    const unassignOwnerHandler = (data: any) => {
        const updated = AssetServices.unassignAsset(id, data);
        updated.then(() => {
            getAsset();
            toggleUnassignModal();
            reset();
        });
    };

    const handleCloseMessage = () => {
        setIsMessageOpen(false);
    };

    const downloadDocument = (document: any) => () => window.open(document, '_blank');

    const getAssetDetailField = (field: any, asset: any) => {
        const value =
            (field.field === FIELD_NAMES.ASSET_TAG && formatAssetTag(asset)) || asset[field.field];
        const isBool = typeof value === 'boolean';
        if (field.field === FIELD_NAMES.PURCHASE_DATE) {
            return <span>{parseDateDetails(value) || COLUMNS_DEFAULT_VALUE}</span>;
        }
        if (!isBool) {
            return <span>{value || COLUMNS_DEFAULT_VALUE}</span>;
        }
        return (
            <div
                className={cx('ac2-field-icon', {
                    'ac2-field-icon--active': value
                })}
            />
        );
    };

    const createAssetDetailsColumn = (column: any, columnName: any, asset: any) => (
        <Grid
            container
            item
            md={12}
            lg={6}
            xl={4}
            justifyContent="start"
            alignItems="stretch"
            className="ac2-asset-details-column"
            key={columnName}
        >
            <h3 className="ac2-h3 ac2-column-title">{column.TITLE}</h3>

            {column.FIELDS.map((field: any) => (
                <Grid
                    container
                    className="ac2-field"
                    key={`${field.field}-${asset._id}`}
                    alignItems="stretch"
                >
                    <Grid item xs={12}>
                        <span className="ac2-field-header">{field.header}: </span>
                    </Grid>
                    <Grid item xs={12} className="ac2-field-content">
                        {getAssetDetailField(field, asset)}
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );

    const getRoute = () => {
        let route = PREVIOUS_CRUMB_ROUTE;
        if (state?.searchParam) {
            route += `?${SEARCH_PROP}=${state.searchParam}`;
            if (state?.page) {
                route += `&`;
            }
        }
        if (state?.page) {
            if (!state.searchParam) {
                route += `?`;
            }
            route += `${PAGE_PROP}=${state.page}`;
        }
        return route;
    };

    return (
        <>
            <CustomBreadcrumbs
                current={formatBreadcrumb(assetDetails)}
                previous={{
                    route: getRoute(),
                    name: PREVIOUS_CRUMB
                }}
            />
            <Grid
                item
                xs={12}
                container
                alignItems="center"
                justifyContent="space-between"
                className="ac2-asset-toolbar"
            >
                <Grid item xs={12} md={4}>
                    <h1 className="ac2-title">{ASSET_DETAILS.TITLE}</h1>
                </Grid>
                {checkRoles(ADMIN_ROLES, user) && (
                    <Grid
                        item
                        container
                        xs={12}
                        md={8}
                        justifyContent="flex-end"
                        alignItems="flex-end"
                    >
                        <CustomButton
                            label={BUTTONS.DOCUMENTS}
                            uiType="secondary"
                            variant="contained"
                            onClick={toggleModal}
                            startIcon={<VisibilityOutlined />}
                            classes="ac2-asset-btn"
                        />

                        <CustomButton
                            label={BUTTONS.EDIT}
                            uiType="primary--outlined"
                            variant="outlined"
                            onClick={toggleEditModal}
                            startIcon={<EditOutlined />}
                            classes="ac2-asset-btn"
                        />
                    </Grid>
                )}
            </Grid>

            <Grid container justifyContent="flex-start" className="ac2-asset-details-container">
                <Grid item xs={12}>
                    <h2 className="ac2-subtitle">{ASSETS.SUBTITLE}</h2>
                </Grid>
                {!isLoading ? (
                    Object.keys(ASSET_DETAILS_COLUMNS).map((column: any) =>
                        createAssetDetailsColumn(
                            ASSET_DETAILS_COLUMNS[column],
                            column,
                            assetDetails
                        )
                    )
                ) : (
                    <Loading />
                )}
            </Grid>
            {checkRoles(ADMIN_ROLES, user) && (
                <>
                    <Grid
                        container
                        item
                        xs={12}
                        justifyContent="flex-start"
                        alignItems="center"
                        className="ac2-asset-details-container ac2-asset-details-table"
                    >
                        <Grid item xs={12} md={4}>
                            <h2 className="ac2-subtitle">{ASSETS.OWNER_HISTORY}</h2>
                        </Grid>
                        <Grid
                            item
                            container
                            xs={12}
                            md={8}
                            justifyContent="flex-end"
                            alignItems="flex-end"
                        >
                            {assetDetails.owner && (
                                <CustomButton
                                    label={BUTTONS.UNASSIGN_OWNER}
                                    uiType="secondary"
                                    variant="outlined"
                                    onClick={toggleUnassignModal}
                                    startIcon={<PersonRemove />}
                                    classes="ac2-asset-btn"
                                />
                            )}
                        </Grid>
                        {!isLoading ? (
                            <CustomTable
                                columns={ASSET_OWNER_HISTORY_COLUMNS}
                                rows={assignments}
                                emptyMessage={EMPTY_MESSAGES.ASSET_OWNER_HISTORY}
                                emptyIcon={EmptyAssignments}
                            />
                        ) : (
                            <Loading />
                        )}
                    </Grid>

                    <Grid
                        container
                        item
                        xs={12}
                        justifyContent="flex-start"
                        className="ac2-asset-details-container ac2-asset-details-table"
                    >
                        <Grid item xs={12}>
                            <h2 className="ac2-subtitle">{ASSETS.CHANGES_HISTORY}</h2>
                        </Grid>
                        {!isLoading ? (
                            <CustomTable
                                columns={ASSET_CHANGES_HISTORY_COLUMNS}
                                rows={changes}
                                emptyMessage={EMPTY_MESSAGES.ASSET_CHANGES_HISTORY}
                                emptyIcon={EmptyChanges}
                            />
                        ) : (
                            <Loading />
                        )}
                    </Grid>
                </>
            )}

            {editModalIsOpen && (
                <AssetInputModal
                    title={EDIT_ASSET.TITLE}
                    modalIsOpen={editModalIsOpen}
                    handleClose={toggleEditModal}
                    setAlert={setAlert}
                    formatter={formatEditValues}
                    onSubmit={editSubmitHandler}
                    onSuccess={getAsset}
                    successMessage={EDIT_ASSET.SUCCESS}
                    storedAssetInfo={parseDateInput(assetDetails)}
                />
            )}

            {isModalOpen && (
                <CustomModal
                    title={ASSET_DETAILS.DOCUMENTS}
                    open={isModalOpen}
                    handleClose={toggleModal}
                    size={SIZES.MEDIUM}
                    titleStyle="documents"
                >
                    {assetDetails.documents && assetDetails.documents.length > 0 ? (
                        <div className="ac2-documents-container">
                            {assetDetails.documents.map((document: any) => (
                                <Grid
                                    container
                                    justifyContent="space-between"
                                    alignItems="center"
                                    className="ac2-document-item"
                                    key={document.filename}
                                >
                                    <p className="ac2-document-text">
                                        {applyRegex(document.filename, FILENAME_REGEX)}
                                    </p>
                                    <CustomIconButton
                                        variant="inverted-secondary"
                                        onClick={downloadDocument(document.url)}
                                        icon={<Description />}
                                        tooltip={BUTTONS.OPEN_DOCUMENT}
                                        classes="ac2-asset-btn"
                                    />
                                </Grid>
                            ))}
                        </div>
                    ) : (
                        <Grid container justifyContent="center" className="ac2-empty-table">
                            <Grid
                                item
                                container
                                xs={12}
                                justifyContent="center"
                                className="ac2-empty-icon"
                            >
                                <img
                                    src={EmptyDocuments}
                                    alt={LABELS.EDIT_ASSET.ALT_EMPTY_DOCUMENT}
                                />
                            </Grid>
                            <Grid item xs={12} container justifyContent="center">
                                <h1 className="ac2-error-title">{ASSET_DETAILS.DOCUMENTS_ERROR}</h1>
                            </Grid>
                        </Grid>
                    )}
                </CustomModal>
            )}

            {unassingModalOpen && (
                <CustomModal
                    title={UNASSIGN_OWNER.TITLE}
                    size={SIZES.EXTRA_SMALL}
                    handleClose={toggleUnassignModal}
                    open={unassingModalOpen}
                >
                    <form onSubmit={handleSubmit(unassignOwnerHandler)}>
                        <p className="ac2-modal-text">{UNASSIGN_OWNER.CONTENT}</p>
                        <CustomTextField
                            name={UNASSIGN_OWNER.FIELDS.REASON.NAME}
                            control={control}
                            label={UNASSIGN_OWNER.FIELDS.REASON.LABEL}
                            type="text"
                            fullWidth
                            defaultValue=""
                            classname="field"
                            helper={errors[UNASSIGN_OWNER.FIELDS.REASON.NAME]?.message}
                            rules={VALIDATION_RULES.UNASSIGN_OWNER.REASON}
                        />
                        <Grid container justifyContent="flex-start" gap={2}>
                            <CustomButton
                                label={BUTTONS.CANCEL}
                                uiType="secondary"
                                variant="outlined"
                                onClick={toggleUnassignModal}
                                classes="ac2-asset-btn"
                            />
                            <CustomButton
                                label={BUTTONS.CONFIRMATION}
                                uiType="primary"
                                variant="contained"
                                classes="ac2-asset-btn"
                                type="submit"
                            />
                        </Grid>
                    </form>
                </CustomModal>
            )}
            {snackbar && (
                <CustomSnackbar
                    isOpen={isMessageOpen}
                    handleClose={handleCloseMessage}
                    alertMessage={snackbar.alertMessage}
                    alertSeverity={snackbar.alertSeverity}
                />
            )}
        </>
    );
};

export default AssetDetails;
