import CustomButton from '@Shared/Button/Button';
import FormInputCheckbox from '@Shared/Form/FormInputCheckbox';
import FormInputDropdown from '@Shared/Form/FormInputDropdown';
import FormInputText from '@Shared/Form/FormInputText';
import { VALIDATION_RULES } from '@Utils/constants';
import { LABELS } from '@Utils/labels';
import './AssetModals.scss';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';

import { FIELD_NAMES } from '../Assets.utils';
import { DeviceInfoModalProps } from './AssetInputModal.type';
import { useEffect, useState } from 'react';

const DeviceInfo = ({
    deviceInfo,
    assetStatusInfo,
    assetTypeInfo,
    nextHandler
}: DeviceInfoModalProps) => {
    const {
        handleSubmit,
        control,
        watch,
        formState: { errors }
    } = useForm({
        defaultValues: deviceInfo
    });

    const sharedDeviceState = watch(FIELD_NAMES.SHARED_DEVICE);

    const allowOnlyNumbers = (e: any) => e.target.value.replace(/[^0-9]/g, '');

    return (
        <form onSubmit={handleSubmit(nextHandler)} className="ac2-form__info ac2-form__device">
            <Grid container columnSpacing={2} rowSpacing={{ xs: 2, sm: 4, md: 6 }}>
                <Grid item xs={12} sm={6} md={4}>
                    <FormInputText
                        name={FIELD_NAMES.ASSET_MAKE}
                        control={control}
                        label={LABELS.CREATE_ASSET.DEVICE_INFO.DESCRIPTION.MAKE}
                        fullWidth
                        helper={errors[FIELD_NAMES.ASSET_MAKE]?.message}
                        rules={VALIDATION_RULES.DEVICE_INFO_RULES.MAKE}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FormInputText
                        name={FIELD_NAMES.ASSET_MODEL}
                        control={control}
                        label={LABELS.CREATE_ASSET.DEVICE_INFO.DESCRIPTION.MODEL}
                        fullWidth
                        rules={VALIDATION_RULES.DEVICE_INFO_RULES.MODEL}
                        helper={errors[FIELD_NAMES.ASSET_MODEL]?.message}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FormInputText
                        name={FIELD_NAMES.ASSET_YEAR}
                        control={control}
                        label={LABELS.CREATE_ASSET.DEVICE_INFO.DESCRIPTION.YEAR}
                        fullWidth
                        onChangeFunc={allowOnlyNumbers}
                        baseInput={VALIDATION_RULES.DEVICE_INFO_RULES.YEAR_INPUT}
                        rules={VALIDATION_RULES.DEVICE_INFO_RULES.YEAR}
                        helper={errors[FIELD_NAMES.ASSET_YEAR]?.message}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FormInputDropdown
                        name={FIELD_NAMES.ASSET_TYPE}
                        control={control}
                        label={LABELS.CREATE_ASSET.DEVICE_INFO.ASSET_TYPE}
                        fullWidth
                        options={assetTypeInfo}
                        idField="id"
                        valueField="description"
                        defaultValue={deviceInfo.assetType}
                        helper={errors[FIELD_NAMES.ASSET_TYPE]?.message}
                        rules={VALIDATION_RULES.DEVICE_INFO_RULES.ASSET_TYPE}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FormInputDropdown
                        name={FIELD_NAMES.ASSET_STATUS}
                        control={control}
                        label={LABELS.CREATE_ASSET.DEVICE_INFO.ASSET_STATUS}
                        fullWidth
                        options={assetStatusInfo}
                        idField="id"
                        valueField="description"
                        defaultValue={deviceInfo.assetStatus}
                        helper={errors[FIELD_NAMES.ASSET_STATUS]?.message}
                        rules={VALIDATION_RULES.DEVICE_INFO_RULES.ASSET_STATUS}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FormInputText
                        name={FIELD_NAMES.OS_VERSION}
                        control={control}
                        label={LABELS.CREATE_ASSET.DEVICE_INFO.QA_DEVICE.OS_VERSION}
                        fullWidth
                        helper={errors[FIELD_NAMES.OS_VERSION]?.message}
                        rules={{
                            required: {
                                message: 'OS Version is required',
                                value: sharedDeviceState
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <FormInputText
                        name={FIELD_NAMES.DESCRIPTION_DETAILS}
                        control={control}
                        label={LABELS.CREATE_ASSET.DEVICE_INFO.DESCRIPTION.DETAILS}
                        fullWidth
                        multiline
                        rows={4}
                        helper={VALIDATION_RULES.DEVICE_INFO_RULES.DESCRIPTION.maxLength.message}
                        rules={VALIDATION_RULES.DEVICE_INFO_RULES.DESCRIPTION}
                    />
                </Grid>
                <Grid item xs={12} sm={5} md={5}>
                    <FormInputCheckbox
                        name={FIELD_NAMES.SHARED_DEVICE}
                        control={control}
                        label={LABELS.CREATE_ASSET.DEVICE_INFO.SHARED_DEVICE}
                        classname="shared-device"
                        tooltip
                        tooltipTitle={LABELS.CREATE_ASSET.DEVICE_INFO.SHARED_DEVICE_TOOLTIP}
                        tooltipOrientation="top-start"
                    />
                </Grid>
            </Grid>
            <Box>
                <CustomButton
                    variant="contained"
                    label={LABELS.CREATE_ASSET.NEXT_BUTTON}
                    type="submit"
                    uiType="secondary"
                    classes="ac2-button__next"
                    endIcon={<ArrowForwardIcon />}
                />
            </Box>
        </form>
    );
};

export default DeviceInfo;
