import { Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import cx from 'classnames';
import { isBoolean } from 'lodash';
import { Controller, ControllerRenderProps } from 'react-hook-form';

import './FormInput.scss';
import { FormInpuCheckboxProps } from './FormInput.type';

const FormInputCheckbox = ({
    name,
    control,
    label,
    classname,
    tooltip,
    tooltipTitle,
    tooltipOrientation,
    group,
    groupOptions = [],
    idField = '',
    valueField = '',
    onHandleCheck
}: FormInpuCheckboxProps) => {
    const defaultValues = groupOptions ? new Array(groupOptions.length).fill(false) : [];

    const renderCheckbox = ({ field }: { field: ControllerRenderProps }) => (
        <>
            {group ? (
                groupOptions.map((item: any) => (
                    <FormControlLabel
                        control={
                            <Checkbox
                                defaultChecked={defaultValues.includes(item[valueField])}
                                onChange={(e) => {
                                    onHandleCheck &&
                                        onHandleCheck(e, item[valueField], field.onChange);
                                }}
                                className={cx(
                                    `ac2-check__${classname}`,
                                    'ac2-input',
                                    'ac2-checkbox'
                                )}
                            />
                        }
                        key={item[idField]}
                        label={item[valueField]}
                    />
                ))
            ) : (
                <FormControlLabel
                    label={label}
                    control={
                        tooltip ? (
                            <Tooltip
                                title={tooltipTitle}
                                placement={tooltipOrientation}
                                disableFocusListener
                                arrow
                                describeChild
                            >
                                <div>
                                    <Checkbox
                                        checked={isBoolean(field.value) ? field.value : false}
                                        onChange={field.onChange}
                                        className={cx(
                                            'ac2-checkbox',
                                            `ac2-check__${classname}`,
                                            'ac2-input'
                                        )}
                                    />
                                </div>
                            </Tooltip>
                        ) : (
                            <Checkbox
                                checked={isBoolean(field.value) ? field.value : false}
                                onChange={field.onChange}
                                className={cx(`ac2-check__${classname}`, 'ac2-input')}
                            />
                        )
                    }
                />
            )}
        </>
    );

    return <Controller name={name} control={control} render={renderCheckbox} />;
};

export default FormInputCheckbox;
