export const LABELS = {
    HEADER: {
        WEB_TITLE: 'KG Asset Control',
        TABS: {
            HOME: 'Home',
            MY_ASSETS: 'My Assets',
            ASSETS: 'Assets',
            REPORTS: 'Reports',
            USERS: 'Users',
            SETTINGS: 'Settings',
            MY_ACCESSORIES: 'My Accessories',
            ACCESSORIES: 'Accessories'
        }
    },
    SEARCH_BAR: {
        SEARCH_BAR_TITLE: 'Search',
        YEAR_BAR_TITLE: 'Year',
        SEARCH_FIELD: 'search',
        YEAR_FIELD: 'year',
        LOCAL_STORAGE: 'searchedValues',
        ALT_SEARCH_BUTTON: 'Search Button',
        ALT_CLOSE_BUTTON: 'Close Search',
        ALT_CALENDAR_BUTTON: 'Calendar Search'
    },
    TABLE: {
        ACTIONS: 'Options',
        ALT_ACTION: 'Download',
        ALT_EMPTY: 'Empty Icon',
        TABLE_OPTIONS: {
            DISABLE_ALT_TEXT: 'disable-row-button',
            EDIT_ALT_TEXT: 'edit-row-button'
        },
        NO_ASSET_DETAILS: 'No Asset Details'
    },
    BUTTONS: {
        CONFIRMATION: 'Confirm',
        INFO: 'Info',
        NEW: 'New',
        WELCOME: 'Welcome',
        SIGN_OUT: 'Sign Out',
        SIGN_IN: 'Sign In',
        EDIT: 'Edit',
        DELETE: 'Delete',
        DOCUMENTS: 'Documents',
        DOWNLOAD: 'Download',
        OPEN_DOCUMENT: 'Open Document',
        CLOSE: 'Close',
        CANCEL: 'Cancel',
        NAVBAR_MENU: 'Navbar Menu',
        UNASSIGN_OWNER: 'Unassign Owner',
        REMOVE_ASSETS: 'Remove all assets',
        GENERATE_OFFBOARDING: 'Offboarding PDF'
    },
    ALERTS: {
        NEW: 'New!',
        ERROR_MSG: 'Error:',
        UPDATE_SUCCESS: 'Updated Successfully',
        CREATE_SUCCESS: 'Created Successfully',
        DISABLED_SUCCESS: 'Disabled Successfully',
        EMAIL_SUCCESS: 'Email Sent Succesfully',
        SEVERITIES: {
            SUCCESS: 'success',
            INFO: 'info',
            WARNING: 'warning',
            ERROR: 'error'
        }
    },
    NOT_FOUND: {
        ALT_MESSAGE: 'Not Found Image',
        TITLE: `Something's gone wrong`,
        SUBTITLE: 'This page does not exist.'
    },
    UNAUTHORIZED: {
        ALT_MESSAGE: 'Unauthorized Image',
        TITLE: `Something's gone wrong`,
        SUBTITLE: 'Invalid user.'
    },
    SERVER_ERROR: {
        ALT_MESSAGE: 'Server Error Image',
        TITLE: `Internal Server Error`,
        SUBTITLE: 'There was a problem obtaining this information.'
    },
    TAX_STATUS: {
        TITLE: 'Tax Status',
        DISABLE_MSG: 'Disable Tax Status?',
        FORM: {
            CREATE_TITLE: 'Create Tax Status',
            UPDATE_TITLE: 'Update Tax Status',
            DESCRIPTION: {
                FIELD: 'description',
                LABEL: 'Description'
            }
        }
    },
    ACCESSORIES: {
        TITLE: 'Accessories',
        SUBTITLE: 'Accessory Information',
        DISABLE_MSG: 'Disable Accessory?',
        OWNERS: 'Owners',
        FORM: {
            CREATE_TITLE: 'Create Accessory',
            UPDATE_TITLE: 'Update Accessory',
            DESCRIPTION: {
                FIELD: 'name',
                LABEL: 'Name'
            }
        },
        COLUMNS: {
            DETAILS: 'Accessory Details',
            INFO: 'Accessory Info',
            NAME: 'Name'
        }
    },
    ASSET_STATUS: {
        TITLE: 'Asset Status',
        DISABLE_MSG: 'Disable Asset Status?',
        FORM: {
            CREATE_TITLE: 'Create Asset Status',
            UPDATE_TITLE: 'Update Asset Status',
            DESCRIPTION: {
                FIELD: 'description',
                LABEL: 'Description'
            }
        }
    },
    ASSET_TYPE: {
        TITLE: 'Asset Type',
        DISABLE_MSG: 'Disable Asset Type?',
        FORM: {
            CREATE_TITLE: 'Create Asset Type',
            UPDATE_TITLE: 'Update Asset Type',
            CODE_FIELD: {
                FIELD: 'assetCode',
                LABEL: 'Code'
            },
            DESCRIPTION_FIELD: {
                FIELD: 'description',
                LABEL: 'Description'
            }
        }
    },
    EMAIL: {
        TITLE: 'Email',
        TEMPLATE_FIELD: 'Template',
        FORM: {
            UPDATE_TITLE: 'Update Template',
            NAME_FIELD: {
                LABEL: 'Template Name',
                NAME: 'templateName',
                DEFAULT_VALUE: 'templateName'
            },
            SUBJECT_FIELD: {
                LABEL: 'Subject',
                NAME: 'emailSubject',
                DEFAULT_VALUE: 'subject'
            },
            BODY_FIELD: {
                LABEL: 'Text Body',
                NAME: 'emailBody',
                DEFAULT_VALUE: 'textBody'
            }
        },
        WELCOME_FORM: {
            DESCRIPTION: 'Add a recipient to notify about their assigned assets.',
            TITLE: 'Send Welcome Email',
            USER_EMAIL: {
                LABEL: 'User Email',
                NAME: 'userEmail'
            }
        }
    },
    FOOTER: `© ${new Date().getFullYear()} Konrad. All Rights Reserved`,
    LOGIN: {
        TITLE: 'Asset Control',
        ALT_IMAGE: 'Background office',
        ALT_LOGO: 'Konrad logo',
        SUCCESS_MESSAGE: 'AUTHORIZED USER',
        UNAUTHORIZED_USER: 'Invalid user',
        ERROR_MESSAGE: 'Something went wrong'
    },
    LOGOUT: {
        ERROR: 'Error while trying to logout',
        BUTTON: 'Logout'
    },
    CREATE_ADMIN: {
        FORM: {
            TITLE: 'Create Admin',
            NAME_LABEL: 'Name',
            NAME_FIELD: 'name',
            EMAIL_LABEL: 'Email',
            EMAIL_FIELD: 'email',
            ROLE_SELECT: 'Role',
            SUBMIT_BUTTON: 'Confirm'
        }
    },
    EDIT_ADMIN: {
        FORM_TITLE: 'Edit Admin'
    },
    MAINSCREEN: {
        REDIRECT_TITLE: 'Mainscreen',
        TITLE: 'Welcome ',
        CHECK_OUT: {
            CARD_TITLE: 'Check-Out Events',
            ICON_ALT: 'Assets Check Out Icon'
        },
        CHECK_IN: {
            CARD_TITLE: 'Check-In Events',
            ICON_ALT: 'Assets Check In Icon'
        },
        CARD_TEXT: 'compared to last week',
        DEFAULT_PERCENTAGE: 'N/A',
        DEFAULT_AMOUNT: 0,
        CANT_COMPARE: "Can't compare with last week",
        WELCOME_TEXT: 'Welcome',
        DASHBOARD_FETCH_ERROR: 'Error retrieving info for dashboard',
        READER_STATUS: {
            CARD_TITLE: 'Reader Status',
            ICON_ALT: 'Reader Status Icon',
            OFFICE_TEXT: 'Office: '
        },
        READER_STATUS_FAILED_COUNT: {
            CARD_TITLE: 'Reader Weekly Fails',
            ICON_ALT: 'Reader Fail Count Icon',
            OFFICE_TEXT: 'Office: '
        },
        ASSETS_BY_STATUS: {
            CARD_TITLE: ' Assets',
            ICON_ALT: ' assets icon'
        },
        SUBTITLES: {
            SCANNER_INFO: 'Scanner Info',
            ASSETS_INFO: 'Assets Info'
        },
        NOTIFICATIONS: 'Notifications',
        ALL_NOTIFICATIONS_BUTTON: 'Show All Notifications'
    },
    USERS: {
        COMPONENT_NAME: 'Users',
        TABLE_ARIA_LABEL: 'Users Table',
        COLUMNS: {
            DATA: 'Data',
            OPTIONS: 'Disable'
        },
        ROWS: {
            NAME: 'Name',
            EMAIL: 'Email',
            ROLE: 'Role',
            DETAILS: 'User Details'
        },
        DISABLE_CONFIRMATION: 'Disable User?',
        DISABLE_ADMIN_SUCCESS: 'User disabled successfully',
        DISABLE_ADMIN_FAILURE: 'Error disabling user',
        NO_RESULTS: 'No results found',
        ADMIN_LOG_BUTTON: 'Admin Log',
        TITLE: 'Users',
        DETAILS: 'User Details',
        FETCH_ERROR: 'Error getting user data.'
    },
    SETTINGS: {
        MENU_LABEL: 'Settings',
        LOCATIONS_CARD: 'Locations',
        TAX_STATUS_CARD: 'Tax Status',
        ASSET_TYPES_CARD: 'Asset Types',
        ASSET_STATUS_CARD: 'Asset Status',
        EMAIL_CARD: 'Emails',
        ACCESSORY_CARD: 'Accessories'
    },
    LOCATION: {
        TITLE: 'Locations',
        DISABLE_MSG: 'Disable Location?',
        FORM: {
            CREATE_TITLE: 'Create Location',
            UPDATE_TITLE: 'Update Location',
            NAME_FIELD: { LABEL: 'Name', NAME: 'name' },
            LOCATION_FIELD: {
                LABEL: 'Location',
                NAME: 'location'
            },
            COUNTRY_FIELD: {
                LABEL: 'Country',
                NAME: 'country'
            },
            DETAILS_FIELD: { LABEL: 'Details', NAME: 'details', FIELD: 'description' }
        }
    },
    CREATE_ASSET: {
        TITLE: 'Create Asset',
        UPLOAD: 'Upload Assets',
        TEMPLATE: 'Template',
        ALERT_MESSAGES: {
            SUCCESS: 'Asset Created Successfully',
            FAILURE: 'An error ocurred while creating the asset'
        },
        CONFIRM_BUTTON: 'Confirm',
        NEXT_BUTTON: 'Next',
        BACK_BUTTON: 'Back',
        NEW_BUTTON: 'New',
        DEVICE_INFO: {
            TITLE: 'Device Info',
            ASSET_TYPE: 'Asset Type',
            ASSET_STATUS: 'Status',
            DESCRIPTION: {
                MODEL: 'Model',
                MAKE: 'Make',
                YEAR: 'Year',
                DETAILS: 'Asset Description'
            },
            QA_DEVICE: {
                OS_VERSION: 'OS Version'
            },
            SHARED_DEVICE: 'Shared QA Device',
            SHARED_DEVICE_TOOLTIP:
                'The shared device can be re-assigned to any user in a daily basis for QA purporses'
        },
        LOCATION_INFO: {
            TITLE: 'Location Info',
            ASSET_LOCATION: {
                OFFICE: 'Office',
                LOCATION_DETAILS: 'Details',
                COUNTRY: 'Country',
                LOCATION: 'Location'
            },
            IN_OFFICE: 'In Location'
        },
        LEGAL_INFO: {
            TITLE: 'Legal Info',
            SERIAL_NUMBER: 'Serial Number',
            ASSET_NUMBER: 'Asset Number',
            RFID: 'RFID',
            TAX_STATUS: 'Tax Status',
            VENDOR: 'Vendor',
            DATE_OF_PURCHASE: 'Date of Purchase',
            WARRANTY_DURATION: 'Warranty Duration',
            DOCUMENTS: 'Documents',
            DOCUMENTS_BUTTON: 'Upload',
            INVOICE_NUMBER: 'Invoice Number',
            INSIDE_REGIME: 'Inside Regime'
        }
    },
    ASSIGN_ASSETS: {
        TITLE: 'Assign Asset',
        OWNER_LABEL: 'Owner',
        DESCRIPTION_LABEL: 'Notes',
        ASSETS_LABEL: 'Assets',
        ACCESSORIES_LABEL: 'Accessories',
        ASSIGN_BUTTON: 'Assign',
        CONFIRM_BUTTON: 'Confirm',
        CANCEL_BUTTON: 'Cancel',
        ADD_BUTTON: 'Add Asset',
        CONFIRM_MESSAGE: 'The assets are assign',
        ONWER_TOOLTIP: 'Owner field must be a KG email',
        ALERT_MESSAGES: {
            SUCCESS: 'Assets Assign Successfully',
            FAILURE: 'No Assets Assigned'
        }
    },
    ASSET_CHANGE_HISTORY: {
        CHANGE_TYPE: 'ChangeType',
        DETAILS: 'Details',
        CREATED_AT: 'Created At',
        MADE_BY: 'Made By'
    },
    ASSET_OWNER_HISTORY: {
        OWNER: 'Owner',
        DATE: 'Date'
    },
    ASSET_MODAL: {
        DROPDOWN_OPTIONS_FETCH_ERROR: 'Error retrieving info for dropdowns from the backend'
    },
    EDIT_ASSET: {
        TITLE: 'Edit Asset',
        SUCCESS: 'Asset edited successfully',
        FAILURE: 'Error editing the asset',
        ALT_EMPTY_DOCUMENT: 'Empty Icon'
    },
    ASSETS: {
        TITTLE: 'Assets',
        SUBTITLE: 'Asset Information',
        OWNER_HISTORY: 'Owner History',
        CHANGES_HISTORY: 'Changes History',
        COLUMNS: {
            ASSET_TAG: 'Asset Tag',
            RFID: 'RFID',
            OWNER: 'Owner',
            IN_OFFICE: 'In Office',
            DETAILS: 'Asset Details',
            SERIAL_NUMBER: 'Serial Number',
            ASSET_TYPE: 'Asset Type'
        },
        FETCH_ERROR: 'Error fetching assets',
        NO_RESULTS: 'No results found',
        DETAILS_LABEL: 'View More'
    },
    ASSET_DETAILS: {
        TITLE: 'Asset Details',
        EDIT_BUTTON: 'Edit',
        DETAILS: 'Details',
        DOCUMENTS: 'Documents',
        FETCH_ERROR: 'Error fetching the asset',
        DOCUMENTS_ERROR: 'No Documents to show',
        REMOVE_MESSAGE: 'Remove this asset from this user?'
    },
    ACCESSORY_DETAILS: {
        TITLE: 'Accessory Details',
        EDIT_BUTTON: 'Edit',
        DETAILS: 'Details',
        FETCH_ERROR: 'Error fetching the accessory',
        FIELDS: {
            NAME: 'Name',
            USER_AMOUNT: 'Users with this accessory'
        },
        CHANGES_HISTORY: 'Changes History',
        REMOVE_MESSAGE: 'Remove this accessory from this user?'
    },
    DEFAULT_LINK_LABEL: 'View More',
    DEFAULT_LINK_ASSET: 'View Asset',
    EMPTY_MESSAGES: {
        ASSET_CHANGES_HISTORY: 'No Changes for this asset',
        ACCESSORY_CHANGES_HISTORY: 'No Changes for this accessory',
        ASSET_OWNER_HISTORY: 'No assignments for this asset',
        ACCESSORY_OWNER_HISTORY: 'No assignments for this accessory',
        ASSET_STATUS: 'No Assets Status Found.',
        ASSET_TYPE: 'No Assets Type Found.',
        TAX_STATUS: 'No Tax Status Found.',
        LOCATION: 'No Locations Found.',
        ADMIN: 'No Admins Found.',
        REPORTS: 'No Reports Found',
        REPORT_DETAILS: 'No information found for this Report.',
        ASSETS: 'No Assets Found',
        SCANNER_LOG: 'No Scanner logs Found',
        EMAILS: 'No Emails Found',
        ADMIN_LOG: 'No Admin Logs Found',
        NOTIFICATIONS: 'No Notifications Found',
        ACCESSORIES: 'No Accessories Found',
        USERS: 'No Users Found.'
    },
    SCANNER_LOG: {
        TITLE: 'Scanner Log',
        NO_INFO: 'No Scanner Logs found',
        TABLE: {
            OFFICE: 'Office',
            DATE: 'Date & Time',
            EVENT: 'Event',
            RFID: 'RFID',
            OWNER: 'Owner',
            DETAILS: 'Asset Details'
        },
        DEFAULT_COLUMN_VALUE: {
            OFFICE: 'No Office',
            DATE: 'No Date',
            EVENT: 'No Event',
            RFID: 'No RFID',
            OWNER: 'Unassigned',
            DETAILS: 'No Asset Details'
        }
    },
    REPORTS: {
        TITLE: 'Reports',
        SUBTITLE: 'Report information',
        BUTTON_NEW: 'New',
        BUTTON_LOG: 'Logs',
        NO_INFO: 'No Reports found',
        ALERT: {
            ERROR_MESSAGE: 'Fail to retrieve reports'
        },
        TABLE: {
            REPORT_NAME: 'Report Name',
            REPORT_TYPE: 'Report Type',
            GENERATED: 'Generated By',
            DATE: 'Date',
            DOWNLOAD: 'Download'
        },
        MODAL: {
            HELPER_TEXT: 'Write the keywords and hit Confirm',
            TITLES: {
                CREATE_REPORT: 'Create Report',
                REPORT_INFO: 'Report Info',
                REPORT_DATA: 'Report Data',
                FILTERS: 'Filters',
                SELECT_FILTERS: 'Select Filters'
            },
            ALERT_MESSAGES: {
                SUCCES: 'Report Created Successfully',
                FAILURE: 'An error ocurred while creating the report',
                INVALID_FILTER: 'There is an invalid value'
            },
            REPORT_NAME: 'Name',
            DEFAULT_FILTER: 'Select a new filter',
            FILTERS: {
                ASSET_TYPE: 'Asset Type',
                ASSET_STATUS: 'Status',
                DESCRIPTION: {
                    MODEL: 'Model',
                    MAKE: 'Make',
                    YEAR: 'Year',
                    DETAILS: 'Asset Description'
                },
                QA_DEVICE: {
                    OS_VERSION: 'OS Version',
                    SHARED_DEVICE: 'Shared QA Device'
                },
                ASSET_LOCATION: {
                    OFFICE: 'Office',
                    LOCATION_DETAILS: 'Location Details',
                    COUNTRY: 'Country',
                    LOCATION: 'Location'
                },
                IN_OFFICE: 'In Location',
                SERIAL_NUMBER: 'Serial Number',
                ASSET_NUMBER: 'Asset Number',
                RFID: 'RFID',
                TAX_STATUS: 'Tax Status',
                VENDOR: 'Vendor',
                DATE_OF_PURCHASE: 'Date of Purchase',
                WARRANTY_DURATION: 'Warranty Duration',
                INSIDE_REGIME: 'Inside Regime',
                INVOICE_NUMBER: 'Invoice Number',
                ASSIGNED_EMPLOYEE: 'Assign Employee',
                START_DATE: 'Start Date',
                START_DATE_HELPER: 'Start Date now is required',
                START_DATE_COMPLEMENT: 'From:',
                END_DATE: 'End Date',
                END_DATE_HELPER: 'End Date now is required',
                END_DATE_COMPLEMENT: 'To:',
                DEFAULT: 'Select a new filter'
            },
            ADD_BUTTON: 'Add',
            CONFIRM_BUTTON: 'Confirm',
            BACK_BUTTON: 'Back',
            CREATED_AT: 'Created At',
            LOGGED_AT: 'Logged At',
            OPTIONS: 'Options'
        },
        FILE_UPLOAD: {
            SIZE_ERROR: 'Files must be under 4 MB and must either be PDF, JPEG or PNG'
        },
        FILENAME_HELPER: 'Filename is required',
        DETAILS: 'Report Details',
        FILTER: 'Filters',
        DETAIL_COLUMNS: {
            SERIAL_NUMBER: 'Serial Number',
            ASSET_TAG: 'Asset Tag',
            RFID: 'RFID',
            ASSET_TYPE_CODE: 'Asset Type Code',
            ASSET_TYPE: 'Asset Type',
            ASSET_STATUS: 'Asset Status',
            TAX_STATUS: 'Tax Status',
            INVOICE_NUMBER: 'Invoice Number',
            INSIDE_REGIME: 'Inside Regime',
            ASSET_MODEL: 'Asset Model',
            ASSET_MAKE: 'Asset Make',
            ASSET_YEAR: 'Asset Year',
            ASSET_DETAILS: 'Asset Details',
            VENDOR: 'Vendor',
            PURCHASE_DATE: 'Purchase Date',
            WARRANTY_DURATION: 'Warranty Duration',
            OS_VERSION: 'OS Version',
            SHARED_DEVICE: 'Shared Device',
            OFFICE_NAME: 'Office Name',
            OFFICE_COUNTRY: 'Office Country',
            OFFICE_LOCATION: 'Office Location',
            LOCATION_DETAIL: 'Location Detail',
            IN_OFFICE: 'In Office',
            CREATED_AT: 'Created At',
            TIMEZONE: 'Timezone',
            OWNER_EMAIL: "Owner's Email",
            LOGGED_AT: 'Logged At',
            LOG_TYPE: 'Log Type'
        }
    },
    IS_REQUIRED: 'is required.',
    FILTERS: 'Filters',
    ADMIN_LOG: {
        TITLE: 'Admin Log',
        NO_INFO: 'No Admin Logs found',
        TABLE: {
            MADE_BY: 'Made By',
            LOGGED_AT: 'Logged At',
            CHANGE_TYPE: 'Type of Change',
            DETAILS: 'Change Details'
        },
        DEFAULT_COLUMN_VALUE: {
            MADE_BY: 'No Made By',
            LOGGED_AT: 'No Logged At',
            CHANGE_TYPE: 'No Type of Change',
            DETAILS: 'No Change Details'
        }
    },
    NOTIFICATIONS: {
        TITLE: 'All Notifications',
        NO_INFO: 'No Notifications found',
        TABLE: {
            DATE: 'Date & Time',
            MESSAGE: 'Message',
            TYPE: 'Type',
            DETAILS: 'Asset Details'
        },
        DEFAULT_COLUMN_VALUE: {
            DATE: 'No Date',
            MESSAGE: 'No Message',
            TYPE: 'No Type',
            DETAILS: 'No Asset Details'
        }
    },
    UNASSIGN_OWNER: {
        TITLE: 'Remove Owner',
        CONTENT: 'Are you sure you want to unassign this asset from its current user?',
        REASON: 'One more thing',
        FIELDS: {
            REASON: {
                NAME: 'reason',
                LABEL: 'Reason'
            },
            USER: {
                EMAIL: 'employeeEmail',
                LABEL: 'Email'
            }
        }
    },
    OFFBOARDING: {
        TITLE: 'Generate PDF',
        CONTENT: 'Are you sure you want to generate an Offboarding PDF for this user?',
        NOTE: 'This will NOT remove assets from the user.',
        SUCCESS: 'The PDF was created successfully.'
    },
    UPLOAD_ASSET: {
        TITLE: 'Asset upload',
        PROCESSING: 'Processing asset #$1 of $2...',
        COMPLETE: 'Successfully processed $1 assets out of $2. Failed to upload $3 assets.',
        ERROR: {
            NO_DATA: 'No data found in the excel file!',
            READ: 'Could not read the uploaded file. Please ensure you are using the template format'
        }
    }
};

export const EMAIL_DATA = {
    LOCATIONS: {
        CR: 'San José, Costa Rica'
    },
    COMPANIES: {
        KONRAD: 'Konrad Group'
    }
};
