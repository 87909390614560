export const addZeroToDate = (date) => (date < 10 ? `0${date}` : `${date}`);

/* 
    Convert UTC date to LocaleString changing the date depending on user's time zone
    example: 2022-05-24T09:25:50.682+00:00 -> '5/24/2022, 3:25:50 AM'
*/
export const getDateString = (date) => {
    if (date) {
        const dateString = new Date(date);
        return dateString.toLocaleString();
    }
    return undefined;
};

/* 
    Convert UTC date to LocaleDateString changing the date depending on user's time zone
    example: 2022-05-24T09:25:50.682+00:00 -> '5/24/2022'
*/
export const getOnlyDateString = (date) => {
    if (date) {
        const dateString = new Date(date);
        return dateString.toLocaleDateString();
    }
    return undefined;
};
