import { useContext, useEffect, useState } from 'react';

import UserContext from '@Context/userContext';
import GET_CR_EMPLOYEES from '@Graph/Queries/user';
import AdminService from '@Services/Admin.services';
import CustomButton from '@Shared/Button/Button';
import FormInputDropdown from '@Shared/Form/FormInputDropdown';
import FormInputMultiTag from '@Shared/Form/FormInputMultiTag';
import Loading from '@Shared/Loading/Loading';
import CustomModal from '@Shared/Modal/Modal';
import ServerError from '@Shared/ServerError/ServerError';
import { showErrorAlert } from '@Utils/alerts';
import {
    ADMIN_MODAL_MESSAGES,
    AUTO_COMPLETE_STYLE,
    CR_OFFICE,
    SIZES,
    VALIDATION_RULES
} from '@Utils/constants';
import { LABELS } from '@Utils/labels';
import './AdminModal.scss';
import { renderEmployeeLabel } from '@Utils/text';
import { userMapper } from '@Utils/user.utils';
import { useQuery } from '@apollo/client';
import CheckIcon from '@mui/icons-material/Check';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { AdminModalProps } from './Admin.type';
import { RuleType } from '../Shared/Form/FormInput.type';

const {
    CREATE_ADMIN,
    EDIT_ADMIN: { FORM_TITLE: EDIT_TITLE }
} = LABELS;
const { FORM } = CREATE_ADMIN;

const { CREATION_SUCCESS, UPDATE_SUCCESS } = ADMIN_MODAL_MESSAGES;

const AdminModal = ({
    creationHandler,
    modalIsOpen,
    handleClose,
    showAlert,
    editing,
    userInfo
}: AdminModalProps) => {
    const { user } = useContext(UserContext);

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
        setValue
    } = useForm();
    const [rolesAreLoading, setRolesAreLoading] = useState(true);
    const [roles, setRoles] = useState<any[]>([]);

    useEffect(() => {
        AdminService.getAdminTypes()
            .then((result) => {
                setRoles(result);
                setRolesAreLoading(false);
                if (userInfo.userTypeDescription) {
                    const { id } = result.find(
                        (props: any) => props.description === userInfo.userTypeDescription
                    );
                    reset({ role: id });
                } else {
                    reset({ role: result[0].id });
                }
            })
            .catch(({ response }) => {
                showErrorAlert(showAlert, response);
            });
    }, []);

    const onSubmit = (props: any) => {
        const request = editing
            ? AdminService.editAdmin(userInfo._id, props.role)
            : AdminService.createAdmin(props.name, props.email, props.role);

        request
            .then(() => {
                const alert = editing ? UPDATE_SUCCESS : CREATION_SUCCESS;
                creationHandler();
                handleClose();
                showAlert(alert);
                if (editing && user && userInfo.email === user.email) {
                    window.location.reload();
                }
            })
            .catch(({ response }) => {
                showErrorAlert(showAlert, response);
            });
    };

    const setUserInfo = (name: string, values: any) => {
        setValue(name, renderEmployeeLabel(values));
        setValue(FORM.EMAIL_FIELD, values.email);
    };

    const getForm = () => {
        const { loading, error, data } = useQuery(GET_CR_EMPLOYEES, {
            variables: { office: CR_OFFICE }
        });

        if (loading) return <Loading size={SIZES.EXTRA_SMALL} />;
        if (error) return <ServerError />;

        const people = data.peopleV2;

        const mappedPeople = people.map(userMapper);

        return !rolesAreLoading ? (
            <form id="new-admin-form" onSubmit={handleSubmit(onSubmit)} className="ac2-form__info">
                {!editing && (
                    <FormInputMultiTag
                        name={FORM.NAME_FIELD}
                        label={FORM.NAME_LABEL}
                        control={control}
                        setValue={setUserInfo}
                        options={mappedPeople}
                        renderOptionLabel={renderEmployeeLabel}
                        style={AUTO_COMPLETE_STYLE}
                        helper={errors[FORM.NAME_FIELD]?.message || ''}
                        rules={VALIDATION_RULES.ADMIN_RULES.NAME as unknown as RuleType}
                    />
                )}
                {editing && (
                    <Grid container>
                        <Grid item xs={12} container className="ac2-admin-field">
                            <Grid item xs={12} md={3} xl={2}>
                                <span className="ac2-field-header">{FORM.NAME_LABEL}: </span>
                            </Grid>

                            <Grid item xs={12} md={9} xl={10}>
                                <span className="ac2-field-content">{userInfo.name}</span>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container className="ac2-admin-field">
                            <Grid item xs={12} md={3} xl={2}>
                                <span className="ac2-field-header">{FORM.EMAIL_LABEL}: </span>
                            </Grid>
                            <Grid item xs={12} md={9} xl={10}>
                                <span className="ac2-field-content">{userInfo.email}</span>
                            </Grid>
                        </Grid>
                    </Grid>
                )}

                <FormInputDropdown
                    name="role"
                    control={control}
                    label={FORM.ROLE_SELECT}
                    fullWidth
                    options={roles}
                    idField="id"
                    valueField="description"
                    defaultValue={roles ? roles[0].id : '-1'}
                    classes="ac2-select"
                    inputClasses="ac2-text"
                    rules={{}}
                />

                <CustomButton
                    startIcon={<CheckIcon />}
                    label={FORM.SUBMIT_BUTTON}
                    variant="contained"
                    onClick={handleSubmit(onSubmit)}
                    uiType="secondary"
                />
            </form>
        ) : (
            <Loading size={SIZES.EXTRA_SMALL} />
        );
    };

    return (
        <CustomModal
            open={modalIsOpen}
            handleClose={handleClose}
            size="xs"
            title={editing ? EDIT_TITLE : FORM.TITLE}
            id="new-admin-modal"
        >
            {getForm()}
        </CustomModal>
    );
};

export default AdminModal;
