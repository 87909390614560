import React, { useContext, useEffect, useState } from 'react';

import LogoXS from '@Assets/Images/konrad-logo-xs.svg?react';
import Logo from '@Assets/Images/konrad-logo.svg?react';
import UserContext from '@Context/userContext';
import { checkRoles } from '@Utils/auth';
import { LABELS } from '@Utils/labels';
import MenuIcon from '@mui/icons-material/Menu';
import { Grid } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import './Navbar.scss';

import { findActualPage, pages } from './Navbar.utils';
import CustomButton from '../Button/Button';
import { NavbarPageProps } from './Navbar.type';
import config, { ConfigProps } from '~/config';

const { BUTTONS } = LABELS;

const env = import.meta.env.VITE_APP_DEPLOY_ENV;

const pathsConfig: ConfigProps = config;

const { logoutUrl, path } = pathsConfig.urls[env];

const Navbar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [anchorElNav, setAnchorElNav] = useState(null);
    const { user, setUser } = useContext(UserContext);
    const [actualTarget, setActualTarget] = useState(findActualPage(location, user?.accessLevel));

    useEffect(() => {
        document.title = actualTarget.label;
        setActualTarget(findActualPage(location, user.accessLevel));
    }, [location]);

    const navigateTo = (page: NavbarPageProps) => {
        setActualTarget(page);
        navigate(page.path);
        setAnchorElNav(null);
    };

    const signOutKG = () => {
        const logoutPath = `${logoutUrl}?returnUrl=${path}`;
        setUser(null);
        window.location = logoutPath as unknown as Location;
    };

    const handleOpenNavMenu = (event: any) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const isActivePath = (page: NavbarPageProps) => actualTarget.path === page.path;

    const handleLogoClick = () => navigateTo(pages(user?.accessLevel).HOME);

    const renderPages = () =>
        Object.values(pages(user?.accessLevel)).map(
            (page) =>
                checkRoles(page.roles, user) && (
                    <MenuItem
                        key={page.label}
                        onClick={() => navigateTo(page)}
                        className={cx({ 'ac2-nav-item-mobile--active': isActivePath(page) })}
                    >
                        <Typography textAlign="center">{page.label}</Typography>
                    </MenuItem>
                )
        );

    const renderMenuItems = () =>
        Object.values(pages(user?.accessLevel)).map(
            (page) =>
                checkRoles(page.roles, user) && (
                    <React.Fragment key={page.label}>
                        <CustomButton
                            variant="text"
                            onClick={() => navigateTo(page)}
                            classes={cx('ac2-nav-item ac2-nav-item-ltr', {
                                'ac2-nav-item--active': isActivePath(page)
                            })}
                            label={page.label}
                            uiType=""
                        />
                    </React.Fragment>
                )
        );

    return (
        <AppBar position="static" color="transparent" className="ac2-navbar">
            <Toolbar disableGutters>
                <Grid
                    item
                    xs={12}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ display: { xs: 'flex', md: 'none' } }}
                >
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                            aria-label={BUTTONS.NAVBAR_MENU}
                            className="ac2-navbar-menu"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left'
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' }
                            }}
                            className="menu"
                        >
                            {renderPages()}
                            <MenuItem key="sign-out" onClick={signOutKG}>
                                <Typography textAlign="center">{BUTTONS.SIGN_OUT}</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'flex', md: 'none' },
                            justifyContent: 'flex-end',
                            alignItems: 'center'
                        }}
                    >
                        <LogoXS className="ac2-logo--xs" />
                    </Box>
                </Grid>
                <Box
                    component="div"
                    sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-start' }}
                    className="ac2-logo-container"
                >
                    <Logo onClick={handleLogoClick} className="ac2-logo" />
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        display: { xs: 'none', md: 'flex' },
                        justifyContent: 'flex-end'
                    }}
                >
                    {renderMenuItems()}
                    <Box sx={{ py: 1 }}>
                        <CustomButton
                            label={BUTTONS.SIGN_OUT}
                            onClick={signOutKG}
                            uiType="secondary"
                            variant="contained"
                        />
                    </Box>
                </Box>
            </Toolbar>
        </AppBar>
    );
};
export default Navbar;
