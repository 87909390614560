export type ConfigProps = {
    urls: {
        [key: string]: {
            logoutUrl: string;
            path: string;
            backendUrl: string;
            graphqlUrl: string;
            loginUrl: string;
            portal?: string;
        };
    };
};

const config: ConfigProps = {
    urls: {}
};

config.urls.development = {
    path: 'http://localhost:3000',
    backendUrl: 'http://localhost:3001',
    graphqlUrl: 'http://localhost:6170/query',
    loginUrl: 'https://auth.stage.kgportal.com',
    logoutUrl: 'https://auth.stage.kgportal.com/logout'
};

config.urls.staging = {
    path: 'https://assetcontrol.staging.kgportal.com',
    portal: 'https://stage.kgportal.com',
    backendUrl: 'https://api.assetcontrol.staging.kgportal.com/',
    graphqlUrl: '/query',
    loginUrl: 'https://auth.stage.kgportal.com',
    logoutUrl: 'https://auth.stage.kgportal.com/logout'
};

config.urls.production = {
    path: '',
    backendUrl: '',
    graphqlUrl: '/query',
    loginUrl: 'https://auth.kgportal.com',
    logoutUrl: 'https://auth.kgportal.com/logout'
};

export default Object.freeze(config);
