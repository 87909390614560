import { API_ROUTES, HTTP_METHODS } from '@Utils/constants';
import { sendCall } from '@Utils/requestPrep';

const { DASHBOARD } = API_ROUTES;

class DashboardService {
    getWeeklyComparisons = () =>
        sendCall(DASHBOARD.WEEKLY_COMPARISONS, {
            method: HTTP_METHODS.GET
        });

    getReaderServiceStatus = () =>
        sendCall(DASHBOARD.READER_SERVICE_STATUS, {
            method: HTTP_METHODS.GET
        });

    getAssetsByAssetStatus = () =>
        sendCall(DASHBOARD.ASSETS_BY_ASSET_STATUS, {
            method: HTTP_METHODS.GET
        });

    getWeeklyReaderServiceStatusCount = () =>
        sendCall(DASHBOARD.READER_SERVICE_STATUS_COUNT, {
            method: HTTP_METHODS.GET
        });
}

export default new DashboardService();
