import { createTheme } from '@mui/material';

import colors from './variables.module.scss';

export const COLORS = Object.freeze(colors);

export const theme = createTheme({
    palette: {
        primary: {
            main: COLORS.primary
        },
        secondary: {
            main: COLORS.secondary
        },
        background: {
            main: COLORS.background_color
        },
        text: {
            primary: COLORS.secondary,
            disabled: COLORS.secondary
        },
        action: {
            disabledOpacity: 0.75
        }
    },
    typography: {
        fontFamily: ['HelveticaNeue', 'sans-serif'].join(','),
        button: {
            textTransform: 'none'
        }
    }
});

export default theme;
