import { API_ROUTES, HTTP_METHODS, PAGE_SIZE } from '@Utils/constants';
import { sendCall } from '@Utils/requestPrep';

const { ASSET_STATUS } = API_ROUTES;

class AssetStatusService {
    createAssetStatus = (params) =>
        sendCall(ASSET_STATUS.POST, { method: HTTP_METHODS.POST, data: params });

    updateAssetStatus = (id, params) =>
        sendCall(`${ASSET_STATUS.PUT}/${id}`, { data: params, method: HTTP_METHODS.PUT });

    getAssetStatus = (page, pagination) =>
        sendCall(`${ASSET_STATUS.GET}?page=${page}&limit=${PAGE_SIZE}&pagination=${pagination}`, {
            method: HTTP_METHODS.GET
        });

    disableAssetStatus = (id) =>
        sendCall(`${ASSET_STATUS.DISABLE}/${id}`, { method: HTTP_METHODS.POST });

    searchAssetStatus = (params, page) =>
        sendCall(`${ASSET_STATUS.SEARCH_POST}?page=${page}&limit=${PAGE_SIZE}`, {
            data: params,
            method: HTTP_METHODS.POST
        });
}

export default new AssetStatusService();
