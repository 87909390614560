import './NotFound.scss';
import NotFoundSVG from '@Assets/Images/404.svg';
import { LABELS } from '@Utils/labels';
import { Grid } from '@mui/material';

const { NOT_FOUND } = LABELS;

const NotFound = () => (
    <Grid container justifyContent="center" alignItems="center" className="ac2-not-found-container">
        <Grid item xs={12} container justifyContent="center">
            <img src={NotFoundSVG} alt={NOT_FOUND.ALT_MESSAGE} className="ac2-not-found-img" />
        </Grid>

        <Grid item xs={12} container justifyContent="center">
            <h1 className="ac2-error-title">{NOT_FOUND.TITLE}</h1>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
            <h2 className="ac2-error-subtitle">{NOT_FOUND.SUBTITLE}</h2>
        </Grid>
    </Grid>
);

export default NotFound;
