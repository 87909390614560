import React from 'react';

import { LABELS } from '@Utils/labels';
import CloseIcon from '@mui/icons-material/Close';
import { Modal } from '@mui/material';
import Box from '@mui/material/Box';
import cx from 'classnames';
import './Modal.scss';

import CustomIconButton from '../IconButton/IconButton';
import { CustomModalProps } from './Modal.type';

const { BUTTONS } = LABELS;

const CustomModal = ({
    id,
    children,
    open,
    handleClose,
    size,
    title,
    titleStyle = 'style',
    allowClose = true
}: CustomModalProps) => {
    return (
        <Modal
            open={open}
            onClose={allowClose ? handleClose : () => {}}
            className="ac2-modal"
            key={id}
        >
            <Box className={cx('ac2-modal-box', `ac2-modal-box--${size}`)}>
                {children}
                <Box className="ac2-heading-box">
                    <h1 className={`ac2-modal-title ac2-modal-title__${titleStyle}`}>{title}</h1>
                    {allowClose && (
                        <CustomIconButton
                            onClick={handleClose}
                            icon={<CloseIcon />}
                            variant="close"
                            tooltip={BUTTONS.CLOSE}
                        />
                    )}
                </Box>
            </Box>
        </Modal>
    );
};

export default CustomModal;
