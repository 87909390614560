import { useEffect, useState } from 'react';

import GET_CR_EMPLOYEES from '@Graph/Queries/user';
import AccessoriesServices from '@Services/Accessories.services';
import AssetServices from '@Services/Asset.services';
import CustomButton from '@Shared/Button/Button';
import FormInputMultiTag from '@Shared/Form/FormInputMultiTag';
import FormInputText from '@Shared/Form/FormInputText';
import Loading from '@Shared/Loading/Loading';
import CustomModal from '@Shared/Modal/Modal';
import ServerError from '@Shared/ServerError/ServerError';
import { showErrorAlert } from '@Utils/alerts';
import { AUTO_COMPLETE_STYLE, CR_OFFICE, LIMIT_TAG, VALIDATION_RULES } from '@Utils/constants';
import { LABELS } from '@Utils/labels';
import { renderEmployeeLabel } from '@Utils/text';
import { userMapper } from '@Utils/user.utils';
import './AssignAssets.scss';
import { useQuery } from '@apollo/client';
import CheckIcon from '@mui/icons-material/Check';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';

import { formatAccessoryData, formatData } from './AssignAssets.utils';
import { AssignAssetsFieldnames, AssignAssetsForm, AssignAssetsProps } from './AssignAssets.type';

const AssignAssets = ({
    modalIsOpen,
    toogleModal,
    setAlert,
    onSuccess,
    successMessage,
    failureMessage,
    setIsLoading
}: AssignAssetsProps) => {
    const [assetValues, setAssetValues] = useState<any[]>([]);
    const [accessoryValues, setAccessoryValues] = useState<any[]>([]);

    const {
        handleSubmit,
        reset,
        control,
        setValue,
        formState: { errors },
        setError
    } = useForm<AssignAssetsForm>();

    useEffect(() => {
        Promise.all([
            AssetServices.getAssets(null, false),
            AccessoriesServices.getAccessories(null, false)
        ])
            .then(([assets, accessories]) => {
                const formattedAssets = formatData(assets.data.docs);
                setAssetValues(formattedAssets);
                const formattedAccessories = formatAccessoryData(accessories.data.docs);
                setAccessoryValues(formattedAccessories);
            })
            .catch(({ response }) => showErrorAlert(setAlert, response));
    }, []);

    const assignEachAsset = async (assetToAssign: any) => {
        const promises = assetToAssign.assignAssets.map((asset: any) => {
            const body = {
                employeeEmail: assetToAssign.owner
            };

            setIsLoading(true);
            return AssetServices.assignAsset(asset.id, body)
                .then(() => {
                    setAlert(successMessage, LABELS.ALERTS.SEVERITIES.SUCCESS);
                    onSuccess(asset.id, assetToAssign.owner);
                })
                .catch(({ response }) => {
                    showErrorAlert(setAlert, response);
                    if (response) return true;
                })
                .finally(() => setIsLoading(false));
        });
        let error = (await Promise.all(promises)).map((obj) => obj);
        error = error.filter((obj) => obj !== undefined);
        return error.length > 0;
    };

    const assignEachAccessory = async (accessoryToAssign: any) => {
        const promises = await accessoryToAssign.assignAccessory.map((accessory: any) => {
            const body = {
                employeeEmail: accessoryToAssign.owner
            };

            setIsLoading(true);
            return AccessoriesServices.assignAccessory(accessory.id, body)
                .then(() => {
                    setAlert(successMessage, LABELS.ALERTS.SEVERITIES.SUCCESS);
                    onSuccess(accessory.id, accessoryToAssign.owner);
                })
                .catch(({ response }) => {
                    showErrorAlert(setAlert, response);
                    if (response) return true;
                })
                .finally(() => setIsLoading(false));
        });
        let error = (await Promise.all(promises)).map((obj) => obj);
        error = error.filter((obj) => obj !== undefined);
        return error.length > 0;
    };

    const handleClose = () => {
        toogleModal(false);
        reset();
    };

    const renderOptionLabel = (option: any) => `[${option.frag}] ${option.assetID}`;

    const onSubmit = async (data: any) => {
        data.owner = data.owner.email;

        if (data.assignAssets.length === 0 && data.assignAccessory.length === 0) {
            // Did not assign either an asset or an accessory
            setError('assignAssets', {
                message: VALIDATION_RULES.ASSIGN_ASSETS_RULES.ASSETS.required
            });
            setError('assignAccessory', {
                message: VALIDATION_RULES.ASSIGN_ASSETS_RULES.ASSETS.required
            });
            setAlert(failureMessage, LABELS.ALERTS.SEVERITIES.INFO);
        } else {
            let error = false;
            if (data.assignAssets.length > 0) {
                error = await assignEachAsset(data);
            }
            if (!error && data.assignAccessory.length > 0) {
                error = await assignEachAccessory(data);
            }

            if (!error) {
                toogleModal(false);
                reset();
            }
        }
    };

    const getForm = () => {
        const { loading, error, data } = useQuery(GET_CR_EMPLOYEES, {
            variables: { office: CR_OFFICE }
        });

        if (loading) return <Loading />;
        if (error) return <ServerError />;

        const people = data.peopleV2;

        const mappedPeople = people.map(userMapper);

        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={5}>
                        <FormInputMultiTag
                            name={AssignAssetsFieldnames.OWNER}
                            label={LABELS.ASSIGN_ASSETS.OWNER_LABEL}
                            control={control}
                            limitTags={LIMIT_TAG}
                            setValue={setValue}
                            options={mappedPeople}
                            renderOptionLabel={renderEmployeeLabel}
                            style={AUTO_COMPLETE_STYLE}
                            helper={errors[AssignAssetsFieldnames.OWNER]?.message}
                            rules={VALIDATION_RULES.ASSIGN_ASSETS_RULES.OWNER}
                        />
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <FormInputMultiTag
                            multiple
                            name={AssignAssetsFieldnames.ASSIGN_ASSET}
                            control={control}
                            limitTags={LIMIT_TAG}
                            label={LABELS.ASSIGN_ASSETS.ASSETS_LABEL}
                            setValue={setValue}
                            options={assetValues}
                            renderOptionLabel={renderOptionLabel}
                            style={AUTO_COMPLETE_STYLE}
                            helper={errors[AssignAssetsFieldnames.ASSIGN_ASSET]?.message}
                            rules={{}}
                        />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <FormInputText
                            name={AssignAssetsFieldnames.DESCRIPTION}
                            control={control}
                            label={LABELS.ASSIGN_ASSETS.DESCRIPTION_LABEL}
                            fullWidth
                            multiline
                            rows={5}
                            helper={errors[AssignAssetsFieldnames.DESCRIPTION]?.message}
                            rules={VALIDATION_RULES.ASSIGN_ASSETS_RULES.DESCRIPTION}
                        />
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <FormInputMultiTag
                            multiple
                            name={AssignAssetsFieldnames.ASSIGN_ACCESSORY}
                            control={control}
                            limitTags={LIMIT_TAG}
                            label={LABELS.ASSIGN_ASSETS.ACCESSORIES_LABEL}
                            setValue={setValue}
                            options={accessoryValues}
                            renderOptionLabel={renderOptionLabel}
                            style={AUTO_COMPLETE_STYLE}
                            helper={errors[AssignAssetsFieldnames.ASSIGN_ACCESSORY]?.message}
                            defaultValue={''}
                            freeSolo={false}
                            inputValue={''}
                            rules={{
                                required: undefined
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <CustomButton
                            variant="contained"
                            label={LABELS.ASSIGN_ASSETS.CONFIRM_BUTTON}
                            type="submit"
                            uiType="secondary"
                            startIcon={<CheckIcon />}
                        />
                    </Grid>
                </Grid>
            </form>
        );
    };

    return (
        <CustomModal
            open={modalIsOpen}
            handleClose={handleClose}
            size="md"
            title={LABELS.ASSIGN_ASSETS.TITLE}
        >
            {getForm()}
        </CustomModal>
    );
};

export default AssignAssets;
