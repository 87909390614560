import { LABELS } from '@Utils/labels';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { TextField, Autocomplete, Chip } from '@mui/material';
import { Controller, ControllerFieldState } from 'react-hook-form';

import { formatLabel } from './FormInput.utils';

import './FormInput.scss';
import { FormInputMultiTagProps } from './FormInput.type';

const FormInputMultiTag = ({
    name,
    control,
    defaultValue,
    limitTags,
    options,
    freeSolo,
    setValue,
    renderOptionLabel,
    label,
    helper,
    rules,
    style,
    multiple
}: FormInputMultiTagProps) => {
    const renderAutocomplete = ({ fieldState }: { fieldState: ControllerFieldState }) => (
        <Autocomplete
            multiple={multiple || false}
            limitTags={limitTags || 1}
            popupIcon={<KeyboardArrowDownIcon color="secondary" />}
            onChange={(e, values) => setValue(name, values)}
            options={options || []}
            getOptionLabel={renderOptionLabel}
            renderTags={(values, getTagProps) => (
                <div style={style}>
                    {values.map((option, index) => (
                        <Chip
                            variant="outlined"
                            label={renderOptionLabel(option)}
                            {...getTagProps({ index })}
                        />
                    ))}
                </div>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    className="ac2-form-input"
                    variant="outlined"
                    label={formatLabel(label, rules)}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? helper : null}
                    placeholder={label}
                />
            )}
        />
    );

    const renderFreeSolo = ({ fieldState }: { fieldState: ControllerFieldState }) => (
        <Autocomplete
            multiple={multiple}
            limitTags={limitTags}
            options={options || []}
            freeSolo
            onChange={(e, values) => setValue(name, values)}
            renderTags={(value, getTagProps) => (
                <div style={style}>
                    {value.map((option, index) => (
                        <Chip label={option} {...getTagProps({ index })} className="ac2-chip" />
                    ))}
                </div>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={LABELS.FILTERS}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? helper : null}
                />
            )}
        />
    );

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue || ''}
            render={freeSolo ? renderFreeSolo : renderAutocomplete}
            rules={rules}
        />
    );
};

export default FormInputMultiTag;
