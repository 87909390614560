import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { Controller, ControllerRenderProps, RegisterOptions } from 'react-hook-form';
import { FormInputRadioProps } from './FormInput.type';

const FormInputRadio = ({
    name,
    control,
    defaultValue,
    options,
    rules,
    disable
}: FormInputRadioProps) => {
    const renderRadioGroup = ({ field }: { field: ControllerRenderProps }) => (
        <RadioGroup row value={field.value} onBlur={field.onBlur} onChange={field.onChange}>
            {options.map((option) => (
                <FormControlLabel
                    key={option.VALUE}
                    value={option.VALUE}
                    control={<Radio />}
                    label={option.LABEL}
                    disabled={disable}
                />
            ))}
        </RadioGroup>
    );

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue || ''}
            render={renderRadioGroup}
            rules={rules as RegisterOptions}
        />
    );
};

export default FormInputRadio;
