import { API_ROUTES, HTTP_METHODS } from '@Utils/constants';
import { sendCall } from '@Utils/requestPrep';

const { USERS } = API_ROUTES;

class UserServices {
    getUserAssets = (email) =>
        sendCall(`${USERS.GET_ASSETS}/${email}`, {
            method: HTTP_METHODS.GET
        });
}

export default new UserServices();
