import CustomButton from '@Shared/Button/Button';
import FormInputRadio from '@Shared/Form/FormInputRadio';
import FormInputText from '@Shared/Form/FormInputText';
import TableOptions from '@Shared/Table/TableOptions';
import { FILE_NAME_FORMAT, REPORT_DATA_RADIO_BUTTON, VALIDATION_RULES } from '@Utils/constants';
import { LABELS } from '@Utils/labels';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Chip, Grid, Typography } from '@mui/material';
import { isArray, isBoolean, isObject, isString } from 'lodash';
import { useForm } from 'react-hook-form';

import { FIELD_NAMES, findLabel, sanitizedFilter } from '../Reports.utils';
import './ReportInputModal.scss';
import { DateProps, FilterInfoProps, NewReportProps } from './NewReport.type';

const { MODAL } = LABELS.REPORTS;
const { FILTERS } = MODAL;

const NewReport = ({
    reportInfo,
    filterInfo,
    setFilterInfo,
    nextHandler,
    submitHandler
}: NewReportProps) => {
    const { handleSubmit, control } = useForm({
        defaultValues: reportInfo
    });

    const deleteFilter = (deletedFilter: keyof FilterInfoProps) => () => {
        const newFilterInfo = { ...filterInfo };

        if (isArray(newFilterInfo[deletedFilter])) {
            newFilterInfo[deletedFilter] = [];
        }
        if (isBoolean(newFilterInfo[deletedFilter])) {
            newFilterInfo[deletedFilter] = false;
        }
        if (isString(newFilterInfo[deletedFilter])) {
            newFilterInfo[deletedFilter] = '';
        }
        if (isObject(newFilterInfo[deletedFilter]) && !isArray(newFilterInfo[deletedFilter])) {
            Object.keys(newFilterInfo[deletedFilter]).forEach((key) => {
                newFilterInfo[deletedFilter][key] = '';
            });
        }

        setFilterInfo(newFilterInfo);
    };

    const addFilter = () => {
        nextHandler(control._formValues);
    };

    const onSubmit = (data: any) => {
        data.filename = data.filename.replace(/\s+/g, FILE_NAME_FORMAT);
        data.timezone = new Date().getTimezoneOffset();
        const dataSubmited = data;
        submitHandler({ ...dataSubmited });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction="column" rowSpacing={2}>
                <Grid item xs={12}>
                    <div className="ac2-info__text">{MODAL.TITLES.REPORT_INFO}</div>
                    <FormInputText
                        name={FIELD_NAMES.FILENAME}
                        control={control}
                        label={MODAL.REPORT_NAME}
                        fullWidth
                        autoFocus
                        classname="report--filename"
                        rules={VALIDATION_RULES.REPORTS.NEW_REPORT.FILE_NAME}
                        helper={LABELS.REPORTS.FILENAME_HELPER}
                    />
                </Grid>
                <Grid item xs={12}>
                    <div className="ac2-info__text">{MODAL.TITLES.REPORT_DATA}</div>
                    <FormInputRadio
                        name={FIELD_NAMES.REPORT_TYPE}
                        control={control}
                        options={REPORT_DATA_RADIO_BUTTON}
                        disable={Object.keys(sanitizedFilter(filterInfo)).length !== 0}
                        rules={{}}
                    />
                </Grid>
                <Grid item xs={12}>
                    <div className="ac2-info__text">{MODAL.TITLES.FILTERS}</div>
                    <div className="ac2-filter-container">
                        {Object.keys(sanitizedFilter(filterInfo)).length !== 0 &&
                            Object.entries(sanitizedFilter(filterInfo)).map((filter: any) => (
                                <Grid
                                    key={Object.entries(filter)[0].toString() || ''}
                                    container
                                    justifyContent="space-between"
                                    alignItems="center"
                                    className="ac2-filter-item"
                                >
                                    <Grid container item xs={10}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" gutterBottom component="div">
                                                {findLabel(filter[0])}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} container alignItems="center">
                                            {(isArray(filter[1]) &&
                                                filter[1].map((value) => (
                                                    <Chip
                                                        key={value}
                                                        label={value}
                                                        variant="outlined"
                                                        className="ac2-chip"
                                                    />
                                                ))) ||
                                                (isString(filter[1]) && filter[1]) ||
                                                (isBoolean(filter[1]) && (
                                                    <CheckCircleOutlineIcon />
                                                )) ||
                                                (isObject(filter[1]) && !isArray(filter[1]) && (
                                                    <>
                                                        <Chip
                                                            key={`${filter[1]} ${FILTERS.START_DATE}`}
                                                            label={`${
                                                                FILTERS.START_DATE_COMPLEMENT
                                                            } ${
                                                                (filter[1] as DateProps).startDate
                                                            }`}
                                                            variant="outlined"
                                                            className="ac2-chip"
                                                        />
                                                        <Chip
                                                            key={`${filter[1]} ${FILTERS.END_DATE}`}
                                                            label={`${
                                                                FILTERS.END_DATE_COMPLEMENT
                                                            } ${(filter[1] as DateProps).endDate}`}
                                                            variant="outlined"
                                                            className="ac2-chip"
                                                        />
                                                    </>
                                                ))}
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        container
                                        justifyContent="flex-end"
                                        alignItems="center"
                                    >
                                        <TableOptions
                                            rowId={filter[0]}
                                            type="reports"
                                            disable
                                            disableHandler={deleteFilter(filter[0])}
                                        />
                                    </Grid>
                                </Grid>
                            ))}
                    </div>
                </Grid>
                <Grid item xs={12} className="ac2-addButton-container">
                    <CustomButton
                        startIcon={<AddIcon />}
                        label={MODAL.ADD_BUTTON}
                        variant="contained"
                        onClick={addFilter}
                        uiType="secondary"
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomButton
                        startIcon={<CheckIcon />}
                        label={MODAL.CONFIRM_BUTTON}
                        variant="contained"
                        type="submit"
                        uiType="secondary"
                    />
                </Grid>
            </Grid>
        </form>
    );
};

export default NewReport;
