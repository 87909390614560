import GET_CR_EMPLOYEES from '@Graph/Queries/user';
import {
    CR_OFFICE,
    PAGE_PROP,
    REPORT_TYPE,
    SEARCH_PROP,
    SIZES,
    SUPER_ADMIN_ROLES,
    UNASSIGN_VALUES,
    USER_ACCESSORY_COLUMNS,
    USER_ASSET_COLUMNS,
    USER_BREADCRUMBS,
    VALIDATION_RULES
} from '@Utils/constants';
import { useQuery } from '@apollo/client';
import { useLocation, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { KGUser } from '@Types/User.type';
import CustomBreadcrumbs from '@Shared/Breadcrumbs/Breadcrumbs';
import { AlertColor, Grid } from '@mui/material';
import { checkRoles } from '@Utils/auth';
import CustomButton from '@Shared/Button/Button';
import { Article, NoCell } from '@mui/icons-material';
import UserContext from '@Context/userContext';
import { LABELS } from '@Utils/labels';
import Loading from '@Shared/Loading/Loading';
import UserServices from '@Services/User.services';
import { SnackBarProps } from '@Shared/Snackbar/Snackbar.type';
import CustomSnackbar from '@Shared/Snackbar/Snackbar';
import CustomTable from '@Shared/Table/Table';
import EmptyIcon from '@Assets/Images/no-device-found.svg';
import CustomModal from '@Shared/Modal/Modal';
import CustomTextField from '@Shared/TextField/TextField';
import { useForm } from 'react-hook-form';
import AccessoriesServices from '@Services/Accessories.services';
import AssetServices from '@Services/Asset.services';
import { Accessory, Asset } from '@Types/Asset.types';
import ReportService from '@Services/Report.service';
import { showErrorAlert } from '@Utils/alerts';

const { PREVIOUS_CRUMB, PREVIOUS_CRUMB_ROUTE } = USER_BREADCRUMBS;
const {
    ALERTS,
    BUTTONS,
    EMPTY_MESSAGES,
    USERS,
    ACCESSORY_DETAILS,
    ASSET_DETAILS,
    UNASSIGN_OWNER,
    OFFBOARDING
} = LABELS;
const { ALL, ASSET, ACCESSORY } = UNASSIGN_VALUES;

const UserDetails = () => {
    const [foundUser, setFoundUser] = useState<KGUser>();
    const [userAssets, setUserAssets] = useState<Asset[]>([]);
    const [userAccessories, setUserAccessories] = useState<Accessory[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isMessageOpen, setIsMessageOpen] = useState<boolean>(false);
    const [snackbar, setSnackbar] = useState<SnackBarProps>();
    const [commentModal, setCommentModal] = useState<boolean>(false);
    const [confirmModal, setConfirmModal] = useState<boolean>(false);

    const { id } = useParams();
    const { state } = useLocation();
    const { user } = useContext(UserContext);
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        reset
    } = useForm();

    const { loading } = useQuery(GET_CR_EMPLOYEES, {
        variables: { office: CR_OFFICE },
        onCompleted: (data) => {
            if (id) {
                const found = data.peopleV2.filter((user: KGUser) => user.id.toString() === id);
                setFoundUser(found[0]);
            }
        }
    });

    const setAlert = (message: string, severity = ALERTS.SEVERITIES.SUCCESS) => {
        setSnackbar({ alertMessage: message, alertSeverity: severity as AlertColor });
        setIsMessageOpen(true);
    };
    const handleCloseMessage = () => {
        setIsMessageOpen(false);
    };

    const getUserAssets = () => {
        if (foundUser) {
            UserServices.getUserAssets(foundUser.email)
                .then(({ data }) => {
                    setUserAssets(data.assets);
                    setUserAccessories(data.accessories);
                    setIsLoading(false);
                })
                .catch(() => {
                    setAlert(USERS.FETCH_ERROR, ALERTS.SEVERITIES.ERROR);
                });
        }
    };

    const toggleCommentModal = () => {
        setCommentModal(!commentModal);
        reset();
    };

    const handleRemoveAsset = (data: any, type: string) => {
        setCommentModal(true);
        setValue('id', data ? data._id : -1);
        setValue('type', type);
        setValue('employeeEmail', foundUser?.email);
    };

    const getRoute = () => {
        let route = PREVIOUS_CRUMB_ROUTE;
        if (state?.searchParam) {
            route += `?${SEARCH_PROP}=${state.searchParam}`;
            if (state?.page) {
                route += `&`;
            }
        }
        if (state?.page) {
            if (!state.searchParam) {
                route += `?`;
            }
            route += `${PAGE_PROP}=${state.page}`;
        }
        return route;
    };

    const handleRemoveAllAssets = async (data: any) => {
        setIsLoading(true);
        if (userAssets.length > 0) {
            const promises = userAssets.map((asset: Asset) =>
                AssetServices.unassignAsset(asset._id, data)
            );
            await Promise.all(promises);
        }
        if (userAccessories.length > 0) {
            const promises = userAccessories.map((accessory: Accessory) =>
                AccessoriesServices.unassignAccessory(accessory._id, data)
            );
            await Promise.all(promises);
        }
    };

    const handleRemoveUser = (data: any) => {
        if (data.type === ACCESSORY) {
            AccessoriesServices.unassignAccessory(data.id, data).then(() => {
                getUserAssets();
                toggleCommentModal();
            });
        } else if (data.type === ASSET) {
            AssetServices.unassignAsset(data.id, data).then(() => {
                getUserAssets();
                toggleCommentModal();
            });
        } else if (data.type === ALL) {
            handleRemoveAllAssets(data).then(() => {
                getUserAssets();
                toggleCommentModal();
                setIsLoading(false);
            });
        }
    };

    const toggleOffboardingModal = () => {
        setConfirmModal(!confirmModal);
    };

    const handleOffboardingPdf = () => {
        const data = {
            user: foundUser,
            reportType: REPORT_TYPE.OFFBOARDING,
            timezone: new Date().getTimezoneOffset(),
            filter: {},
            filename: 'offboarding.pdf'
        };
        ReportService.createReport(data)
            .then((response) => {
                const report = response.data;
                if (report.generatedDocument.url !== '') {
                    window.open(report.generatedDocument.url, '_blank');
                }
                setAlert(OFFBOARDING.SUCCESS);
                toggleOffboardingModal();
            })
            .catch(({ response }) => {
                showErrorAlert(setAlert, response);
            });
    };

    useEffect(() => {
        if (foundUser) {
            getUserAssets();
        }
    }, [foundUser]);

    return (
        <>
            <CustomBreadcrumbs
                current={foundUser?.id.toString() || '00000'}
                previous={{
                    route: getRoute(),
                    name: PREVIOUS_CRUMB
                }}
            />
            {loading ? (
                <Loading />
            ) : (
                <Grid
                    item
                    xs={12}
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    className="ac2-asset-toolbar"
                >
                    <Grid item xs={12} md={4}>
                        <h1 className="ac2-title">{USERS.DETAILS}</h1>
                    </Grid>
                    {checkRoles(SUPER_ADMIN_ROLES, user) && (
                        <Grid
                            item
                            container
                            xs={12}
                            md={8}
                            justifyContent="flex-end"
                            alignItems="flex-end"
                        >
                            <CustomButton
                                label={BUTTONS.REMOVE_ASSETS}
                                uiType="secondary"
                                variant="contained"
                                onClick={() => handleRemoveAsset(null, ALL)}
                                startIcon={<NoCell />}
                                classes="ac2-asset-btn"
                            />

                            <CustomButton
                                label={BUTTONS.GENERATE_OFFBOARDING}
                                uiType="primary--outlined"
                                variant="outlined"
                                onClick={toggleOffboardingModal}
                                startIcon={<Article />}
                                classes="ac2-asset-btn"
                            />
                        </Grid>
                    )}
                    <Grid
                        container
                        justifyContent="flex-start"
                        className="ac2-asset-details-container"
                    >
                        <Grid item xs={12} md={4}>
                            <h2 className="ac2-subtitle">{foundUser?.fullName}</h2>
                            <h3 className="ac2-h3">Email: </h3>{' '}
                            <h4 className="ac2-info__text">{foundUser?.email}</h4>
                            <h3 className="ac2-h3">Company: </h3>{' '}
                            <h4 className="ac2-info__text">{foundUser?.company.Name}</h4>
                        </Grid>
                        <Grid
                            item
                            container
                            xs={12}
                            md={8}
                            justifyContent="flex-end"
                            alignItems="flex-end"
                        >
                            <img
                                alt={foundUser?.fullName}
                                src={foundUser?.photoUrl}
                                className="pfp"
                            />
                        </Grid>
                        {!isLoading ? (
                            <>
                                <h2 className="ac2-subtitle">Assets</h2>
                                <CustomTable
                                    columns={USER_ASSET_COLUMNS}
                                    rows={userAssets}
                                    emptyMessage={EMPTY_MESSAGES.ASSETS}
                                    emptyIcon={EmptyIcon}
                                    disable
                                    disableConfirmationMessage={ASSET_DETAILS.REMOVE_MESSAGE}
                                    disableHandler={(data) => handleRemoveAsset(data, ASSET)}
                                />
                                <h2 className="ac2-subtitle">Accessories</h2>
                                <CustomTable
                                    columns={USER_ACCESSORY_COLUMNS}
                                    rows={userAccessories}
                                    emptyMessage={EMPTY_MESSAGES.ACCESSORIES}
                                    emptyIcon={EmptyIcon}
                                    disable
                                    disableConfirmationMessage={ACCESSORY_DETAILS.REMOVE_MESSAGE}
                                    disableHandler={(data) => handleRemoveAsset(data, ACCESSORY)}
                                />
                            </>
                        ) : (
                            <Loading />
                        )}
                    </Grid>
                </Grid>
            )}
            {snackbar && (
                <CustomSnackbar
                    isOpen={isMessageOpen}
                    handleClose={handleCloseMessage}
                    alertMessage={snackbar.alertMessage}
                    alertSeverity={snackbar.alertSeverity}
                />
            )}
            {commentModal && (
                <CustomModal
                    title={UNASSIGN_OWNER.REASON}
                    size={SIZES.EXTRA_SMALL}
                    handleClose={toggleCommentModal}
                    open={commentModal}
                >
                    <form onSubmit={handleSubmit(handleRemoveUser)}>
                        <CustomTextField
                            name={UNASSIGN_OWNER.FIELDS.REASON.NAME}
                            control={control}
                            label={UNASSIGN_OWNER.FIELDS.REASON.LABEL}
                            type="text"
                            fullWidth
                            defaultValue=""
                            classname="field"
                            helper={errors[UNASSIGN_OWNER.FIELDS.REASON.NAME]?.message}
                            rules={VALIDATION_RULES.UNASSIGN_OWNER.REASON}
                        />
                        <Grid container justifyContent="flex-start" gap={2}>
                            <CustomButton
                                label={BUTTONS.CANCEL}
                                uiType="secondary"
                                variant="outlined"
                                onClick={toggleCommentModal}
                                classes="ac2-asset-btn"
                            />
                            <CustomButton
                                label={BUTTONS.CONFIRMATION}
                                type="submit"
                                uiType="primary"
                                variant="contained"
                                classes="ac2-asset-btn"
                            />
                        </Grid>
                    </form>
                </CustomModal>
            )}
            {confirmModal && (
                <CustomModal
                    title={OFFBOARDING.TITLE}
                    size={SIZES.EXTRA_SMALL}
                    handleClose={toggleOffboardingModal}
                    open={confirmModal}
                >
                    <div>
                        <h2 className="ac2-info__text">{OFFBOARDING.CONTENT}</h2>
                        <h3 className="ac2-info__subtext">Note: {OFFBOARDING.NOTE}</h3>
                        <Grid container justifyContent="flex-start" gap={2}>
                            <CustomButton
                                label={BUTTONS.CANCEL}
                                uiType="secondary"
                                variant="outlined"
                                onClick={toggleOffboardingModal}
                                classes="ac2-asset-btn"
                            />
                            <CustomButton
                                label={BUTTONS.CONFIRMATION}
                                type="submit"
                                uiType="primary"
                                variant="contained"
                                classes="ac2-asset-btn"
                                onClick={handleOffboardingPdf}
                            />
                        </Grid>
                    </div>
                </CustomModal>
            )}
        </>
    );
};
export default UserDetails;
