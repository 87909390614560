import { TextField } from '@mui/material';
import cx from 'classnames';
import { Controller, RegisterOptions } from 'react-hook-form';

import { formatLabel } from './FormInput.utils';

import './FormInput.scss';
import { FormInputTextProps } from './FormInput.type';

const FormInputText = ({
    name,
    control,
    defaultValue,
    label,
    classname,
    type,
    fullWidth,
    autoFocus,
    multiline,
    rows,
    startAdornment,
    endAdornment,
    shrink,
    mode,
    pattern,
    helper,
    rules,
    baseInput,
    onChangeFunc,
    maxLength,
    disabled
}: FormInputTextProps) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue || ''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                    label={formatLabel(label, rules)}
                    className={cx(`ac2-input__${classname}`, 'ac2-input', 'ac2-form-input')}
                    variant="outlined"
                    value={value}
                    type={type}
                    fullWidth={fullWidth}
                    autoFocus={autoFocus}
                    multiline={multiline}
                    rows={rows}
                    inputProps={{ ...baseInput, maxLength, pattern }}
                    InputProps={{
                        startAdornment,
                        endAdornment,
                        inputProps: type === 'date' ? { min: '0000-00-00', max: '9999-12-31' } : {}
                    }}
                    InputLabelProps={{
                        shrink,
                        inputMode: mode
                    }}
                    onChange={onChangeFunc ? (e) => onChange(onChangeFunc(e)) : onChange}
                    error={!!error}
                    helperText={helper || null}
                    disabled={disabled}
                />
            )}
            rules={rules as RegisterOptions}
        />
    );
};

export default FormInputText;
