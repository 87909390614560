import { useContext, useEffect, useState } from 'react';

import EmptyAssignments from '@Assets/Images/empty-assigments.svg';
import EmptyChanges from '@Assets/Images/none-changes.svg';
import {
    FIELD_NAMES,
    formatAssetTag,
    parseDateDetails,
    prepAssignments,
    prepChanges
} from '@Components/Assets/Assets.utils';
import UserContext from '@Context/userContext';
import AccessoriesServices from '@Services/Accessories.services';
import CustomBreadcrumbs from '@Shared/Breadcrumbs/Breadcrumbs';
import CustomButton from '@Shared/Button/Button';
import Loading from '@Shared/Loading/Loading';
import CustomModal from '@Shared/Modal/Modal';
import CustomSnackbar from '@Shared/Snackbar/Snackbar';
import CustomTable from '@Shared/Table/Table';
import './AccessoryDetails.scss';
import { CustomTextField } from '@Shared/TextField/TextField';
import { checkRoles } from '@Utils/auth';
import {
    ACCESSORY_DETAILS_COLUMNS,
    ADMIN_ROLES,
    ASSET_BREADCRUMBS,
    ASSET_CHANGES_HISTORY_COLUMNS,
    ASSET_OWNER_HISTORY_COLUMNS,
    COLUMNS_DEFAULT_VALUE,
    PAGE_PROP,
    SEARCH_PROP,
    SIZES,
    TAB_VALUES,
    VALIDATION_RULES
} from '@Utils/constants';
import { LABELS } from '@Utils/labels';
import { AlertColor, Grid } from '@mui/material';
import cx from 'classnames';
import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import { SnackBarProps } from '../Shared/Snackbar/Snackbar.type';

const { ALERTS, EMPTY_MESSAGES, ACCESSORY_DETAILS, ACCESSORIES, UNASSIGN_OWNER, BUTTONS } = LABELS;
const { PREVIOUS_CRUMB, PREVIOUS_CRUMB_ROUTE } = ASSET_BREADCRUMBS;

const AccessoryDetails = () => {
    const [accessoryDetails, setAccessoryDetails] = useState<any>();
    const { user } = useContext(UserContext);
    const [assignments, setAssignments] = useState<any[]>([]);
    const [changes, setChanges] = useState([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isMessageOpen, setIsMessageOpen] = useState<boolean>(false);
    const [snackbar, setSnackbar] = useState<SnackBarProps>();
    const { state } = useLocation();
    const [commentModal, setCommentModal] = useState<boolean>(false);

    const { id } = useParams();
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        reset
    } = useForm();

    const prepAccessory = (accessory: any) => {
        accessory.id = accessory._id;
        if (checkRoles(ADMIN_ROLES, user)) {
            accessory.userAmount = accessory.assignments.length;
        }
        delete accessory._id;
        return accessory;
    };

    const setAlert = (message: string, severity = ALERTS.SEVERITIES.SUCCESS) => {
        setSnackbar({ alertMessage: message, alertSeverity: severity as AlertColor });
        setIsMessageOpen(true);
    };

    const getAccessory = () => {
        AccessoriesServices.getAccessory(id)
            .then(({ data }) => {
                const accessory = prepAccessory(data[0]);
                setAccessoryDetails(accessory);
                setAssignments(prepAssignments(accessory.assignments));
                setChanges(prepChanges(accessory.assetChanges));
                setIsLoading(false);
            })
            .catch(() => {
                setAlert(ACCESSORY_DETAILS.FETCH_ERROR, ALERTS.SEVERITIES.ERROR);
            });
    };

    useEffect(() => {
        getAccessory();
    }, []);

    const handleCloseMessage = () => {
        setIsMessageOpen(false);
    };

    const getAssetDetailField = (field: any, asset: any) => {
        const value =
            (field.field === FIELD_NAMES.ASSET_TAG && formatAssetTag(asset)) || asset[field.field];
        const isBool = typeof value === 'boolean';
        if (field.field === FIELD_NAMES.PURCHASE_DATE) {
            return <span>{parseDateDetails(value) || COLUMNS_DEFAULT_VALUE}</span>;
        }
        if (!isBool) {
            return <span>{value || COLUMNS_DEFAULT_VALUE}</span>;
        }
        return (
            <div
                className={cx('ac2-field-icon', {
                    'ac2-field-icon--active': value
                })}
            />
        );
    };

    const toggleCommentModal = (closing?: any) => {
        setCommentModal(!commentModal);
        if (closing) {
            reset();
        }
    };

    const getUserToRemove = (selectedUser: any) => {
        setValue(UNASSIGN_OWNER.FIELDS.USER.EMAIL, selectedUser.employeeEmail);
        toggleCommentModal();
    };

    const handleRemoveUser = (data: any) => {
        AccessoriesServices.unassignAccessory(accessoryDetails.id, data).then(() => {
            getAccessory();
            toggleCommentModal(true);
        });
    };

    const createAssetDetailsColumn = (column: any, columnName: string, asset: any) => (
        <Grid
            container
            item
            md={12}
            lg={6}
            xl={4}
            justifyContent="start"
            alignItems="stretch"
            className="ac2-asset-details-column"
            key={columnName}
        >
            <h3 className="ac2-h3 ac2-column-title">{column.TITLE}</h3>

            {column.FIELDS.map((field: any) => (
                <Grid
                    container
                    className="ac2-field"
                    key={`${field.field}-${asset._id}`}
                    alignItems="stretch"
                >
                    <Grid item xs={12}>
                        <span className="ac2-field-header">{field.header}: </span>
                    </Grid>
                    <Grid item xs={12} className="ac2-field-content">
                        {getAssetDetailField(field, asset)}
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );

    const getRoute = () => {
        let route = PREVIOUS_CRUMB_ROUTE;
        if (state?.searchParam) {
            route += `?${SEARCH_PROP}=${state.searchParam}`;
            if (state?.page) {
                route += `&`;
            }
        }
        if (state?.page) {
            if (!state.searchParam) {
                route += `?`;
            }
            route += `${PAGE_PROP}=${state.page}`;
        }
        if (state && (state.page || state.searchParam)) {
            route += `&tab=${TAB_VALUES.ACCESSORIES}`;
        }
        return route;
    };

    return (
        <>
            <CustomBreadcrumbs
                current={accessoryDetails?.id}
                previous={{
                    route: getRoute(),
                    name: PREVIOUS_CRUMB
                }}
            />
            <Grid
                item
                xs={12}
                container
                alignItems="center"
                justifyContent="space-between"
                className="ac2-asset-toolbar"
            >
                <Grid item xs={12} md={4}>
                    <h1 className="ac2-title">{ACCESSORY_DETAILS.TITLE}</h1>
                </Grid>
            </Grid>

            <Grid container justifyContent="flex-start" className="ac2-asset-details-container">
                <Grid item xs={12}>
                    <h2 className="ac2-subtitle">{ACCESSORIES.SUBTITLE}</h2>
                </Grid>
                {!isLoading ? (
                    Object.keys(ACCESSORY_DETAILS_COLUMNS).map((column: any) =>
                        createAssetDetailsColumn(
                            ACCESSORY_DETAILS_COLUMNS[column],
                            column,
                            accessoryDetails
                        )
                    )
                ) : (
                    <Loading />
                )}
            </Grid>
            {checkRoles(ADMIN_ROLES, user) && (
                <>
                    <Grid
                        container
                        item
                        xs={12}
                        justifyContent="flex-start"
                        alignItems="center"
                        className="ac2-asset-details-container ac2-asset-details-table"
                    >
                        <Grid item xs={12} md={4}>
                            <h2 className="ac2-subtitle">{ACCESSORIES.OWNERS}</h2>
                        </Grid>
                        {!isLoading ? (
                            <CustomTable
                                columns={ASSET_OWNER_HISTORY_COLUMNS}
                                rows={assignments}
                                emptyMessage={EMPTY_MESSAGES.ACCESSORY_OWNER_HISTORY}
                                emptyIcon={EmptyAssignments}
                                disable
                                disableConfirmationMessage={ACCESSORY_DETAILS.REMOVE_MESSAGE}
                                disableHandler={getUserToRemove}
                            />
                        ) : (
                            <Loading />
                        )}
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        justifyContent="flex-start"
                        className="ac2-asset-details-container ac2-asset-details-table"
                    >
                        <Grid item xs={12}>
                            <h2 className="ac2-subtitle">{ACCESSORY_DETAILS.CHANGES_HISTORY}</h2>
                        </Grid>
                        {!isLoading ? (
                            <CustomTable
                                columns={ASSET_CHANGES_HISTORY_COLUMNS}
                                rows={changes}
                                emptyMessage={EMPTY_MESSAGES.ACCESSORY_CHANGES_HISTORY}
                                emptyIcon={EmptyChanges}
                            />
                        ) : (
                            <Loading />
                        )}
                    </Grid>
                </>
            )}

            {commentModal && (
                <CustomModal
                    title={UNASSIGN_OWNER.REASON}
                    size={SIZES.EXTRA_SMALL}
                    handleClose={() => toggleCommentModal(true)}
                    open={commentModal}
                >
                    <form onSubmit={handleSubmit(handleRemoveUser)}>
                        <CustomTextField
                            name={UNASSIGN_OWNER.FIELDS.REASON.NAME}
                            control={control}
                            label={UNASSIGN_OWNER.FIELDS.REASON.LABEL}
                            type="text"
                            fullWidth
                            defaultValue=""
                            classname="field"
                            helper={errors[UNASSIGN_OWNER.FIELDS.REASON.NAME]?.message}
                            rules={VALIDATION_RULES.UNASSIGN_OWNER.REASON}
                        />
                        <Grid container justifyContent="flex-start" gap={2}>
                            <CustomButton
                                label={BUTTONS.CANCEL}
                                uiType="secondary"
                                variant="outlined"
                                onClick={() => toggleCommentModal(true)}
                                classes="ac2-asset-btn"
                            />
                            <CustomButton
                                label={BUTTONS.CONFIRMATION}
                                type="submit"
                                uiType="primary"
                                variant="contained"
                                classes="ac2-asset-btn"
                            />
                        </Grid>
                    </form>
                </CustomModal>
            )}
            {snackbar && (
                <CustomSnackbar
                    isOpen={isMessageOpen}
                    handleClose={handleCloseMessage}
                    alertMessage={snackbar?.alertMessage}
                    alertSeverity={snackbar?.alertSeverity}
                />
            )}
        </>
    );
};

export default AccessoryDetails;
