import { API_ROUTES, HTTP_METHODS, PAGE_SIZE } from '@Utils/constants';
import { sendCall } from '@Utils/requestPrep';

const { USER_LOG } = API_ROUTES;

class AdminLogService {
    getAdminLogs = (page) =>
        sendCall(`${USER_LOG.GET}?page=${page}&limit=${PAGE_SIZE}`, {
            method: HTTP_METHODS.GET
        });
}

export default new AdminLogService();
