import { useEffect, useState } from 'react';

import breakpoints from '@Styling/breakpoints.module.scss';
import {
    PAGE_PROP,
    PAGINATION_OPTIONS,
    PARSE_INT_BASE_NUMBER,
    SEARCH_PROP
} from '@Utils/constants';
import { LABELS } from '@Utils/labels';
import { Grid, Pagination } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import MobileTable from './MobileTable';
import WebTable from './WebTable';
import usePagination from '../Hooks/usePagination';
import useWindowDimensions from '../Hooks/useWindowDimension';
import { CustomTableProps, PaginationSize } from './Table.type';

const entriesPerPage = 8;
const { TABLE } = LABELS;

const CustomTable = ({
    rows,
    columns,
    edit,
    info,
    disable,
    download,
    infoHandler,
    editHandler,
    disableHandler,
    disableConfirmationMessage,
    downloadHandler,
    page,
    updatePage,
    details,
    detailsLabel,
    emptyMessage,
    emptyIcon,
    detailsPath,
    idField,
    searchParam,
    itemCount
}: CustomTableProps) => {
    const { width } = useWindowDimensions();

    const { currentData, currentPage, jump, maxPage, prev, next } = usePagination(
        rows,
        entriesPerPage,
        itemCount
    );

    const [paginationSize, setPaginationSize] = useState(PAGINATION_OPTIONS.SIZE_MEDIUM);
    const [siblings, setSiblings] = useState(PAGINATION_OPTIONS.SIBLING_COUNT_MEDIUM);
    const [searchParams, setSearchParams] = useSearchParams();

    const handleChange = (event: any, value: number) => {
        if (updatePage) {
            updatePage(value);
        }
        jump(value);
    };

    useEffect(() => {
        if (page && page <= maxPage) {
            const parsedPage = page.toString();
            if (parseInt(searchParams.get(PAGE_PROP) || '', PARSE_INT_BASE_NUMBER) !== page)
                // eslint-disable-next-line no-unused-expressions
                searchParams.get(SEARCH_PROP)
                    ? setSearchParams({
                          search: searchParams.get(SEARCH_PROP) || '',
                          page: parsedPage
                      })
                    : setSearchParams({ page: parsedPage });
            jump(page);
        } else if (searchParams.get(SEARCH_PROP)) {
            setSearchParams({ search: searchParams.get(SEARCH_PROP) || '', page: '1' });
            jump(1);
        } else if (searchParams.get(PAGE_PROP)) {
            setSearchParams({ page: '1' });
            jump(1);
        }
    }, [page]);

    useEffect(() => {
        if (width >= parseInt(breakpoints.tablet)) {
            setPaginationSize(PAGINATION_OPTIONS.SIZE_MEDIUM);
            setSiblings(PAGINATION_OPTIONS.SIBLING_COUNT_MEDIUM);
        } else {
            setPaginationSize(PAGINATION_OPTIONS.SIZE_SMALL);
            setSiblings(PAGINATION_OPTIONS.SIBLING_COUNT_SMALL);
        }
    }, [width]);

    return rows.length <= 0 ? (
        <Grid container justifyContent="center" className="ac2-empty-table">
            <Grid item container xs={12} justifyContent="center" className="ac2-empty-icon">
                <img src={emptyIcon} alt={TABLE.ALT_EMPTY} />
            </Grid>
            <Grid item xs={12} container justifyContent="center">
                <h1 className="ac2-error-title">{emptyMessage}</h1>
            </Grid>
        </Grid>
    ) : (
        <>
            {width >= parseInt(breakpoints.tablet) ? (
                <WebTable
                    rows={currentData}
                    columns={columns}
                    edit={edit || false}
                    info={info || false}
                    disable={disable || false}
                    download={download || false}
                    editHandler={editHandler}
                    infoHandler={infoHandler}
                    disableHandler={disableHandler}
                    disableConfirmationMessage={disableConfirmationMessage}
                    downloadHandler={downloadHandler}
                    details={details}
                    detailsLabel={detailsLabel}
                    detailsPath={detailsPath}
                    page={page}
                    idField={idField}
                    searchParam={searchParam}
                />
            ) : (
                <MobileTable
                    rows={currentData}
                    columns={columns}
                    edit={edit || false}
                    info={info || false}
                    disable={disable || false}
                    download={download || false}
                    editHandler={editHandler}
                    infoHandler={infoHandler}
                    disableHandler={disableHandler}
                    disableConfirmationMessage={disableConfirmationMessage}
                    downloadHandler={downloadHandler}
                    details={details}
                    detailsLabel={detailsLabel}
                    detailsPath={detailsPath}
                    page={page}
                    idField={idField}
                    searchParam={searchParam}
                />
            )}

            {maxPage > 1 && (
                <Grid container justifyContent="center">
                    <Pagination
                        count={maxPage}
                        page={currentPage}
                        onChange={handleChange}
                        size={paginationSize as PaginationSize}
                        siblingCount={siblings}
                        className="ac2-pagination"
                    />
                </Grid>
            )}
        </>
    );
};

export default CustomTable;
