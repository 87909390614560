import { useEffect, useState } from 'react';

import CustomButton from '@Shared/Button/Button';
import FileUploadButton from '@Shared/FileUploadButton/FileUploadButton';
import FormInputCheckbox from '@Shared/Form/FormInputCheckbox';
import FormInputDropdown from '@Shared/Form/FormInputDropdown';
import FormInputText from '@Shared/Form/FormInputText';
import {
    AUTO_COMPLETE_STYLE,
    CONDITIONAL_ASSET_TYPE,
    FILENAME_REGEX,
    HELPERS,
    LIMIT_TAG,
    VALIDATION_RULES
} from '@Utils/constants';
import { LABELS } from '@Utils/labels';
import { applyRegex } from '@Utils/text';
import './AssetModals.scss';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Autocomplete, Chip, Grid, InputAdornment, TextField } from '@mui/material';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useController, useForm } from 'react-hook-form';

import { FIELD_NAMES, getAssetTypeByID, getDocumentName } from '../Assets.utils';
import { LegalInfoModalProps } from './AssetInputModal.type';

const {
    ASSET_NUMBER,
    SERIAL_NUMBER_ALT,
    SERIAL_NUMBER,
    RFID,
    RFID_ALT,
    TAX_STATUS,
    VENDOR,
    PURCHASE_DATE,
    WARRANTY,
    INVOICE_NUMBER
} = VALIDATION_RULES.LEGAL_INFO_RULES;

const { CREATE_ASSET } = LABELS;

const renderInput = (params: any) => (
    <TextField // eslint-disable-next-line react/jsx-props-no-spreading
        {...params}
        variant="outlined"
        fullWidth
        label={CREATE_ASSET.LEGAL_INFO.DOCUMENTS}
        placeholder={CREATE_ASSET.LEGAL_INFO.DOCUMENTS}
    />
);

const renderDisabledInput = (params: any) => (
    <TextField // eslint-disable-next-line react/jsx-props-no-spreading
        {...params}
        variant="outlined"
        disabled
        fullWidth
        label={CREATE_ASSET.LEGAL_INFO.DOCUMENTS}
        placeholder={CREATE_ASSET.LEGAL_INFO.DOCUMENTS}
    />
);

const LegalInfo = ({
    legalInfo,
    taxStatusInfo,
    conditionalInfo,
    assetTypeInfo,
    submitHandler,
    previousHandler,
    setAlert
}: LegalInfoModalProps) => {
    const { documents: storedDocuments } = legalInfo;

    const {
        handleSubmit,
        control,
        getValues,
        setValue,
        formState: { errors }
    } = useForm({
        defaultValues: legalInfo
    });

    const {
        field: { onChange }
    } = useController({ control, name: FIELD_NAMES.DOCUMENTS });

    const [documentNames, setDocumentNames] = useState(['']);
    const [deletedDocuments, setDeletedDocuments] = useState<any[]>([]);
    const [isDocumentsSelected, setIsDocumentsSelected] = useState<boolean>(false);
    const [isComputer, setIsComputer] = useState<boolean>(false);
    const [assetTypeFound, setAssetTypeFound] = useState<any>();

    useEffect(() => {
        const foundAssetType = getAssetTypeByID(conditionalInfo, assetTypeInfo);
        setAssetTypeFound(foundAssetType);
        if (foundAssetType && foundAssetType.description === CONDITIONAL_ASSET_TYPE) {
            setIsComputer(true);
        } else {
            setValue('rfid', '');
        }
    }, []);

    useEffect(() => {
        if (storedDocuments) {
            setDocumentNames(storedDocuments);
            setIsDocumentsSelected(true);
        }
    }, [legalInfo]);

    useEffect(() => {}, [getValues()]);

    const onFileSelect = () => {
        setIsDocumentsSelected(false);

        const newDocumentNames = getValues(FIELD_NAMES.DOCUMENTS)?.map(getDocumentName) || [];
        setDocumentNames(newDocumentNames);
        setIsDocumentsSelected(true);
    };

    const onFileUnselect = (fileName: any) => {
        // mark stored file for deletion
        if (storedDocuments && storedDocuments.includes(fileName)) {
            const deleted: any[] = deletedDocuments;
            deleted.push(fileName);
            setDeletedDocuments(deleted);
        }

        // remove file from state
        const newDocuments = getValues(FIELD_NAMES.DOCUMENTS)?.filter((document) => {
            const name = getDocumentName(document);
            return name !== fileName;
        });

        onChange(newDocuments);

        // update document names
        const newDocumentNames = newDocuments?.map(getDocumentName) || [];
        setDocumentNames(newDocumentNames);

        if (newDocumentNames.length === 0) {
            setIsDocumentsSelected(false);
        }
    };

    const renderTags = (value: any, getTagProps: any) => (
        <div style={AUTO_COMPLETE_STYLE}>
            {value.map((option: any, index: number) => {
                const name =
                    (typeof option === 'string' && option) || option?.filename || option.name;
                return (
                    <Chip
                        label={applyRegex(name, FILENAME_REGEX)}
                        onDelete={() => {
                            onFileUnselect(
                                (typeof option === 'string' && option) ||
                                    (option && option.filename) ||
                                    option.name
                            );
                        }}
                        {...getTagProps({ index })}
                    />
                );
            })}
        </div>
    );

    const backHandler = () => {
        previousHandler(control._formValues);
    };

    const allowOnlyNumbers = (e: any) => e.target.value.replace(/[^0-9]/g, '');

    const onSubmit = (data: any) => {
        submitHandler({ ...data, deletedDocuments });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="ac2-form__info ac2-form__legal">
            <Grid container columnSpacing={2} rowSpacing={{ xs: 2, sm: 4, md: 6 }}>
                <Grid item xs={12} sm={6} md={4}>
                    <FormInputText
                        name={FIELD_NAMES.SERIAL_NUMBER}
                        control={control}
                        label={CREATE_ASSET.LEGAL_INFO.SERIAL_NUMBER}
                        fullWidth
                        helper={errors[FIELD_NAMES.SERIAL_NUMBER]?.message}
                        rules={isComputer ? SERIAL_NUMBER_ALT : SERIAL_NUMBER}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FormInputText
                        name={FIELD_NAMES.ASSET_TAG}
                        control={control}
                        label={CREATE_ASSET.LEGAL_INFO.ASSET_NUMBER}
                        maxLength={ASSET_NUMBER.maxLength.value}
                        onChangeFunc={allowOnlyNumbers}
                        endAdornment={
                            <InputAdornment position="end">
                                {assetTypeFound?.assetCode}
                            </InputAdornment>
                        }
                        fullWidth
                        helper={errors[FIELD_NAMES.ASSET_TAG]?.message}
                        rules={ASSET_NUMBER}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FormInputText
                        name={FIELD_NAMES.RFID}
                        control={control}
                        maxLength={RFID.maxLength.value}
                        onChangeFunc={allowOnlyNumbers}
                        label={CREATE_ASSET.LEGAL_INFO.RFID}
                        fullWidth
                        disabled={!isComputer}
                        helper={
                            isComputer
                                ? errors[FIELD_NAMES.RFID]?.message
                                : HELPERS.LEGAL_INFO_RULES.RFID
                        }
                        rules={isComputer ? RFID_ALT : RFID}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FormInputDropdown
                        name={FIELD_NAMES.TAX_STATUS}
                        control={control}
                        label={CREATE_ASSET.LEGAL_INFO.TAX_STATUS}
                        fullWidth
                        options={taxStatusInfo}
                        idField="id"
                        valueField="description"
                        defaultValue={legalInfo.taxStatus}
                        helper={errors[FIELD_NAMES.TAX_STATUS]?.message}
                        rules={TAX_STATUS}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FormInputText
                        name={FIELD_NAMES.VENDOR}
                        control={control}
                        label={CREATE_ASSET.LEGAL_INFO.VENDOR}
                        fullWidth
                        helper={errors[FIELD_NAMES.VENDOR]?.message}
                        rules={VENDOR}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FormInputText
                        name={FIELD_NAMES.PURCHASE_DATE}
                        control={control}
                        label={CREATE_ASSET.LEGAL_INFO.DATE_OF_PURCHASE}
                        type="date"
                        fullWidth
                        shrink
                        helper={errors[FIELD_NAMES.PURCHASE_DATE]?.message}
                        rules={PURCHASE_DATE}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FormInputText
                        name={FIELD_NAMES.WARRANTY_DURATION}
                        control={control}
                        label={CREATE_ASSET.LEGAL_INFO.WARRANTY_DURATION}
                        onChangeFunc={allowOnlyNumbers}
                        fullWidth
                        helper={WARRANTY.months}
                        rules={{ valueAsNumber: true }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FormInputText
                        name={FIELD_NAMES.INVOICE_NUMBER}
                        control={control}
                        label={CREATE_ASSET.LEGAL_INFO.INVOICE_NUMBER}
                        fullWidth
                        rules={INVOICE_NUMBER}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <FormInputCheckbox
                        name={FIELD_NAMES.INSIDE_REGIME}
                        control={control}
                        classname="form"
                        label={CREATE_ASSET.LEGAL_INFO.INSIDE_REGIME}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    {isDocumentsSelected && (
                        <Autocomplete
                            id="document-viewer"
                            multiple
                            options={documentNames}
                            value={documentNames}
                            readOnly
                            popupIcon=""
                            fullWidth
                            limitTags={LIMIT_TAG}
                            renderInput={renderInput}
                            renderTags={renderTags}
                        />
                    )}
                    {!isDocumentsSelected && (
                        <Autocomplete
                            id="document-viewer"
                            multiple
                            options={[]}
                            readOnly
                            popupIcon=""
                            fullWidth
                            renderInput={renderDisabledInput}
                        />
                    )}
                </Grid>
            </Grid>
            <Grid container className={cx('ac2-grid__document')}>
                <Grid item xs={5} sm={5} md={5} className={cx('ac2-grid__upload')}>
                    <FileUploadButton
                        id="asset-file-uploader"
                        name={FIELD_NAMES.DOCUMENTS}
                        control={control}
                        variant="contained"
                        label={CREATE_ASSET.LEGAL_INFO.DOCUMENTS_BUTTON}
                        uiType="secondary"
                        classes="ac2-button__upload"
                        endIcon={<FileUploadIcon />}
                        onFileSelect={onFileSelect}
                        setAlert={setAlert}
                    />
                </Grid>
            </Grid>
            <CustomButton
                variant="contained"
                label={CREATE_ASSET.BACK_BUTTON}
                onClick={backHandler}
                uiType="secondary"
                classes="ac2-button__back"
                startIcon={<ArrowBackIcon />}
            />
            <CustomButton
                variant="contained"
                label={CREATE_ASSET.CONFIRM_BUTTON}
                type="submit"
                uiType="primary"
                classes="ac2-button__confirm"
                endIcon={<CheckIcon />}
            />
        </form>
    );
};

export default LegalInfo;
