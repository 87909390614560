import CustomButton from '@Shared/Button/Button';
import CustomTextField from '@Shared/TextField/TextField';
import { VALIDATION_RULES } from '@Utils/constants';
import { LABELS } from '@Utils/labels';
import CheckIcon from '@mui/icons-material/Check';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { SettingsFormProps } from '../../Settings.type';

const { EMAIL, BUTTONS } = LABELS;

const { USER_EMAIL, DESCRIPTION } = EMAIL.WELCOME_FORM;
const { EMAIL_RULES } = VALIDATION_RULES;

const WelcomeEmailForm = ({ handlerSubmit }: SettingsFormProps) => {
    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm();

    return (
        <form onSubmit={handleSubmit(handlerSubmit)}>
            <Grid container>
                <p>{DESCRIPTION}</p>
                <CustomTextField
                    name={USER_EMAIL.NAME}
                    control={control}
                    label={USER_EMAIL.LABEL}
                    helper={errors[USER_EMAIL.NAME]?.message}
                    fullWidth
                    classname="field"
                    rules={EMAIL_RULES.EMAIL}
                />

                <CustomButton
                    label={BUTTONS.CONFIRMATION}
                    type="submit"
                    variant="contained"
                    uiType="secondary"
                    classes="ac2-button-field"
                    startIcon={<CheckIcon />}
                />
            </Grid>
        </form>
    );
};

export default WelcomeEmailForm;
