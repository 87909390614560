import './SettingsCard.scss';
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SettingsCardProps } from './Settings.type';

const SettingsCard = ({ name, path, image, alt }: SettingsCardProps) => {
    const navigate = useNavigate();

    const clickHandler = () => {
        navigate(path);
    };

    return (
        <Card className="ac2-card">
            <CardActionArea onClick={clickHandler}>
                <CardMedia
                    component="img"
                    src={image}
                    alt={`${alt}-image`}
                    className="ac2-card-image"
                />
                <CardContent className="ac2-card-text">
                    <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold' }}>
                        {name}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default SettingsCard;
