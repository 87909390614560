import LogoXS from '@Assets/Images/konrad-logo-xs.svg?react';
import { SIZES } from '@Utils/constants';
import { Grid } from '@mui/material';
import cx from 'classnames';
import './Loading.scss';
import { LoadingProps } from './Loading.type';

const Loading = ({ size = SIZES.MEDIUM }: LoadingProps) => {
    return (
        <Grid
            container
            justifyContent="center"
            className={cx(`ac2-loading-container__${size}`, 'ac2-loader', 'ac2-loading-container')}
        >
            <LogoXS />
        </Grid>
    );
};

export default Loading;
