import './Footer.scss';
import { LABELS } from '@Utils/labels';

const Footer = () => (
    <footer className="ac2-footer__container">
        <hr className="ac2-footer__division" />
        <div className="ac2-footer__bottom">
            <p className="ac2-footer__copyright">{LABELS.FOOTER}</p>
        </div>
    </footer>
);

export default Footer;
