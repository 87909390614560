import { API_ROUTES, HTTP_METHODS, PAGE_SIZE } from '@Utils/constants';
import { sendCall } from '@Utils/requestPrep';

const { TAX_STATUS } = API_ROUTES;

class TaxStatusService {
    createTaxStatus = (params) =>
        sendCall(TAX_STATUS.POST, {
            data: params,
            method: HTTP_METHODS.POST
        });

    getTaxStatus = (page, pagination) =>
        sendCall(`${TAX_STATUS.GET}?page=${page}&limit=${PAGE_SIZE}&pagination=${pagination}`, {
            method: HTTP_METHODS.GET
        });

    updateTaxStatus = (id, params) =>
        sendCall(`${TAX_STATUS.PUT}/${id}`, { data: params, method: HTTP_METHODS.PUT });

    disableTaxStatus = (id) =>
        sendCall(`${TAX_STATUS.DISABLE}/${id}`, { method: HTTP_METHODS.POST });

    searchTaxStatus = (params, page) =>
        sendCall(`${TAX_STATUS.SEARCH_POST}?page=${page}&limit=${PAGE_SIZE}`, {
            data: params,
            method: HTTP_METHODS.POST
        });
}

export default new TaxStatusService();
