import React from 'react';

import './ServerError.scss';
import NotFoundSVG from '@Assets/Images/ServerError.svg';
import { LABELS } from '@Utils/labels';
import { Grid } from '@mui/material';

const { SERVER_ERROR } = LABELS;

const ServerError = () => (
    <Grid
        container
        justifyContent="center"
        alignItems="center"
        className="ac2-server-error-container"
    >
        <Grid item xs={12} container justifyContent="center">
            <img
                src={NotFoundSVG}
                alt={SERVER_ERROR.ALT_MESSAGE}
                className="ac2-server-error-img"
            />
        </Grid>

        <Grid item xs={12} container justifyContent="center">
            <h1 className="ac2-server-error-title">{SERVER_ERROR.TITLE}</h1>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
            <h2 className="ac2-server-error-subtitle">{SERVER_ERROR.SUBTITLE}</h2>
        </Grid>
    </Grid>
);

export default ServerError;
