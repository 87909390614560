import { useCallback, useEffect, useState } from 'react';

const usePagination = (data, itemsPerPage, itemCount) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPage, setMaxPage] = useState(Math.ceil(itemCount || data.length / itemsPerPage));

    const getCurrentData = useCallback(() => {
        const begin = (currentPage - 1) * itemsPerPage;
        const end = begin + itemsPerPage;
        return data.slice(begin, end);
    }, [currentPage, data]);

    const [currentData, setCurrentData] = useState(itemCount ? data : getCurrentData());

    const next = useCallback(() => {
        setCurrentPage(Math.min(currentPage + 1, maxPage));
    }, [currentPage, maxPage]);

    const prev = useCallback(() => {
        setCurrentPage(Math.max(currentPage - 1, 1));
    }, [currentPage]);

    const jump = useCallback(
        (page) => {
            const pageNumber = Math.max(1, page);
            setCurrentPage(Math.min(pageNumber, maxPage));
        },
        [currentPage, maxPage]
    );

    useEffect(() => {
        setMaxPage(Math.ceil(itemCount || data.length / itemsPerPage));
        setCurrentData(itemCount ? data : getCurrentData());
    }, [itemCount, data, data.length, itemsPerPage, currentPage]);

    return { next, prev, jump, currentData, currentPage, maxPage };
};

export default usePagination;
