import AssetInOffice from '@Assets/Images/assets-check-in.svg?react';
import AssetOutOffice from '@Assets/Images/assets-check-out.svg?react';
import colors from '@Assets/Styling/variables.module.scss';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import { LABELS } from './labels';

export const COLORS = Object.freeze(colors);

const { VITE_APP_API_URL, VITE_APP_DEPLOY_ENV } = import.meta.env;

const {
    ASSET_STATUS,
    ASSET_TYPE,
    TAX_STATUS,
    LOCATION,
    CREATE_ASSET,
    ASSET_CHANGE_HISTORY,
    ASSET_OWNER_HISTORY,
    SCANNER_LOG,
    REPORTS,
    EMAIL,
    ADMIN_LOG,
    NOTIFICATIONS,
    ACCESSORIES,
    ACCESSORY_DETAILS
} = LABELS;

export const API_URL = `${VITE_APP_API_URL}`;

export const FIRST_PAGE_NUMBER = 1;

export const PAGE_PROP = 'page';

export const PATHS = {
    USERS: '/users',
    ASSETS: '/assets',
    ACCESSORIES: '/accessories',
    ASSET_DETAILS: '/assets/:id',
    ACCESSORY_DETAILS: '/accessories/:id',
    ASSET_STATUS: '/settings/asset-status',
    ASSET_TYPES: '/settings/asset-type',
    EMAIL: '/settings/email',
    ACCESSORY_SETTINGS: '/settings/accessories',
    HOME: '/',
    LOCATIONS: '/settings/locations',
    NOTFOUND: '*',
    REPORTS: '/reports',
    REPORT_DETAILS: '/reports/:id',
    SETTINGS: '/settings',
    TAX_STATUS: '/settings/tax-status',
    SCANNER_LOG: '/reports/scanner-log',
    UNAUTHORIZED: '/unauthorized',
    ADMIN_LOG: '/users/admin-log',
    NOTIFICATIONS: '/notifications',
    USER_DETAILS: '/users/:id'
};

export const API_ROUTES = {
    ASSET: {
        GET: `${API_URL}/assets`,
        POST: `${API_URL}/assets/create`,
        SEARCH_POST: `${API_URL}/assets/search`,
        EDIT: `${API_URL}/assets/edit`,
        PUT: `${API_URL}/assets/assign`,
        UNASSIGN: `${API_URL}/assets/unassign`,
        UPLOAD: `${API_URL}/assets/upload`
    },
    ALERT: {
        UPDATE: `${API_URL}/alerts/update-all`,
        GET: `${API_URL}/alerts`,
        HIDE: `${API_URL}/alerts/hide`,
        VIEW: `${API_URL}/alerts/view`
    },
    ASSET_TYPE: {
        GET: `${API_URL}/asset-types`,
        POST: `${API_URL}/asset-types/create`,
        DISABLE: `${API_URL}/asset-types/disable`,
        PUT: `${API_URL}/asset-types/update`,
        SEARCH_POST: `${API_URL}/asset-types/search`
    },
    ASSET_STATUS: {
        GET: `${API_URL}/asset-status`,
        POST: `${API_URL}/asset-status/create`,
        DISABLE: `${API_URL}/asset-status/disable`,
        PUT: `${API_URL}/asset-status/update`,
        SEARCH_POST: `${API_URL}/asset-status/search`
    },
    AUTH: {
        VALIDATE_USER: `${API_URL}/auth`,
        USER_BY_TOKEN: `${API_URL}/users/info`
    },
    LOCATION: {
        GET: `${API_URL}/locations`,
        POST: `${API_URL}/locations/create`,
        DISABLE: `${API_URL}/locations/disable`,
        PUT: `${API_URL}/locations/update`,
        SEARCH_POST: `${API_URL}/locations/search`
    },
    EMAIL: {
        GET: `${API_URL}/email`,
        PUT: `${API_URL}/email/edit`,
        WELCOME: `${API_URL}/email/welcome`,
        SEARCH_POST: `${API_URL}/email/search`
    },
    USERS: {
        GET_ADMIN: `${API_URL}/users`,
        CREATE_ADMIN: `${API_URL}/users/create`,
        GET_ADMIN_TYPES: `${API_URL}/users/types`,
        DISABLE_ADMIN: `${API_URL}/users/disable`,
        SEARCH_POST: `${API_URL}/users/search`,
        USERS_LIST: 'https://admin.googleapis.com/$discovery/rest?version=directory_v1',
        EDIT_ADMIN: `${API_URL}/users/edit`,
        GET_ASSETS: `${API_URL}/users/assets`
    },
    DASHBOARD: {
        WEEKLY_COMPARISONS: `${API_URL}/asset-log/weekly-comparisons`,
        READER_SERVICE_STATUS: `${API_URL}/reader-service/status`,
        ASSETS_BY_ASSET_STATUS: `${API_URL}/assets/assets-by-status`,
        READER_SERVICE_STATUS_COUNT: `${API_URL}/reader-service-log/weekly-count`
    },
    TAX_STATUS: {
        GET: `${API_URL}/tax-status`,
        POST: `${API_URL}/tax-status/create`,
        DISABLE: `${API_URL}/tax-status/disable`,
        PUT: `${API_URL}/tax-status/update`,
        SEARCH_POST: `${API_URL}/tax-status/search`
    },
    REPORT: {
        GET: `${API_URL}/reports`,
        POST: `${API_URL}/reports/create`,
        SEARCH_POST: `${API_URL}/reports/search`,
        EXCEL: `${API_URL}/reports/excel`,
        PAGE_DATA: `${API_URL}/reports/data`
    },
    ASSET_LOG: {
        GET: `${API_URL}/asset-log`,
        SEARCH_POST: `${API_URL}/asset-log/search`
    },
    USER_LOG: {
        GET: `${API_URL}/user-log`
    },
    ACCESSORIES: {
        GET: `${API_URL}/accessories`,
        POST: `${API_URL}/accessories/create`,
        DISABLE: `${API_URL}/accessories/disable`,
        PUT: `${API_URL}/accessories/update`,
        SEARCH_POST: `${API_URL}/accessories/search`,
        ASSIGN: `${API_URL}/accessories/assign`,
        UNASSIGN: `${API_URL}/accessories/unassign`
    }
};

export const LOCAL_STORAGE = {
    LOGIN_DATA: 'loginData'
};

export const SESSION_STORAGE = {
    ALERTS: 'alerts'
};

export const ASSETS_STATUS_COLUMNS = [
    {
        field: 'description',
        header: ASSET_STATUS.FORM.DESCRIPTION.LABEL
    }
];

export const ASSET_OWNER_HISTORY_COLUMNS = [
    {
        field: 'employeeEmail',
        header: ASSET_OWNER_HISTORY.OWNER
    },
    {
        field: 'assignmentDate',
        header: ASSET_OWNER_HISTORY.DATE
    }
];

export const ASSET_CHANGES_HISTORY_COLUMNS = [
    {
        field: 'changeType',
        header: ASSET_CHANGE_HISTORY.CHANGE_TYPE
    },

    {
        field: 'madeBy',
        header: ASSET_CHANGE_HISTORY.MADE_BY
    },
    {
        field: 'createdAt',
        header: ASSET_CHANGE_HISTORY.CREATED_AT
    },
    {
        field: 'details',
        header: ASSET_CHANGE_HISTORY.DETAILS
    }
];

export const ACCESSORY_DETAILS_COLUMNS: any = {
    DEVICE_INFO: {
        TITLE: ACCESSORIES.COLUMNS.INFO,
        FIELDS: [
            {
                field: 'name',
                header: ACCESSORY_DETAILS.FIELDS.NAME
            },
            {
                field: 'userAmount',
                header: ACCESSORY_DETAILS.FIELDS.USER_AMOUNT
            }
        ]
    }
};

export const ASSET_DETAILS_COLUMNS: any = {
    DEVICE_INFO: {
        TITLE: CREATE_ASSET.DEVICE_INFO.TITLE,
        FIELDS: [
            {
                field: 'assetModel',
                header: CREATE_ASSET.DEVICE_INFO.DESCRIPTION.MODEL
            },
            {
                field: 'assetMake',
                header: CREATE_ASSET.DEVICE_INFO.DESCRIPTION.MAKE
            },
            {
                field: 'assetYear',
                header: CREATE_ASSET.DEVICE_INFO.DESCRIPTION.YEAR
            },
            {
                field: 'assetType',
                header: CREATE_ASSET.DEVICE_INFO.ASSET_TYPE
            },
            {
                field: 'assetStatus',
                header: CREATE_ASSET.DEVICE_INFO.ASSET_STATUS
            },
            {
                field: 'descriptionDetails',
                header: CREATE_ASSET.DEVICE_INFO.DESCRIPTION.DETAILS
            },
            {
                field: 'sharedDevice',
                header: CREATE_ASSET.DEVICE_INFO.SHARED_DEVICE
            }
        ]
    },
    LEGAL_INFO: {
        TITLE: CREATE_ASSET.LEGAL_INFO.TITLE,
        FIELDS: [
            {
                field: 'serialNumber',
                header: CREATE_ASSET.LEGAL_INFO.SERIAL_NUMBER
            },
            {
                field: 'assetTag',
                header: CREATE_ASSET.LEGAL_INFO.ASSET_NUMBER
            },
            {
                field: 'rfid',
                header: CREATE_ASSET.LEGAL_INFO.RFID
            },
            {
                field: 'purchaseDate',
                header: CREATE_ASSET.LEGAL_INFO.DATE_OF_PURCHASE
            },
            {
                field: 'vendor',
                header: CREATE_ASSET.LEGAL_INFO.VENDOR
            },
            {
                field: 'taxStatus',
                header: CREATE_ASSET.LEGAL_INFO.TAX_STATUS
            },
            {
                field: 'invoiceNumber',
                header: CREATE_ASSET.LEGAL_INFO.INVOICE_NUMBER
            },
            {
                field: 'insideRegime',
                header: CREATE_ASSET.LEGAL_INFO.INSIDE_REGIME
            },
            {
                field: 'warrantyDuration',
                header: CREATE_ASSET.LEGAL_INFO.WARRANTY_DURATION
            }
        ]
    },
    LOCATION_INFO: {
        TITLE: CREATE_ASSET.LOCATION_INFO.TITLE,
        FIELDS: [
            {
                field: 'officeName',
                header: CREATE_ASSET.LOCATION_INFO.ASSET_LOCATION.OFFICE
            },
            {
                field: 'officeDescription',
                header: CREATE_ASSET.LOCATION_INFO.ASSET_LOCATION.LOCATION_DETAILS
            },
            {
                field: 'officeLocation',
                header: CREATE_ASSET.LOCATION_INFO.ASSET_LOCATION.LOCATION
            },
            {
                field: 'officeCountry',
                header: CREATE_ASSET.LOCATION_INFO.ASSET_LOCATION.COUNTRY
            },
            {
                field: 'inOffice',
                header: CREATE_ASSET.LOCATION_INFO.IN_OFFICE
            }
        ]
    }
};

export const ASSET_TYPE_COLUMNS = [
    {
        field: 'assetCode',
        header: ASSET_TYPE.FORM.CODE_FIELD.LABEL
    },
    {
        field: 'description',
        header: ASSET_TYPE.FORM.DESCRIPTION_FIELD.LABEL
    }
];

export const CONTAINER_SIZE = {
    FULL: 'xxl'
};

export const GRID_BREAKPOINTS = {
    XS: 12,
    SM: 6,
    MD: 6,
    LG: 4
};

export const COLUMNS_DEFAULT_VALUE = '-';

export const ADMIN_COLUMNS = [
    {
        field: 'name',
        header: LABELS.USERS.ROWS.NAME
    },
    {
        field: 'email',
        header: LABELS.USERS.ROWS.EMAIL
    },
    {
        field: 'userType',
        header: LABELS.USERS.ROWS.ROLE
    }
];

export const USER_COLUMNS = [
    {
        field: 'fullName',
        header: LABELS.USERS.ROWS.NAME
    },
    {
        field: 'email',
        header: LABELS.USERS.ROWS.EMAIL
    }
];

export const ASSET_COLUMNS = [
    {
        field: 'assetTag',
        header: LABELS.ASSETS.COLUMNS.ASSET_TAG,
        default: COLUMNS_DEFAULT_VALUE
    },
    {
        field: 'rfid',
        header: LABELS.ASSETS.COLUMNS.RFID,
        default: COLUMNS_DEFAULT_VALUE
    },
    {
        field: 'owner',
        header: LABELS.ASSETS.COLUMNS.OWNER,
        default: COLUMNS_DEFAULT_VALUE
    },
    {
        field: 'inOffice',
        header: LABELS.ASSETS.COLUMNS.IN_OFFICE,
        default: COLUMNS_DEFAULT_VALUE,
        icon: {
            true: <CheckCircleOutlineIcon />,
            false: <RemoveCircleIcon />
        }
    }
];

export const TAX_STATUS_COLUMNS = [
    {
        field: 'description',
        header: TAX_STATUS.FORM.DESCRIPTION.LABEL
    }
];

export const ACCESSORY_COLUMNS = [
    {
        field: 'name',
        header: ACCESSORIES.FORM.DESCRIPTION.LABEL
    }
];

export const LOCATION_COLUMNS = [
    {
        field: 'name',
        header: LOCATION.FORM.NAME_FIELD.LABEL
    },
    {
        field: 'location',
        header: LOCATION.FORM.LOCATION_FIELD.LABEL
    },
    {
        field: 'country',
        header: LOCATION.FORM.COUNTRY_FIELD.LABEL
    },
    {
        field: 'description',
        header: LOCATION.FORM.DETAILS_FIELD.LABEL
    }
];

const NAME_REGEX = /^[A-Za-zÀ-ÖØ-öø-ÿ]([A-Za-zÀ-ÖØ-öø-ÿ]|[ ,.-])*$/;

export const KG_EMAIL_REGEX = /^[a-zA-Z1-9]+@konradgroup\.com$/;

export const VALIDATION_RULES = {
    TAX_STATUS_RULES: {
        required: 'Description is required',
        maxLength: { value: 50, message: 'Description cannot be longer than 50 characters.' }
    },
    ACCESSORY_RULES: {
        required: 'Name is required',
        maxLength: { value: 50, message: 'Name cannot be longer than 50 characters.' }
    },
    ASSET_STATUS_RULES: {
        required: 'Description is required',
        maxLength: { value: 50, message: 'Description cannot be longer than 50 characters.' }
    },
    ASSET_TYPE_RULES: {
        CODE: {
            required: 'Code is required',
            maxLength: { value: 25, message: 'Code cannot be longer than 25 characters.' }
        },
        DESCRIPTION: {
            required: 'Description is required',
            maxLength: { value: 50, message: 'Description cannot be longer than 50 characters.' }
        }
    },
    LOCATION_RULES: {
        NAME: {
            required: 'Name is required.',
            maxLength: { value: 25, message: 'Name cannot be longer than 25 characters.' }
        },
        LOCATION: {
            required: 'Location is required.',
            maxLength: { value: 50, message: 'Location cannot be longer than 50 characters.' }
        },
        COUNTRY: {
            required: 'Country is required.',
            maxLength: { value: 25, message: 'Country cannot be longer than 25 characters.' }
        },
        DETAILS: {
            maxLength: { value: 50, message: 'Details cannot be longer than 50 characters.' }
        }
    },
    ADMIN_RULES: {
        NAME: {
            required: 'Name is required',
            pattern: { value: NAME_REGEX, message: 'Must be a valid name' }
        },
        EMAIL: {
            required: 'Email is required',
            pattern: { value: KG_EMAIL_REGEX, message: 'Must be a valid @konradgroup email' }
        }
    },
    DEVICE_INFO_RULES: {
        MAKE: {
            required: 'Make is required',
            maxLength: { value: 50, message: 'Make cannot be longer than 50 characters.' }
        },
        MODEL: {
            maxLength: { value: 50, message: 'Model cannot be longer than 50 characters.' }
        },
        YEAR: {
            min: { value: 1900, message: 'The min value is 1900.' },
            max: {
                value: new Date().getFullYear() + 1,
                message: `The max value is ${new Date().getFullYear() + 1}`
            },
            pattern: {
                value: /^(19|2[0-9])\d{2}$/,
                message: `Must be a valid Year (1900 - ${new Date().getFullYear() + 1})`
            }
        },
        YEAR_INPUT: { inputMode: 'numeric', maxLength: 4 },
        ASSET_TYPE: {
            required: 'Asset Type is required',
            maxLength: { value: 24, message: 'Status cannot be longer than 24 characters.' }
        },
        ASSET_STATUS: {
            required: 'Status is required',
            maxLength: { value: 24, message: 'Status cannot be longer than 24 characters.' }
        },
        DESCRIPTION: {
            maxLength: { value: 255, message: 'Description cannot be longer than 255 characters.' }
        },
        OS_VERSION: {
            required: true
        }
    },
    EMAIL_RULES: {
        SUBJECT: {
            required: 'Subject is required.'
        },
        BODY: {
            required: 'Body is required.'
        },
        EMAIL: {
            required: 'Email is required.',
            pattern: { value: KG_EMAIL_REGEX, message: 'Must be a valid @konradgroup email' }
        }
    },
    LOCATION_INFO_RULES: {
        OFFICE: {
            required: 'Office is required',
            maxLength: { value: 24, message: 'Office cannot be longer than 50 characters.' }
        },
        DETAILS: {
            maxLength: { value: 50, message: 'Details cannot be longer than 50 characters.' }
        }
    },
    LEGAL_INFO_RULES: {
        SERIAL_NUMBER: {
            maxLength: { value: 50, message: 'Serial Number cannot be longer than 50 characters.' }
        },
        SERIAL_NUMBER_ALT: {
            required: 'Serial Number is required',
            maxLength: { value: 50, message: 'Serial Number  cannot be longer than 50 characters.' }
        },
        ASSET_NUMBER: {
            maxLength: { value: 7, message: 'Asset Number cannot be longer than 7 characters.' }
        },
        RFID: {
            maxLength: { value: 24, message: 'RFID cannot be longer than 24 characters.' }
        },
        RFID_ALT: {
            required: 'RFID is required',
            maxLength: { value: 24, message: 'RFID cannot be longer than 24 characters.' }
        },
        TAX_STATUS: {
            required: 'Tax Status is required'
        },
        VENDOR: {
            maxLength: { value: 50, message: 'Vendor cannot be longer than 50 characters.' }
        },
        PURCHASE_DATE: {
            valueAsDate: true
        },
        WARRANTY: {
            valueAsNumber: 'This value must be a number.',
            months: 'Type the warranty in months.'
        },
        INVOICE_NUMBER: {
            maxLength: 50
        }
    },
    ASSIGN_ASSETS_RULES: {
        OWNER: {
            required: 'Valid Owner is required',
            pattern: { value: KG_EMAIL_REGEX, message: 'Owner field must be a KG email' }
        },
        DESCRIPTION: {
            required: 'Description is required'
        },
        ASSETS: {
            required: 'Must select either an asset or an accessory to assign'
        }
    },
    SEARCH_BAR_RULES: {
        max: new Date().getFullYear()
    },
    REPORTS: {
        NEW_REPORT: {
            FILE_NAME: {
                required: true
            },
            REPORT_DATA: {
                required: true
            }
        }
    },
    UNASSIGN_OWNER: {
        REASON: {
            required: 'Please provide a reason for this removal',
            maxLength: { value: 150, message: 'Reason cannot be longer than 150 characters.' }
        }
    }
};

export const POPOVER_ORIGIN = {
    ANCHOR: {
        vertical: 'bottom',
        horizontal: 'left'
    },
    TRANSFORM: {
        vertical: 'top',
        horizontal: 'left'
    }
};

export const ALERT_SEVERITIES = {
    SUCCESS: 'success',
    INFO: 'info',
    WARNING: 'warning',
    ERROR: 'error'
};

export type AlertProps = {
    type: string;
    severity: string;
};

export type AlertTypeProps = 'WARRANTY' | 'OBSOLETE' | 'CHECK_IN';

export const ALERT_TYPES = {
    WARRANTY: { type: 'ExpiresSoon', severity: ALERT_SEVERITIES.ERROR },
    OBSOLETE: { type: 'Obsolete', severity: ALERT_SEVERITIES.INFO },
    CHECK_IN: { type: 'CheckIn', severity: ALERT_SEVERITIES.WARNING }
};

export const ADMIN_MODAL_MESSAGES = {
    REQUIRED_FIELDS_WARNING: 'Missing required fields',
    ROLES_QUERY_ERROR: 'Error retrieving admin roles',
    CREATION_SUCCESS: 'Admin created successfully',
    UPDATE_SUCCESS: 'Admin updated successfully'
};

export type ButtonSizes = 'small' | 'medium' | 'large';

export enum BTN_SIZE {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large'
}

export const SIZES = {
    MEDIUM: 'md',
    EXTRA_SMALL: 'xs',
    LARGE: 'lg'
};
export const BTN_SIZES = [BTN_SIZE.SMALL, BTN_SIZE.MEDIUM, BTN_SIZE.LARGE];

export const SNACKBAR_DURATION = 6000;

export const {
    CHECK_IN,
    CHECK_OUT,
    CARD_TEXT,
    DEFAULT_AMOUNT,
    DEFAULT_PERCENTAGE,
    CANT_COMPARE,
    WELCOME_TEXT
} = LABELS.MAINSCREEN;

export const CARD_TYPES = {
    ASSETS_EXPIRED: 'Expired',
    ASSETS_CHECK_OUT: 'Check-Out',
    ASSETS_CHECK_IN: 'Check-In',
    ASSETS_REGISTERED: 'Registered',
    ASSETS_OUT_OFFICE: 'Out Office',
    ASSETS_IN_OFFICE: 'In Office'
};

export const SETTINGS_CARDS = [
    {
        name: LABELS.SETTINGS.LOCATIONS_CARD,
        image: '/images/locations-settings-card.jpg',
        path: PATHS.LOCATIONS
    },
    {
        name: LABELS.SETTINGS.TAX_STATUS_CARD,
        image: '/images/tax-status-settings-card.jpg',
        path: PATHS.TAX_STATUS
    },
    {
        name: LABELS.SETTINGS.ASSET_TYPES_CARD,
        image: '/images/asset-types-settings-card.jpg',
        path: PATHS.ASSET_TYPES
    },
    {
        name: LABELS.SETTINGS.ASSET_STATUS_CARD,
        image: '/images/asset-status-settings-card.jpg',
        path: PATHS.ASSET_STATUS
    },
    {
        name: LABELS.SETTINGS.EMAIL_CARD,
        image: '/images/email-settings-card.jpg',
        path: PATHS.EMAIL
    },
    {
        name: LABELS.SETTINGS.ACCESSORY_CARD,
        image: '/images/accessories-settings-card.jpeg',
        path: PATHS.ACCESSORY_SETTINGS
    }
];

export const HTTP_METHODS = {
    GET: 'get',
    POST: 'post',
    PUT: 'put'
};

export const ACCESS_LEVEL_COOKIE = 'ACCESS_LEVEL';

export const BEARER_TOKEN = 'Bearer';

export const TOKEN_FIELD = 'token';

export const COOKIE_SETTINGS = {
    NAME: VITE_APP_DEPLOY_ENV === 'staging' ? 'kgauth-stage' : 'kgauth-prod',
    EXPIRATION: ';expires= ',
    MAX_AGE: ';max-age= ',
    SECURE: ';secure',
    POLICY: 'single_host_origin',
    SAME_SITE: ';samesite=strict',
    DELETION_DATE: '01 Jan 1970 00:00:00 UTC'
};

export const ASSET_FORM_DATA_KEYS = {
    FIELDS: 'fields',
    DOCUMENTS: 'documents'
};

export const FILE_NAME_REGEX = /^.+\/[0-9]+-(.*)$/;

export const DEBOUNCE_MILISECONDS = 500;

export const DEFAULT_OPTIONS = {
    ASSET_ID: 'No Asset Tag',
    TAX_STATUS: 'No Tax Status'
};

export const USER_EMAILS_FILTERS = {
    domain: 'konradgroup.com',
    viewType: 'domain_public',
    fields: 'users(primaryEmail)',
    maxResults: 500
};

export const LOGIN_SCOPES =
    'https://www.googleapis.com/auth/admin.directory.user https://www.googleapis.com/auth/admin.directory.user.readonly https://www.googleapis.com/auth/cloud-platform';

export const SCANNER_LOG_CHECK_OUT = 'Check-Out';

export const CONDITIONAL_ASSET_TYPE = 'Computers';

export const SCANNER_LOG_COLUMNS = [
    {
        field: 'officeName',
        header: SCANNER_LOG.TABLE.OFFICE,
        default: COLUMNS_DEFAULT_VALUE
    },
    {
        field: 'loggedAt',
        header: SCANNER_LOG.TABLE.DATE,
        default: COLUMNS_DEFAULT_VALUE
    },
    {
        field: 'logType',
        header: SCANNER_LOG.TABLE.EVENT,
        icon: {
            false: <AssetOutOffice fill={COLORS.primary} />,
            true: <AssetInOffice fill={COLORS.primary} />
        },
        default: <AssetInOffice fill={COLORS.primary} />
    },
    {
        field: 'rfid',
        header: SCANNER_LOG.TABLE.RFID,
        default: COLUMNS_DEFAULT_VALUE
    },
    {
        field: 'owner',
        header: SCANNER_LOG.TABLE.OWNER,
        default: COLUMNS_DEFAULT_VALUE
    }
];

export const ADMIN_LOG_COLUMNS = [
    {
        field: 'madeBy',
        header: ADMIN_LOG.TABLE.MADE_BY,
        default: COLUMNS_DEFAULT_VALUE
    },
    {
        field: 'changeType',
        header: ADMIN_LOG.TABLE.CHANGE_TYPE,
        default: COLUMNS_DEFAULT_VALUE
    },
    {
        field: 'details',
        header: ADMIN_LOG.TABLE.DETAILS,
        default: COLUMNS_DEFAULT_VALUE
    },
    {
        field: 'loggedAt',
        header: ADMIN_LOG.TABLE.LOGGED_AT,
        default: COLUMNS_DEFAULT_VALUE
    }
];

export const SUPER_ADMIN_ROLES = [2];

export const ADMIN_ROLES = [1, 2];

export const REGULAR_ROLES = [0, 1, 2];

export const FILE_VALIDATION = {
    MAX_SIZE: 4194304, // 4 MB
    ALLOWED_EXTENSIONS: '.jpg, .jpeg, .png, .pdf'
};

export const EXCEL_EXTENSION = '.xlsx';

export const REPORT_COLUMNS = [
    {
        field: 'name',
        header: REPORTS.TABLE.REPORT_NAME,
        defaul: COLUMNS_DEFAULT_VALUE
    },
    {
        field: 'reportType',
        header: REPORTS.TABLE.REPORT_TYPE,
        default: COLUMNS_DEFAULT_VALUE
    },
    {
        field: 'createdBy',
        header: REPORTS.TABLE.GENERATED,
        default: COLUMNS_DEFAULT_VALUE
    },
    {
        field: 'createdAt',
        header: REPORTS.TABLE.DATE,
        default: COLUMNS_DEFAULT_VALUE
    }
];

export const EMAIL_COLUMNS = [
    {
        field: 'templateName',
        header: EMAIL.TEMPLATE_FIELD,
        default: COLUMNS_DEFAULT_VALUE
    }
];

export const NOTIFICATIONS_COLUMNS = [
    {
        field: 'type',
        header: NOTIFICATIONS.TABLE.TYPE,
        defalut: COLUMNS_DEFAULT_VALUE
    },
    {
        field: 'message',
        header: NOTIFICATIONS.TABLE.MESSAGE,
        defalut: COLUMNS_DEFAULT_VALUE
    },
    {
        field: 'date',
        header: NOTIFICATIONS.TABLE.DATE,
        defalut: COLUMNS_DEFAULT_VALUE
    }
];

export const REPORT_MESSAGES = {
    CREATION_SUCCESS: 'Report created successfully',
    CREATION_ERROR: 'Error to create Report',
    INFO_ERROR: 'Error to bring information'
};

export const AUTO_COMPLETE_STYLE: any = {
    maxHeight: '8rem',
    overflowY: 'auto',
    marginRight: '1rem'
};

export const STEPPER_LABELS = ['Device Info', 'Location Info', 'Legal Info'];

export const LIMIT_TAG = 3;

export const DATE_FORMAT_SHORT_DATE = {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
};

export const DATE_FORMAT_SHORT_DATE_NUMERIC = {
    day: '2-digit',
    month: 'numeric',
    year: 'numeric'
};

export const DATE_FORMAT_ONLY_YEAR = {
    year: 'numeric'
};

export const FILTER_TYPE = {
    CHECKBOX: 'checkbox',
    CHECKBOX_GROUP: 'checkboxGroup',
    MULTI_TAG: 'multiTag',
    DATE: 'date',
    MULTI_DATE: 'multiDate',
    DEFAULT: 'default'
};

export const SETTINGS_BREADCRUMBS = {
    PREVIOUS_CRUMB: LABELS.SETTINGS.MENU_LABEL,
    PREVIOUS_CRUMB_ROUTE: PATHS.SETTINGS,
    ASSET_STATUS_CRUMB: LABELS.ASSET_STATUS.TITLE,
    ASSET_TYPE_CRUMB: LABELS.ASSET_TYPE.TITLE,
    EMAIL_CRUMB: LABELS.EMAIL.TITLE,
    LOCATION_CRUMB: LABELS.LOCATION.TITLE,
    TAX_STATUS_CRUMB: LABELS.TAX_STATUS.TITLE,
    ACCESSORIES_CRUMB: LABELS.ACCESSORIES.TITLE
};

export const ASSET_BREADCRUMBS = {
    PREVIOUS_CRUMB: LABELS.ASSETS.TITTLE,
    PREVIOUS_CRUMB_ROUTE: PATHS.ASSETS
};

export const REPORT_BREADCRUMBS = {
    PREVIOUS_CRUMB: LABELS.REPORTS.TITLE,
    PREVIOUS_CRUMB_ROUTE: PATHS.REPORTS
};

export const ACCESSORY_BREADCRUMBS = {
    PREVIOUS_CRUMB: LABELS.ACCESSORIES.TITLE,
    PREVIOUS_CRUMB_ROUTE: PATHS.ACCESSORIES
};

export const USER_BREADCRUMBS = {
    PREVIOUS_CRUMB: LABELS.USERS.TITLE,
    PREVIOUS_CRUMB_ROUTE: PATHS.USERS
};

export const DEFAULT_GOOGLE_TIMEOUT = 3300;

export const MILLISECOND_CONVERTER = 1000;

export const FILE_NAME_FORMAT = '_';

export const FILENAME_REGEX = /^(.*?)\//;

export const PAGINATION_OPTIONS = {
    SIZE_MEDIUM: 'medium',
    SIZE_SMALL: 'small',
    SIBLING_COUNT_MEDIUM: 1,
    SIBLING_COUNT_SMALL: 0
};

export const REPORT_DATA_RADIO_BUTTON = [
    {
        VALUE: 'ASSET',
        LABEL: 'Assets'
    },
    {
        VALUE: 'ASSET_LOG',
        LABEL: 'Asset Logs'
    }
];

export const FILTER_NAMES = {
    ASSET_MAKE: 'ASSET_MAKE',
    ASSET_MODEL: 'ASSET_MODEL',
    ASSET_STATUS: 'ASSET_STATUS',
    ASSET_TAG: 'ASSET_TAG',
    ASSET_TYPE: 'ASSET_TYPE',
    ASSET_YEAR: 'ASSET_YEAR',
    ASSIGNED_EMPLOYEE: 'ASSIGNED_EMPLOYEE',
    CREATED_AT: 'CREATED_AT',
    DESCRIPTION_DETAILS: 'DESCRIPTION_DETAILS',
    LOCATION_DETAIL: 'LOCATION_DETAIL',
    LOGGED_AT: 'LOGGED_AT',
    IN_OFFICE: 'IN_OFFICE',
    OFFICE_COUNTRY: 'OFFICE_COUNTRY',
    OFFICE_LOCATION: 'OFFICE_LOCATION',
    OFFICE_NAME: 'OFFICE_NAME',
    OS_VERSION: 'OS_VERSION',
    PURCHASE_DATE: 'PURCHASE_DATE',
    RFID: 'RFID',
    SERIAL_NUMBER: 'SERIAL_NUMBER',
    SHARED_DEVICE: 'SHARED_DEVICE',
    TAX_STATUS: 'TAX_STATUS',
    VENDOR: 'VENDOR',
    WARRANTY_DURATION: 'WARRANTY_DURATION',
    INSIDE_REGIME: 'INSIDE_REGIME',
    INVOICE_NUMBER: 'INVOICE_NUMBER'
};

export const READER_STATUS = {
    RUNNING: 'Running',
    STOPPED: 'Stopped',
    FAILED: 'Failed',
    RESTARTING: 'Restarting',
    CONNECTION_LOST: 'Connection Lost'
};

export const ASSET_IDENTIFIERS = ['rfid', 'serialNumber', 'id', 'assetTag'];

export const DEFAULT_PAGE = 1;

export const ENVIRONMENTS = {
    DEV: 'development'
};
export const FILL_VALUES = {
    ZERO: '0',
    CR_CODE: '506'
};

export const OFFICES = {
    TERRACAMPUS: 'TerraCampus',
    TEMPO: 'Plaza Tempo'
};

export const READER_API_STATUS = {
    FAILED: 'FAILED',
    RUNNING: 'RUNNING',
    RESTARTING: 'RESTARTING',
    STOPPED: 'STOPPED',
    CONNECTION_LOST: 'CONNECTION LOST'
};
export const SEARCH_PROP = 'search';
export const TAB_PROP = 'tab';

export const HELPERS = {
    LEGAL_INFO_RULES: {
        RFID: 'Asset Type needs to be "Computers"'
    }
};

export const CR_OFFICE = 2;

export const PARSE_INT_BASE_NUMBER = 10;

export const TAB_VALUES = {
    ASSETS: 0,
    ACCESSORIES: 1,
    USERS: 2,
    ADMINS: 3
};

export const REPORT_TYPE = {
    ASSET: 'ASSET',
    LOG: 'ASSET_LOG',
    OFFBOARDING: 'OFFBOARDING'
};

export const PAGE_SIZE = 8;

export const CLEAR = 'clear';

export const USER_ASSET_COLUMNS = [
    {
        field: 'serialNumber',
        header: LABELS.ASSETS.COLUMNS.SERIAL_NUMBER,
        default: COLUMNS_DEFAULT_VALUE
    },
    {
        field: 'assetTag',
        header: LABELS.ASSETS.COLUMNS.ASSET_TAG,
        default: COLUMNS_DEFAULT_VALUE
    },
    {
        field: 'assetType',
        header: LABELS.ASSETS.COLUMNS.ASSET_TYPE,
        default: COLUMNS_DEFAULT_VALUE
    }
];

export const USER_ACCESSORY_COLUMNS = [
    {
        field: 'name',
        header: LABELS.ACCESSORIES.COLUMNS.NAME
    }
];

export const UNASSIGN_VALUES = {
    ACCESSORY: 'Accessory',
    ASSET: 'Asset',
    ALL: 'All'
};

export const EXCEL_ASSET_FIELD_NAMES: Record<string, string> = {
    assetMake: 'Brand',
    assetModel: 'Model',
    assetYear: 'Year',
    assetType: 'Asset Type',
    assetStatus: 'Asset Status',
    osVersion: 'OS Version',
    descriptionDetails: 'Description',
    sharedDevice: 'Shared QA Device',
    officeName: 'Office',
    locationDetail: 'Office Details',
    inOffice: 'In Location',
    serialNumber: 'Serial Number',
    assetTag: 'Asset Number',
    rfid: 'RFID',
    taxStatus: 'Tax Status',
    vendor: 'Vendor',
    purchaseDate: 'Date of Purchase',
    warrantyDuration: 'Warranty Duration',
    invoiceNumber: 'Invoice Number',
    insideRegime: 'Inside Regime',
    owner: 'Owner'
};

export const EXCEL_FILE_NAME = 'Asset_Control_Template';
export const EXCEL_FILE_LOCATION = 'docs/Asset_Control_Template.xlsx';
