import { API_ROUTES, HTTP_METHODS, PAGE_SIZE } from '@Utils/constants';
import { sendCall } from '@Utils/requestPrep';

const { LOCATION } = API_ROUTES;

class LocationService {
    createLocation = (params) =>
        sendCall(LOCATION.POST, { data: params, method: HTTP_METHODS.POST });

    updateLocation = (id, params) =>
        sendCall(`${LOCATION.PUT}/${id}`, { data: params, method: HTTP_METHODS.PUT });

    getLocations = (page, pagination) =>
        sendCall(`${LOCATION.GET}?page=${page}&limit=${PAGE_SIZE}&pagination=${pagination}`, {
            method: HTTP_METHODS.GET
        });

    disableLocation = (id) => sendCall(`${LOCATION.DISABLE}/${id}`, { method: HTTP_METHODS.POST });

    searchLocation = (params, page) =>
        sendCall(`${LOCATION.SEARCH_POST}?page=${page}&limit=${PAGE_SIZE}`, {
            data: params,
            method: HTTP_METHODS.POST
        });
}

export default new LocationService();
