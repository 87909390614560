import { BTN_SIZE, ButtonSizes } from '@Utils/constants';
import { Button } from '@mui/material';
import cx from 'classnames';
import './Button.scss';
import { CustomButtonProps } from './Button.type';

const CustomButton = ({
    classes,
    disabled,
    label,
    onClick,
    variant,
    size = BTN_SIZE.MEDIUM as ButtonSizes,
    startIcon,
    type,
    uiType,
    endIcon,
    ...props
}: CustomButtonProps) => {
    return (
        <Button
            className={cx('ac2-btn', `ac2-btn--${uiType}`, classes)}
            endIcon={endIcon || null}
            disableElevation
            disableRipple
            onClick={onClick}
            size={size}
            startIcon={startIcon || null}
            variant={variant}
            type={type || 'button'}
            disabled={disabled || false}
            {...props}
        >
            {label}
        </Button>
    );
};

export default CustomButton;
