import {
    COLUMNS_DEFAULT_VALUE,
    FILTER_NAMES,
    FILTER_TYPE,
    REPORT_DATA_RADIO_BUTTON,
    SCANNER_LOG_CHECK_OUT
} from '@Utils/constants';
import { addZeroToDate, getDateString } from '@Utils/dates';
import { LABELS } from '@Utils/labels';
import { isArray, isBoolean, isObject, isString } from 'lodash';
import pickBy from 'lodash/pickBy';

const { FILTERS } = LABELS.REPORTS.MODAL;

export const getSimpleDate = (fullDate?: string) => {
    const date = fullDate ? new Date(fullDate) : new Date();

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const strDay = addZeroToDate(day);
    const strMonth = addZeroToDate(month);

    return `${year}-${strMonth}-${strDay}`;
};

const changeEvent = (event) => {
    if (event === SCANNER_LOG_CHECK_OUT) {
        return false;
    }
    return true;
};

export const formatValuesScanner = (values) => {
    if (values) {
        return values
            .filter((fetchedAssetLog) => fetchedAssetLog._id)
            .filter((fetchedReport) => fetchedReport.rfid)
            .map((fetchedAssetLog) => ({
                ...fetchedAssetLog,
                logType: fetchedAssetLog.logType ? changeEvent(fetchedAssetLog.logType) : true
            }))
            .sort(
                (firstDate, secondDate) =>
                    new Date(secondDate.loggedAt) - new Date(firstDate.loggedAt)
            );
    }
    return [];
};

export const formatAssetStatus = (result) =>
    result.map((fetchedAssetStatus) => ({
        ...fetchedAssetStatus,
        name: FILTER_NAMES.ASSET_STATUS
    }));

export const formatAssetType = (result) =>
    result.map((fetchedAssetType) => ({
        ...fetchedAssetType,
        name: FILTER_NAMES.ASSET_TYPE
    }));

export const formatLocation = (result) =>
    result.map((fetchedLocation) => ({
        _id: fetchedLocation._id,
        description: fetchedLocation.name,
        name: FILTER_NAMES.OFFICE_NAME
    }));

export const formatTaxStatus = (result) =>
    result.map((fetchedTaxStatus) => ({
        ...fetchedTaxStatus,
        name: FILTER_NAMES.TAX_STATUS
    }));

export const createNewInfo = (assetStatus, assetTypes, location, taxStatus) => {
    const formattedAsetStatus = formatAssetStatus(assetStatus.data.docs);
    const formattedAssetTypes = formatAssetType(assetTypes.data.docs);
    const formattedLocations = formatLocation(location.data.docs);
    const formattedTaxStatus = formatTaxStatus(taxStatus.data.docs);

    const newInfo = formattedAsetStatus.concat(
        formattedAssetTypes,
        formattedLocations,
        formattedTaxStatus
    );

    return newInfo;
};

export const formatString = (str) => {
    const newString = str.replace(/_/g, ' ').toLowerCase();
    const formattedString = newString.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
        letter.toUpperCase()
    );
    return formattedString;
};

export const formatValues = (values) => {
    if (values) {
        return values.map((fetchedReport) => ({
            ...fetchedReport,
            createdAt: getDateString(fetchedReport.createdAt),
            reportType: fetchedReport.reportType && formatString(fetchedReport.reportType)
        }));
    }
    return [];
};

export const sanitizedFilter = (filterInfo) => {
    const sanitizedValues = { ...filterInfo };
    delete sanitizedValues.filterOption;
    const newFilterInfo = pickBy(
        sanitizedValues,
        (value) =>
            value.length > 0 ||
            (isBoolean(value) && value) ||
            (isObject(value) && !isArray(value) && Object.values(value)[0] !== '')
    );
    return newFilterInfo;
};

export const restoreFilter = (filters) => {
    Object.keys(filters).forEach((key) => {
        if (isArray(filters[key])) {
            filters[key] = [];
        }
        if (isBoolean(filters[key])) {
            filters[key] = false;
        }
        if (isString(filters[key])) {
            filters[key] = '';
        }
        if (isObject(filters[key]) && !isArray(filters[key])) {
            Object.values(filters[key])[0] = '';
            Object.values(filters[key])[1] = '';
        }
        return filters[key];
    });
};

export enum DATE_NAMES {
    END_DATE = 'endDate',
    START_DATE = 'startDate'
}

export enum FIELD_NAMES {
    CREATE_BY = 'createdBy',
    FILENAME = 'filename',
    REPORT_TYPE = 'reportType',
    ASSET_MAKE = 'assetMake',
    ASSET_MODEL = 'assetModel',
    ASSET_STATUS = 'assetStatus',
    ASSET_TAG = 'assetTag',
    ASSET_TYPE = 'assetType',
    ASSET_YEAR = 'assetYear',
    ASSIGNED_EMPLOYEE = 'assignedEmployee',
    CREATED_AT = 'createdAt.endDate',
    DESCRIPTION_DETAILS = 'descriptionDetails',
    IN_OFFICE = 'inOffice',
    INSIDE_REGIME = 'insideRegime',
    INVOICE_NUMBER = 'invoiceNumber',
    LOCATION_DETAIL = 'locationDetail',
    LOGGED_AT = 'loggedAt',
    OFFICE_COUNTRY = 'officeCountry',
    OFFICE_LOCATION = 'officeLocation',
    OFFICE_NAME = 'officeName',
    OS_VERSION = 'osVersion',
    PURCHASE_DATE = 'purchaseDate',
    RFID = 'rfid',
    SERIAL_NUMBER = 'serialNumber',
    SHARED_DEVICE = 'sharedDevice',
    TAX_STATUS = 'taxStatus',
    VENDOR = 'vendor',
    WARRANTY_DURATION = 'warrantyDuration',
    FILTER_OPTION = 'filterOption'
}

export const FILTERS_OPTIONS = [
    {
        type: FILTER_TYPE.DEFAULT,
        label: LABELS.REPORTS.MODAL.DEFAULT_FILTER,
        name: LABELS.REPORTS.MODAL.DEFAULT_FILTER,
        log: true,
        disabled: true
    },
    {
        type: FILTER_TYPE.MULTI_TAG,
        label: FILTERS.DESCRIPTION.MAKE,
        name: FILTER_NAMES.ASSET_MAKE,
        log: false,
        disabled: false
    },
    {
        type: FILTER_TYPE.MULTI_TAG,
        label: FILTERS.DESCRIPTION.MODEL,
        name: FILTER_NAMES.ASSET_MODEL,
        log: false,
        disabled: false
    },
    {
        type: FILTER_TYPE.MULTI_TAG,
        label: FILTERS.ASSET_NUMBER,
        name: FILTER_NAMES.ASSET_TAG,
        log: false,
        disabled: false
    },
    {
        type: FILTER_TYPE.CHECKBOX_GROUP,
        label: FILTERS.ASSET_STATUS,
        name: FILTER_NAMES.ASSET_STATUS,
        log: false,
        disabled: false
    },
    {
        type: FILTER_TYPE.CHECKBOX_GROUP,
        label: FILTERS.ASSET_TYPE,
        name: FILTER_NAMES.ASSET_TYPE,
        log: false,
        disabled: false
    },
    {
        type: FILTER_TYPE.MULTI_TAG,
        label: FILTERS.DESCRIPTION.YEAR,
        name: FILTER_NAMES.ASSET_YEAR,
        log: false,
        disabled: false
    },
    {
        type: FILTER_TYPE.MULTI_TAG,
        label: FILTERS.DESCRIPTION.DETAILS,
        name: FILTER_NAMES.DESCRIPTION_DETAILS,
        log: false,
        disabled: false
    },
    {
        type: FILTER_TYPE.CHECKBOX,
        label: FILTERS.IN_OFFICE,
        name: FILTER_NAMES.IN_OFFICE,
        log: false,
        disabled: false
    },
    {
        type: FILTER_TYPE.MULTI_TAG,
        label: FILTERS.ASSET_LOCATION.LOCATION_DETAILS,
        name: FILTER_NAMES.LOCATION_DETAIL,
        log: false,
        disabled: false
    },
    {
        type: FILTER_TYPE.MULTI_TAG,
        label: FILTERS.QA_DEVICE.OS_VERSION,
        name: FILTER_NAMES.OS_VERSION,
        log: false,
        disabled: false
    },
    {
        type: FILTER_TYPE.MULTI_DATE,
        label: FILTERS.DATE_OF_PURCHASE,
        name: FILTER_NAMES.PURCHASE_DATE,
        log: false,
        disabled: false
    },
    {
        type: FILTER_TYPE.MULTI_TAG,
        label: FILTERS.RFID,
        name: FILTER_NAMES.RFID,
        log: true,
        disabled: false
    },
    {
        type: FILTER_TYPE.MULTI_TAG,
        label: FILTERS.SERIAL_NUMBER,
        name: FILTER_NAMES.SERIAL_NUMBER,
        log: false,
        disabled: false
    },
    {
        type: FILTER_TYPE.CHECKBOX_GROUP,
        label: FILTERS.TAX_STATUS,
        name: FILTER_NAMES.TAX_STATUS,
        log: false,
        disabled: false
    },
    {
        type: FILTER_TYPE.MULTI_TAG,
        label: FILTERS.VENDOR,
        name: FILTER_NAMES.VENDOR,
        log: false,
        disabled: false
    },
    {
        type: FILTER_TYPE.MULTI_TAG,
        label: FILTERS.WARRANTY_DURATION,
        name: FILTER_NAMES.WARRANTY_DURATION,
        log: false,
        disabled: false
    },
    {
        type: FILTER_TYPE.CHECKBOX_GROUP,
        label: FILTERS.ASSET_LOCATION.OFFICE,
        name: FILTER_NAMES.OFFICE_NAME,
        log: true,
        disabled: false
    },
    {
        type: FILTER_TYPE.MULTI_TAG,
        label: FILTERS.ASSET_LOCATION.COUNTRY,
        name: FILTER_NAMES.OFFICE_COUNTRY,
        log: true,
        disabled: false
    },
    {
        type: FILTER_TYPE.MULTI_TAG,
        label: FILTERS.ASSET_LOCATION.LOCATION,
        name: FILTER_NAMES.OFFICE_LOCATION,
        log: true,
        disabled: false
    },
    {
        type: FILTER_TYPE.MULTI_TAG,
        label: FILTERS.ASSIGNED_EMPLOYEE,
        name: FILTER_NAMES.ASSIGNED_EMPLOYEE,
        log: false,
        disabled: false
    },
    {
        type: FILTER_TYPE.CHECKBOX,
        label: FILTERS.QA_DEVICE.SHARED_DEVICE,
        name: FILTER_NAMES.SHARED_DEVICE,
        log: false,
        disabled: false
    },
    {
        type: FILTER_TYPE.MULTI_DATE,
        label: LABELS.REPORTS.MODAL.CREATED_AT,
        name: FILTER_NAMES.CREATED_AT,
        log: false,
        disabled: false
    },
    {
        type: FILTER_TYPE.MULTI_DATE,
        label: LABELS.REPORTS.MODAL.LOGGED_AT,
        name: FILTER_NAMES.LOGGED_AT,
        log: true,
        disabled: false
    },
    {
        type: FILTER_TYPE.CHECKBOX,
        label: FILTERS.INSIDE_REGIME,
        name: FILTER_NAMES.INSIDE_REGIME,
        log: false,
        disabled: false
    },
    {
        type: FILTER_TYPE.MULTI_TAG,
        label: FILTERS.INVOICE_NUMBER,
        name: FILTER_NAMES.INVOICE_NUMBER,
        log: false,
        disabled: false
    }
];

export const REPORT_VALUES = {
    createdBy: '',
    filename: '',
    reportType: REPORT_DATA_RADIO_BUTTON[0].VALUE
};

export const FILTERS_VALUES = {
    assetMake: [],
    assetModel: [],
    assetStatus: [],
    assetTag: [],
    assetType: [],
    assetYear: [],
    assignedEmployee: [],
    createdAt: {
        endDate: '',
        startDate: ''
    },
    descriptionDetails: [],
    inOffice: false,
    insideRegime: false,
    invoiceNumber: [],
    locationDetail: [],
    loggedAt: {
        endDate: '',
        startDate: ''
    },
    officeCountry: [],
    officeLocation: [],
    officeName: [],
    osVersion: [],
    purchaseDate: {
        endDate: '',
        startDate: ''
    },
    rfid: [],
    serialNumber: [],
    sharedDevice: false,
    taxStatus: [],
    vendor: [],
    warrantyDuration: [],
    filterOption: FILTERS_OPTIONS[0].name
};

export const findLabel = (fieldName) => {
    const fieldTag = Object.entries(FIELD_NAMES).find((field) =>
        isObject(field[1])
            ? fieldName === field[1].START_DATE.split('.')[0]
            : fieldName === field[1]
    );

    const fieldLabel = FILTERS_OPTIONS.find((option) => option.name === fieldTag[0]);

    if (fieldLabel) {
        return fieldLabel.label;
    }
    return LABELS.REPORTS.MODAL.FILTERS[fieldTag[0]];
};

export const sortFilters = (filters) => {
    const defaultFilter = filters[0];
    filters.shift();
    const sortedFilters = Object.entries(filters).sort((firstFilter, secondFilter) =>
        firstFilter[1].label.localeCompare(secondFilter[1].label)
    );
    return sortedFilters.shift(defaultFilter);
};

export const formatBreadcrumb = (report) => report._id;

export const REPORT_DETAILS_COLUMNS = {
    SERIAL_NUMBER: {
        field: 'serialNumber',
        header: LABELS.REPORTS.DETAIL_COLUMNS.SERIAL_NUMBER,
        default: COLUMNS_DEFAULT_VALUE
    },
    ASSET_TAG: {
        field: 'assetTag',
        header: LABELS.REPORTS.DETAIL_COLUMNS.ASSET_TAG,
        default: COLUMNS_DEFAULT_VALUE
    },
    RFID: {
        field: 'rfid',
        header: LABELS.REPORTS.DETAIL_COLUMNS.RFID,
        default: COLUMNS_DEFAULT_VALUE
    },
    ASSET_TYPE_CODE: {
        field: 'assetTypeCode',
        header: LABELS.REPORTS.DETAIL_COLUMNS.ASSET_TYPE_CODE,
        default: COLUMNS_DEFAULT_VALUE
    },
    ASSET_TYPE: {
        field: 'assetType',
        header: LABELS.REPORTS.DETAIL_COLUMNS.ASSET_TYPE,
        default: COLUMNS_DEFAULT_VALUE
    },
    ASSET_STATUS: {
        field: 'assetStatus',
        header: LABELS.REPORTS.DETAIL_COLUMNS.ASSET_STATUS,
        default: COLUMNS_DEFAULT_VALUE
    },
    TAX_STATUS: {
        field: 'taxStatus',
        header: LABELS.REPORTS.DETAIL_COLUMNS.TAX_STATUS,
        default: COLUMNS_DEFAULT_VALUE
    },
    INVOICE_NUMBER: {
        field: 'invoiceNumber',
        header: LABELS.REPORTS.DETAIL_COLUMNS.INVOICE_NUMBER,
        default: COLUMNS_DEFAULT_VALUE
    },
    INSIDE_REGIME: {
        field: 'insideRegime',
        header: LABELS.REPORTS.DETAIL_COLUMNS.INSIDE_REGIME,
        default: COLUMNS_DEFAULT_VALUE
    },
    ASSET_MODEL: {
        field: 'assetModel',
        header: LABELS.REPORTS.DETAIL_COLUMNS.ASSET_MODEL,
        default: COLUMNS_DEFAULT_VALUE
    },
    ASSET_MAKE: {
        field: 'assetMake',
        header: LABELS.REPORTS.DETAIL_COLUMNS.ASSET_MAKE,
        default: COLUMNS_DEFAULT_VALUE
    },
    ASSET_YEAR: {
        field: 'assetYear',
        header: LABELS.REPORTS.DETAIL_COLUMNS.ASSET_YEAR,
        default: COLUMNS_DEFAULT_VALUE
    },
    ASSET_DETAILS: {
        field: 'descriptionDetails',
        header: LABELS.REPORTS.DETAIL_COLUMNS.ASSET_DETAILS,
        default: COLUMNS_DEFAULT_VALUE
    },
    VENDOR: {
        field: 'vendor',
        header: LABELS.REPORTS.DETAIL_COLUMNS.VENDOR,
        default: COLUMNS_DEFAULT_VALUE
    },
    PURCHASE_DATE: {
        field: 'purchaseDate',
        header: LABELS.REPORTS.DETAIL_COLUMNS.PURCHASE_DATE,
        default: COLUMNS_DEFAULT_VALUE
    },
    WARRANTY_DURATION: {
        field: 'warrantyDuration',
        header: LABELS.REPORTS.DETAIL_COLUMNS.WARRANTY_DURATION,
        default: COLUMNS_DEFAULT_VALUE
    },
    OS_VERSION: {
        field: 'osVersion',
        header: LABELS.REPORTS.DETAIL_COLUMNS.OS_VERSION,
        default: COLUMNS_DEFAULT_VALUE
    },
    SHARED_DEVICE: {
        field: 'sharedDevice',
        header: LABELS.REPORTS.DETAIL_COLUMNS.SHARED_DEVICE,
        default: COLUMNS_DEFAULT_VALUE
    },
    OFFICE_NAME: {
        field: 'officeName',
        header: LABELS.REPORTS.DETAIL_COLUMNS.OFFICE_NAME,
        default: COLUMNS_DEFAULT_VALUE
    },
    OFFICE_COUNTRY: {
        field: 'officeCountry',
        header: LABELS.REPORTS.DETAIL_COLUMNS.OFFICE_COUNTRY,
        default: COLUMNS_DEFAULT_VALUE
    },
    OFFICE_LOCATION: {
        field: 'officeLocation',
        header: LABELS.REPORTS.DETAIL_COLUMNS.OFFICE_LOCATION,
        default: COLUMNS_DEFAULT_VALUE
    },
    LOCATION_DETAIL: {
        field: 'locationDetail',
        header: LABELS.REPORTS.DETAIL_COLUMNS.LOCATION_DETAIL,
        default: COLUMNS_DEFAULT_VALUE
    },
    IN_OFFICE: {
        field: 'inOffice',
        header: LABELS.REPORTS.DETAIL_COLUMNS.IN_OFFICE,
        default: COLUMNS_DEFAULT_VALUE
    },
    CREATED_AT: {
        field: 'createdAt',
        header: LABELS.REPORTS.DETAIL_COLUMNS.CREATED_AT,
        default: COLUMNS_DEFAULT_VALUE
    },
    TIMEZONE: {
        field: 'timezone',
        header: LABELS.REPORTS.DETAIL_COLUMNS.TIMEZONE,
        default: COLUMNS_DEFAULT_VALUE
    },
    OWNER_EMAIL: {
        field: 'owner',
        header: LABELS.REPORTS.DETAIL_COLUMNS.OWNER_EMAIL,
        default: COLUMNS_DEFAULT_VALUE
    },
    LOGGED_AT: {
        field: 'loggedAt',
        header: LABELS.REPORTS.DETAIL_COLUMNS.LOGGED_AT,
        default: COLUMNS_DEFAULT_VALUE
    },
    LOG_TYPE: {
        field: 'logType',
        header: LABELS.REPORTS.DETAIL_COLUMNS.LOG_TYPE,
        default: COLUMNS_DEFAULT_VALUE
    }
};

export const ASSET_REPORT_FILTER_COLUMNS = [
    REPORT_DETAILS_COLUMNS.SERIAL_NUMBER,
    REPORT_DETAILS_COLUMNS.ASSET_TAG,
    REPORT_DETAILS_COLUMNS.RFID,
    REPORT_DETAILS_COLUMNS.ASSET_TYPE_CODE,
    REPORT_DETAILS_COLUMNS.ASSET_TYPE,
    REPORT_DETAILS_COLUMNS.ASSET_STATUS,
    REPORT_DETAILS_COLUMNS.TAX_STATUS,
    REPORT_DETAILS_COLUMNS.INVOICE_NUMBER,
    REPORT_DETAILS_COLUMNS.INSIDE_REGIME,
    REPORT_DETAILS_COLUMNS.ASSET_MODEL,
    REPORT_DETAILS_COLUMNS.ASSET_MAKE,
    REPORT_DETAILS_COLUMNS.ASSET_YEAR,
    REPORT_DETAILS_COLUMNS.ASSET_DETAILS,
    REPORT_DETAILS_COLUMNS.VENDOR,
    REPORT_DETAILS_COLUMNS.PURCHASE_DATE,
    REPORT_DETAILS_COLUMNS.WARRANTY_DURATION,
    REPORT_DETAILS_COLUMNS.OS_VERSION,
    REPORT_DETAILS_COLUMNS.SHARED_DEVICE,
    REPORT_DETAILS_COLUMNS.OFFICE_NAME,
    REPORT_DETAILS_COLUMNS.OFFICE_COUNTRY,
    REPORT_DETAILS_COLUMNS.OFFICE_LOCATION,
    REPORT_DETAILS_COLUMNS.LOCATION_DETAIL,
    REPORT_DETAILS_COLUMNS.IN_OFFICE,
    REPORT_DETAILS_COLUMNS.CREATED_AT,
    REPORT_DETAILS_COLUMNS.TIMEZONE,
    REPORT_DETAILS_COLUMNS.OWNER_EMAIL
];

export const ASSET_LOGS_REPORT_FILTER_COLUMNS = [
    REPORT_DETAILS_COLUMNS.LOGGED_AT,
    REPORT_DETAILS_COLUMNS.LOG_TYPE,
    REPORT_DETAILS_COLUMNS.RFID,
    REPORT_DETAILS_COLUMNS.OFFICE_NAME,
    REPORT_DETAILS_COLUMNS.OFFICE_COUNTRY,
    REPORT_DETAILS_COLUMNS.OFFICE_LOCATION,
    REPORT_DETAILS_COLUMNS.TIMEZONE
];

export const ASSET_REPORT_FILTERS = ASSET_REPORT_FILTER_COLUMNS.map((filter, index) => ({
    id: filter.field,
    frag: index + 1,
    label: filter.header
}));

export const ASSET_REPORT_DEFAULT_FILTERS = ASSET_REPORT_FILTERS.filter(
    (value) =>
        value.id === REPORT_DETAILS_COLUMNS.SERIAL_NUMBER.field ||
        value.id === REPORT_DETAILS_COLUMNS.ASSET_TAG.field ||
        value.id === REPORT_DETAILS_COLUMNS.ASSET_TYPE.field ||
        value.id === REPORT_DETAILS_COLUMNS.OWNER_EMAIL.field ||
        value.id === REPORT_DETAILS_COLUMNS.ASSET_MODEL.field
);

export const ASSET_LOGS_REPORT_FILTERS = ASSET_LOGS_REPORT_FILTER_COLUMNS.map((filter, index) => ({
    id: filter.field,
    frag: index + 1,
    label: filter.header
}));

export const ASSET_LOGS_REPORT_DEFAULT_FILTERS = ASSET_LOGS_REPORT_FILTERS.filter(
    (value) =>
        value.id === REPORT_DETAILS_COLUMNS.LOGGED_AT.field ||
        value.id === REPORT_DETAILS_COLUMNS.LOG_TYPE.field ||
        value.id === REPORT_DETAILS_COLUMNS.RFID.field ||
        value.id === REPORT_DETAILS_COLUMNS.OFFICE_NAME.field
);
