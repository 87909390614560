import React, { useEffect, useState } from 'react';

import './ScannerLog.scss';
import EmptyIcon from '@Assets/Images/empty-box.svg';
import AssetLogServices from '@Services/AssetLog.services';
import CustomBreadcrumbs from '@Shared/Breadcrumbs/Breadcrumbs';
import Loading from '@Shared/Loading/Loading';
import SearchBar from '@Shared/SearchBar/SearchBar';
import ServerError from '@Shared/ServerError/ServerError';
import CustomSnackbar from '@Shared/Snackbar/Snackbar';
import Table from '@Shared/Table/Table';
import { showErrorAlert } from '@Utils/alerts';
import {
    FIRST_PAGE_NUMBER,
    PAGE_PROP,
    PARSE_INT_BASE_NUMBER,
    PATHS,
    SCANNER_LOG_COLUMNS,
    SEARCH_PROP
} from '@Utils/constants';
import { getDateString } from '@Utils/dates';
import { LABELS } from '@Utils/labels';
import { AlertColor, Grid } from '@mui/material';
import { useLocation, useSearchParams } from 'react-router-dom';

import { formatValuesScanner } from '../Reports.utils';
import { SnackBarProps } from '~/Components/Shared/Snackbar/Snackbar.type';

const { EMPTY_MESSAGES } = LABELS;

const ScannerLog = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isMessageOpen, setIsMessageOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<SnackBarProps>();
    const [assetLogsInfo, setAssetLogsInfo] = useState({ docs: [], totalPages: -1 });
    const [dataObtainFailed, setDataObtainFailed] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const location = useLocation();

    const showMessage = (pSeverity: string, pMessage: string) => {
        setIsMessageOpen(true);
        setMessage({
            alertSeverity: pSeverity as AlertColor,
            alertMessage: pMessage
        });
    };

    const prepAssetLogs = (assetLogs: any) => {
        assetLogs.loggedAt = getDateString(assetLogs.loggedAt);
        return assetLogs;
    };

    const getAssetInfo = () => {
        AssetLogServices.getAssetLogs(currentPage, true)
            .then(({ data }) => {
                data.docs = data.docs.map(prepAssetLogs);
                setAssetLogsInfo(data);
            })
            .catch(({ response }) => {
                showErrorAlert(showMessage, response);
                setDataObtainFailed(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getAssetInfo();
    }, [currentPage]);

    useEffect(() => {
        if (searchParams.get(PAGE_PROP)) {
            setCurrentPage(parseInt(searchParams.get(PAGE_PROP) || '', PARSE_INT_BASE_NUMBER));
        }
    }, [location]);

    const handleCloseMessage = () => {
        setIsMessageOpen(false);
    };

    const onSearch = (values: any) => {
        values.docs = values.docs.map(prepAssetLogs);
        setAssetLogsInfo(values || []);
    };

    const renderMainComponent = (loading: boolean, failed: boolean) => {
        if (loading) {
            return <Loading />;
        }
        if (failed) {
            return <ServerError />;
        }
        return (
            <Grid item xs={12} sm={12} md={12}>
                <Table
                    columns={SCANNER_LOG_COLUMNS}
                    details
                    detailsLabel={LABELS.SCANNER_LOG.TABLE.DETAILS}
                    rows={formatValuesScanner(assetLogsInfo.docs)}
                    emptyMessage={EMPTY_MESSAGES.SCANNER_LOG}
                    emptyIcon={EmptyIcon}
                    detailsPath={PATHS.ASSETS}
                    idField="assetId"
                    page={currentPage}
                    searchParam={searchParams.get(SEARCH_PROP) || ''}
                    updatePage={setCurrentPage}
                    itemCount={assetLogsInfo.totalPages}
                />
            </Grid>
        );
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
                <CustomBreadcrumbs
                    current={LABELS.SCANNER_LOG.TITLE}
                    previous={{
                        route: `${PATHS.REPORTS}?${PAGE_PROP}=${FIRST_PAGE_NUMBER}`,
                        name: LABELS.REPORTS.TITLE
                    }}
                />
                <h1>{LABELS.SCANNER_LOG.TITLE}</h1>
            </Grid>
            <Grid container justifyContent="flex-end">
                <Grid item xs={12} sm={12} md={6}>
                    <SearchBar
                        setSearchResults={onSearch}
                        setIsLoading={setIsLoading}
                        searchService={(param) =>
                            AssetLogServices.getAssetLogsSearch(param, currentPage)
                        }
                        setServerError={setDataObtainFailed}
                    />
                </Grid>
            </Grid>
            {renderMainComponent(isLoading, dataObtainFailed)}
            {message && (
                <CustomSnackbar
                    isOpen={isMessageOpen}
                    handleClose={handleCloseMessage}
                    alertMessage={message.alertMessage}
                    alertSeverity={message.alertSeverity as AlertColor}
                />
            )}
        </Grid>
    );
};

export default ScannerLog;
