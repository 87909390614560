import { API_ROUTES, HTTP_METHODS, PAGE_SIZE } from '@Utils/constants';
import { sendCall } from '@Utils/requestPrep';

const { ASSET } = API_ROUTES;

class AssetService {
    createAsset = (params) =>
        sendCall(ASSET.POST, {
            data: params,
            method: HTTP_METHODS.POST
        });

    getAsset = (id) => sendCall(`${ASSET.GET}/${id}`, { method: HTTP_METHODS.GET });

    getAssets = (page, pagination) =>
        sendCall(`${ASSET.GET}?page=${page}&limit=${PAGE_SIZE}&pagination=${pagination}`, {
            method: HTTP_METHODS.GET
        });

    getSpecificAsset = (id) => sendCall(`${ASSET.GET}/${id}`, { method: HTTP_METHODS.GET });

    getAssetsSearch = (params, page) =>
        sendCall(`${ASSET.SEARCH_POST}?page=${page}&limit=${PAGE_SIZE}`, {
            data: params,
            method: HTTP_METHODS.POST
        });

    editAsset = (id, params) =>
        sendCall(`${ASSET.EDIT}/${id}`, { data: params, method: HTTP_METHODS.POST });

    assignAsset = (id, params) =>
        sendCall(`${ASSET.PUT}/${id}`, { data: params, method: HTTP_METHODS.PUT });

    unassignAsset = (id, params) =>
        sendCall(`${ASSET.UNASSIGN}/${id}`, { data: params, method: HTTP_METHODS.PUT });

    uploadAsset = (params) =>
        sendCall(ASSET.UPLOAD, {
            data: params,
            method: HTTP_METHODS.POST
        });
}

export default new AssetService();
