import { API_ROUTES, HTTP_METHODS, PAGE_SIZE } from '@Utils/constants';
import { sendCall } from '@Utils/requestPrep';

const { EMAIL } = API_ROUTES;

class EmailService {
    welcomeEmail = (params) =>
        sendCall(`${EMAIL.WELCOME}`, { data: params, method: HTTP_METHODS.POST });

    updateEmail = (params) => sendCall(`${EMAIL.PUT}`, { data: params, method: HTTP_METHODS.PUT });

    getEmails = (page, pagination) =>
        sendCall(`${EMAIL.GET}?page=${page}&limit=${PAGE_SIZE}&pagination=${pagination}`, {
            method: HTTP_METHODS.GET
        });

    searchEmail = (params, page) =>
        sendCall(`${EMAIL.SEARCH_POST}?page=${page}&limit=${PAGE_SIZE}`, {
            data: params,
            method: HTTP_METHODS.POST
        });
}

export default new EmailService();
