import AccessoryDetails from '@Components/Assets/AccessoryDetails';
import ReportDetails from '@Components/Reports/ReportDetails/ReportDetails';
import AccessorySettings from '@Components/Settings/Accessories/AccessorySettings';
import UserDetails from '@Components/Users/UserDetails';
import {
    AdminLog,
    AssetDetails,
    Assets,
    AssetsStatus,
    AssetType,
    Email,
    Home,
    Locations,
    NotFound,
    Notifications,
    Reports,
    ScannerLog,
    Settings,
    TaxStatus,
    Unauthorized,
    Users
} from '@Components/exporter';
import { ADMIN_ROLES, PATHS, SUPER_ADMIN_ROLES } from '@Utils/constants';
import { Route, Routes } from 'react-router-dom';

import { PrivateRoute } from './PrivateRoute';
import ProtectedRoutes from './ProtectedRoutes';

const {
    HOME,
    ASSETS,
    ASSET_DETAILS,
    ASSET_STATUS,
    ASSET_TYPES,
    EMAIL,
    LOCATIONS,
    REPORTS,
    USERS,
    SETTINGS,
    NOTFOUND,
    TAX_STATUS,
    UNAUTHORIZED,
    SCANNER_LOG,
    ADMIN_LOG,
    NOTIFICATIONS,
    REPORT_DETAILS,
    ACCESSORY_SETTINGS,
    ACCESSORY_DETAILS,
    USER_DETAILS
} = PATHS;

const AppRoutes = () => (
    <Routes>
        <Route path={UNAUTHORIZED} element={<Unauthorized />} />
        <Route element={<ProtectedRoutes />}>
            <Route
                exact
                path={HOME}
                element={
                    <PrivateRoute roles={ADMIN_ROLES} redirect={<Assets />}>
                        <Home />
                    </PrivateRoute>
                }
            />
            <Route exact path={ASSET_DETAILS} element={<AssetDetails />} />
            <Route exact path={ACCESSORY_DETAILS} element={<AccessoryDetails />} />
            <Route exact path={ASSETS} element={<Assets />} />
            <Route
                exact
                path={REPORTS}
                element={
                    <PrivateRoute roles={ADMIN_ROLES}>
                        <Reports />
                    </PrivateRoute>
                }
            />
            <Route exact path={REPORT_DETAILS} element={<ReportDetails />} />
            <Route
                exact
                path={SCANNER_LOG}
                element={
                    <PrivateRoute roles={ADMIN_ROLES}>
                        <ScannerLog />
                    </PrivateRoute>
                }
            />
            <Route
                exact
                path={ASSET_STATUS}
                element={
                    <PrivateRoute roles={ADMIN_ROLES}>
                        <AssetsStatus />
                    </PrivateRoute>
                }
            />
            <Route
                exact
                path={TAX_STATUS}
                element={
                    <PrivateRoute roles={ADMIN_ROLES}>
                        <TaxStatus />
                    </PrivateRoute>
                }
            />
            <Route
                exact
                path={LOCATIONS}
                element={
                    <PrivateRoute roles={ADMIN_ROLES}>
                        <Locations />
                    </PrivateRoute>
                }
            />
            <Route
                path={ASSET_TYPES}
                element={
                    <PrivateRoute roles={ADMIN_ROLES}>
                        <AssetType />
                    </PrivateRoute>
                }
            />
            <Route
                exact
                path={EMAIL}
                element={
                    <PrivateRoute roles={ADMIN_ROLES}>
                        <Email />
                    </PrivateRoute>
                }
            />
            <Route
                exact
                path={SETTINGS}
                element={
                    <PrivateRoute roles={ADMIN_ROLES}>
                        <Settings />
                    </PrivateRoute>
                }
            />
            <Route path={NOTFOUND} element={<NotFound />} />
            <Route
                exact
                path={USERS}
                element={
                    <PrivateRoute roles={SUPER_ADMIN_ROLES}>
                        <Users />
                    </PrivateRoute>
                }
            />
            <Route
                exact
                path={ADMIN_LOG}
                element={
                    <PrivateRoute roles={SUPER_ADMIN_ROLES}>
                        <AdminLog />
                    </PrivateRoute>
                }
            />
            <Route
                exact
                path={NOTIFICATIONS}
                element={
                    <PrivateRoute roles={ADMIN_ROLES}>
                        <Notifications />
                    </PrivateRoute>
                }
            />
            <Route
                exact
                path={ACCESSORY_SETTINGS}
                element={
                    <PrivateRoute roles={ADMIN_ROLES}>
                        <AccessorySettings />
                    </PrivateRoute>
                }
            />

            <Route
                exact
                path={USER_DETAILS}
                element={
                    <PrivateRoute roles={SUPER_ADMIN_ROLES}>
                        <UserDetails />
                    </PrivateRoute>
                }
            />
        </Route>
    </Routes>
);

export default AppRoutes;
