import { useEffect, useState } from 'react';

import AssetStatusService from '@Services/AssetStatus.services';
import AssetTypeService from '@Services/AssetType.services';
import LocationService from '@Services/Location.services';
import TaxStatusService from '@Services/TaxStatus.services';
import useWindowDimensions from '@Shared/Hooks/useWindowDimension';
import CustomModal from '@Shared/Modal/Modal';
import SetupStepper from '@Shared/Stepper/Stepper';
import breakpoints from '@Styling/breakpoints.module.scss';
import { showErrorAlert } from '@Utils/alerts';
import { STEPPER_LABELS } from '@Utils/constants';
import { LABELS } from '@Utils/labels';

import DeviceInfo from './DeviceInfo';
import LegalInfo from './LegalInfo';
import LocationInfo from './LocationInfo';
import {
    ASSET_MODAL_STEPS,
    DEVICE_VALUES,
    divideAsset,
    formatAssetStatus,
    formatAssetType,
    formatLocation,
    formatTaxStatus,
    LEGAL_VALUES,
    LOCATION_VALUES
} from '../Assets.utils';
import { AssetInputModalProps, DeviceInfoProps } from './AssetInputModal.type';

const { ALERTS, ASSET_MODAL } = LABELS;

const AssetInputModal = ({
    title,
    modalIsOpen,
    handleClose,
    setAlert,
    successMessage,
    storedAssetInfo,
    onSubmit,
    onSuccess,
    formatter
}: AssetInputModalProps) => {
    const [step, setStep] = useState(ASSET_MODAL_STEPS.DEVICE_INFO);
    const [deviceOpen, setDeviceOpen] = useState<boolean>(false);
    const [locationOpen, setLocationOpen] = useState<boolean>(false);
    const [legalOpen, setLegalOpen] = useState<boolean>(false);

    const [deviceInfo, setDeviceInfo] = useState<DeviceInfoProps>(DEVICE_VALUES);
    const [locationInfo, setLocationInfo] = useState(LOCATION_VALUES);
    const [legalInfo, setLegalInfo] = useState(LEGAL_VALUES);

    const [assetStatusInfo, setAssetStatusInfo] = useState<any[]>([]);
    const [taxStatusInfo, setTaxStatusInfo] = useState<any[]>([]);
    const [assetTypesInfo, setAssetTypesInfo] = useState<any[]>([]);
    const [locationsInfo, setLocationsInfo] = useState<any[]>([]);
    const [isDropdownInfoLoading, setIsDropdownInfoLoading] = useState<boolean>(true);
    const { width } = useWindowDimensions();

    // load dropdown info on render
    useEffect(() => {
        const getStatus = async () => {
            Promise.all([
                AssetStatusService.getAssetStatus(null, false),
                AssetTypeService.getAssetTypes(null, false),
                LocationService.getLocations(null, false),
                TaxStatusService.getTaxStatus(null, false)
            ])
                .then(([assetStatus, assetTypes, location, taxStatus]) => {
                    const formattedAssetStatus = formatAssetStatus(assetStatus.data.docs);
                    const formattedAssetTypes = formatAssetType(assetTypes.data.docs);
                    const formattedLocations = formatLocation(location.data.docs);
                    const formattedTaxStatus = formatTaxStatus(taxStatus.data.docs);

                    setAssetStatusInfo(formattedAssetStatus);
                    setAssetTypesInfo(formattedAssetTypes);
                    setLocationsInfo(formattedLocations);
                    setTaxStatusInfo(formattedTaxStatus);

                    setIsDropdownInfoLoading(false);
                })
                .catch(() => {
                    setAlert(ASSET_MODAL.DROPDOWN_OPTIONS_FETCH_ERROR, ALERTS.SEVERITIES.ERROR);
                });
        };
        getStatus();
    }, []);

    useEffect(() => {
        if (!isDropdownInfoLoading) {
            if (storedAssetInfo) {
                const {
                    deviceInfo: newDeviceInfo,
                    locationInfo: newLocationInfo,
                    legalInfo: newLegalInfo
                } = divideAsset(storedAssetInfo);

                setDeviceInfo({
                    ...deviceInfo,
                    ...newDeviceInfo,
                    ...{
                        assetStatus: assetStatusInfo.find(
                            ({ description }) => description === newDeviceInfo.assetStatus
                        )?.id,
                        assetType: assetTypesInfo.find(
                            ({ description }) => description === newDeviceInfo.assetType
                        )?.id
                    }
                });
                setLocationInfo({
                    ...locationInfo,
                    ...newLocationInfo,
                    ...{
                        officeId: locationsInfo.find(
                            ({ name }) => name === newLocationInfo.officeId
                        )?.id
                    }
                });
                setLegalInfo({
                    ...legalInfo,
                    ...newLegalInfo,
                    ...{
                        taxStatus: taxStatusInfo.find(
                            ({ description }) => description === newLegalInfo.taxStatus
                        )?.id
                    }
                });
            }
            setDeviceOpen(true);
        }
    }, [isDropdownInfoLoading]);

    const deviceNextStepHandler = (data: any) => {
        setDeviceInfo(data);
        setDeviceOpen(false);
        setLocationOpen(true);
        setStep(ASSET_MODAL_STEPS.LOCATION_INFO);
    };

    const locationNextStepHandler = (data: any) => {
        setLocationInfo(data);
        setLocationOpen(false);
        setLegalOpen(true);
        setStep(ASSET_MODAL_STEPS.LEGAL_INFO);
    };

    const locationPreviousStepHandler = (data: any) => {
        setLocationInfo(data);
        setLocationOpen(false);
        setDeviceOpen(true);
        setStep(ASSET_MODAL_STEPS.DEVICE_INFO);
    };

    const legalPreviousStepHandler = (data: any) => {
        setLegalInfo(data);
        setLegalOpen(false);
        setLocationOpen(true);
        setStep(ASSET_MODAL_STEPS.LOCATION_INFO);
    };

    const submitHandler = (data: any) => {
        const assetData = { ...deviceInfo, ...locationInfo, ...data };
        const assetFormData = formatter(assetData);

        const id = storedAssetInfo ? storedAssetInfo.id : null;

        onSubmit(assetFormData, id)
            .then((response: any) => {
                onSuccess(response.data.result);
                setLegalOpen(false);
                handleClose();
                setAlert(successMessage);
            })
            .catch((error: any) => {
                showErrorAlert(setAlert, error.response);
            });
    };

    return (
        <CustomModal title={title} size="md" open={modalIsOpen} handleClose={handleClose}>
            <>
                {deviceOpen && (
                    <DeviceInfo
                        deviceInfo={deviceInfo}
                        assetStatusInfo={assetStatusInfo}
                        assetTypeInfo={assetTypesInfo}
                        nextHandler={deviceNextStepHandler}
                    />
                )}
                {locationOpen && (
                    <LocationInfo
                        locationInfo={locationInfo}
                        locationsInfo={locationsInfo}
                        nextHandler={locationNextStepHandler}
                        previousHandler={locationPreviousStepHandler}
                    />
                )}
                {legalOpen && (
                    <LegalInfo
                        legalInfo={legalInfo}
                        taxStatusInfo={taxStatusInfo}
                        conditionalInfo={deviceInfo.assetType}
                        assetTypeInfo={assetTypesInfo}
                        submitHandler={submitHandler}
                        previousHandler={legalPreviousStepHandler}
                        setAlert={setAlert}
                    />
                )}
                <SetupStepper
                    activeStep={step}
                    steps={STEPPER_LABELS}
                    alternativeLabel={width <= parseInt(breakpoints.tablet)}
                />
            </>
        </CustomModal>
    );
};

export default AssetInputModal;
