import { theme } from '@Assets/Styling/theme';
import { UserProvider } from '@Context/userContext';
import AppRoutes from '@Routes/Routes';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { PortalContext } from '@kg-internal/pv3-uikit';
import { ThemeProvider } from '@mui/system';
import { CookiesProvider } from 'react-cookie';

import './App.scss';
import config from './config.ts';

const env = import.meta.env.VITE_APP_DEPLOY_ENV;

const { graphqlUrl } = config.urls[env];

const client = new ApolloClient({
    uri: graphqlUrl,
    cache: new InMemoryCache()
});

const App = () => (
    <PortalContext env={env} bypassHandbookCheck name="asset-control" version="1.0">
        <ApolloProvider client={client}>
            <ThemeProvider theme={theme}>
                <CookiesProvider>
                    <UserProvider>
                        <AppRoutes />
                    </UserProvider>
                </CookiesProvider>
            </ThemeProvider>
        </ApolloProvider>
    </PortalContext>
);

export default App;
