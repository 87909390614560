import { API_ROUTES, HTTP_METHODS } from '@Utils/constants';
import { sendCall } from '@Utils/requestPrep';

const { AUTH } = API_ROUTES;

class AuthService {
    validateUser = () =>
        sendCall(AUTH.VALIDATE_USER, {
            method: HTTP_METHODS.GET
        });
}

export default new AuthService();
