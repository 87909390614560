import { LABELS } from '@Utils/labels';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import cx from 'classnames';

import './Table.scss';
import RowDetails from './RowDetails';
import TableOptions from './TableOptions';
import { TableProps } from './Table.type';

const { TABLE } = LABELS;

const WebTable = ({
    rows,
    columns,
    ariaLabel,
    edit,
    info,
    disable,
    download,
    infoHandler,
    editHandler,
    disableHandler,
    disableConfirmationMessage,
    downloadHandler,
    details,
    detailsLabel,
    detailsPath,
    page,
    idField = '',
    searchParam
}: TableProps) => {
    const hasOptions = disable || edit || download || info;

    const handleInfo = (row: any) => () => infoHandler && infoHandler(row);

    const handleUpdate = (row: any) => () => editHandler && editHandler(row);

    const handleDisable = (row: any) => () => disableHandler && disableHandler(row);

    const handleDownload = (row: any) => () => downloadHandler && downloadHandler(row);

    const renderCell = (row: any, column: any) => (
        <TableCell
            key={`${row._id}-${column.field}`}
            align="center"
            className={cx({
                'ac2-icon-cell': column.icon,
                'ac2-text-cell--laptop': !column.icon
            })}
        >
            {column.icon ? column.icon[row[column.field]] : row[column.field] || column.default}
        </TableCell>
    );

    return (
        <Table aria-label={ariaLabel || ''} sx={{ tableLayout: 'fixed' }}>
            <TableHead className="ac2-table-header">
                <TableRow>
                    {columns.map((column) => (
                        <TableCell
                            key={column.field}
                            align="center"
                            className="ac2-table__font--header"
                        >
                            {column.header}
                        </TableCell>
                    ))}
                    {hasOptions && (
                        <TableCell align="center" className="ac2-table__font--header">
                            {download ? TABLE.ALT_ACTION : TABLE.ACTIONS}
                        </TableCell>
                    )}
                    {details && (
                        <TableCell align="center" className="ac2-table__font--header">
                            {detailsLabel}
                        </TableCell>
                    )}
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row, index) => (
                    <TableRow key={`${row._id}_${index}`} className="ac2-table__row--division">
                        {columns.map((column) => renderCell(row, column))}
                        {hasOptions && (
                            <TableCell>
                                <TableOptions
                                    rowId={row._id}
                                    edit={edit || false}
                                    info={info || false}
                                    disable={disable || false}
                                    download={download || false}
                                    infoHandler={handleInfo(row)}
                                    editHandler={handleUpdate(row)}
                                    disableHandler={handleDisable(row)}
                                    disableConfirmationMessage={disableConfirmationMessage}
                                    downloadHandler={handleDownload(row)}
                                />
                            </TableCell>
                        )}
                        {details && (
                            <TableCell align="center" className="ac2-table__font--fields">
                                {row[idField] === undefined ? (
                                    TABLE.NO_ASSET_DETAILS
                                ) : (
                                    <RowDetails
                                        url={detailsPath || ''}
                                        idParam={row[idField]}
                                        page={page || 1}
                                        searchParam={searchParam || ''}
                                    />
                                )}
                            </TableCell>
                        )}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default WebTable;
