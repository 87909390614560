import { API_ROUTES, HTTP_METHODS, PAGE_SIZE } from '@Utils/constants';
import { sendCall } from '@Utils/requestPrep';

const { ALERT } = API_ROUTES;

class AlertService {
    getAlerts = () => sendCall(ALERT.GET, { method: HTTP_METHODS.GET });

    updateAlerts = (page, pagination) =>
        sendCall(`${ALERT.UPDATE}?page=${page}&limit=${PAGE_SIZE}&pagination=${pagination}`, {
            method: HTTP_METHODS.GET
        });

    hideAlert = (id) => sendCall(`${ALERT.HIDE}/${id}`, { method: HTTP_METHODS.PUT });

    viewAlert = (id) => sendCall(`${ALERT.VIEW}/${id}`, { method: HTTP_METHODS.PUT });
}

export default new AlertService();
