import React from 'react';

import { Box, Step, StepLabel, Stepper } from '@mui/material';
import cx from 'classnames';
import PropTypes from 'prop-types';
import './Stepper.scss';
import { StepperProps } from './Stepper.type';

const SetupStepper = ({ activeStep, alternativeLabel, steps }: StepperProps) => {
    return (
        <Box>
            <Stepper
                activeStep={activeStep}
                className={cx('ac2-setup-stepper')}
                alternativeLabel={alternativeLabel}
            >
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
};

export default SetupStepper;
