import { API_ROUTES, HTTP_METHODS, PAGE_SIZE } from '@Utils/constants';
import { sendCall } from '@Utils/requestPrep';

const { ASSET_LOG } = API_ROUTES;

class AssetLogService {
    getAssetLog = (id) => sendCall(`${ASSET_LOG.GET}/${id}`, { method: HTTP_METHODS.GET });

    getAssetLogs = (page, pagination) =>
        sendCall(`${ASSET_LOG.GET}?page=${page}&limit=${PAGE_SIZE}&pagination=${pagination}`, {
            method: HTTP_METHODS.GET
        });

    // Change to correct search
    getAssetLogsSearch = (params, page) =>
        sendCall(`${ASSET_LOG.SEARCH_POST}?page=${page}&limit=${PAGE_SIZE}`, {
            data: params,
            method: HTTP_METHODS.POST
        });
}

export default new AssetLogService();
