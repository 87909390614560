import { SETTINGS_CARDS } from '@Utils/constants';
import { LABELS } from '@Utils/labels';
import { Box, Grid } from '@mui/material';
import './Settings.scss';

import SettingsCard from './SettingsCard';

const Settings = () => (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid item xs={12}>
            <h1 className="ac2-title">{LABELS.SETTINGS.MENU_LABEL}</h1>
        </Grid>
        <Grid item xs={12}>
            <Box className="ac2-settings-menu-box">
                <Box className="ac2-grid">
                    {SETTINGS_CARDS.map((cardInfo) => (
                        <SettingsCard
                            name={cardInfo.name}
                            path={cardInfo.path}
                            image={`${cardInfo.image}`}
                            alt={cardInfo.name}
                            key={`${cardInfo.name}-card`}
                        />
                    ))}
                </Box>
            </Box>
        </Grid>
    </Grid>
);

export default Settings;
