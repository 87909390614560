import React, { useContext, useEffect, useState } from 'react';

import EmptyIcon from '@Assets/Images/no-device-found.svg';
import './Assets.scss';
import UserContext from '@Context/userContext';
import AccessoriesServices from '@Services/Accessories.services';
import AssetServices from '@Services/Asset.services';
import CustomButton from '@Shared/Button/Button';
import Loading from '@Shared/Loading/Loading';
import SearchBar from '@Shared/SearchBar/SearchBar';
import ServerError from '@Shared/ServerError/ServerError';
import CustomSnackbar from '@Shared/Snackbar/Snackbar';
import Table from '@Shared/Table/Table';
import Tabs from '@Shared/Tabs/Tabs';
import { showErrorAlert } from '@Utils/alerts';
import { checkRoles } from '@Utils/auth';
import {
    ACCESSORY_COLUMNS,
    ADMIN_ROLES,
    ASSET_COLUMNS,
    EXCEL_EXTENSION,
    EXCEL_FILE_LOCATION,
    EXCEL_FILE_NAME,
    PAGE_PROP,
    PARSE_INT_BASE_NUMBER,
    PATHS,
    SEARCH_PROP,
    TAB_PROP,
    TAB_VALUES
} from '@Utils/constants';
import { LABELS } from '@Utils/labels';
import AddIcon from '@mui/icons-material/Add';
import { AlertColor, Grid, Input } from '@mui/material';
import { useLocation, useSearchParams } from 'react-router-dom';

import { formatAllAssetTag, formatCreateValues, formatExcelData } from './Assets.utils';
import AssignAssets from './AssignAssets/AssignAssets';
import AssetInputModal from './Modals/AssetInputModal';
import { SnackBarProps } from '@Shared/Snackbar/Snackbar.type';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import readXlsxFile from 'read-excel-file';
import AssetUploadModal from '@Components/Assets/Modals/AssetUploadModal';
import { ExcelAsset } from '@Types/Asset.types';
import DownloadButton from '@Shared/DownloadButton/DownloadButton';

const { ALERTS, ASSETS, EMPTY_MESSAGES, ACCESSORIES, UPLOAD_ASSET } = LABELS;

const Assets = () => {
    const { user } = useContext(UserContext);
    const [createModalIsOpen, setCreateModalIsOpen] = useState<boolean>(false);
    const [assignModalIsOpen, setAssignModalIsOpen] = useState<boolean>(false);
    const [snackbarIsOpen, setSnackbarIsOpen] = useState<boolean>(false);
    const [areAssetsLoading, setAreAssetsLoading] = useState<boolean>(true);
    const [areAccessoriesLoading, setAreAccessoriesLoading] = useState<boolean>(true);
    const [dataObtainFailed, setDataObtainFailed] = useState<boolean>(false);
    const [snackbar, setSnackbar] = useState<SnackBarProps>();
    const [assets, setAssets] = useState<any>();
    const [accessories, setAccessories] = useState<any>();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState<number>(
        searchParams.has(PAGE_PROP) ? parseInt(searchParams.get(PAGE_PROP) || '', 10) : 1
    );
    const [selectedTab, setSelectedTab] = useState(
        parseInt(searchParams.get(TAB_PROP) || '', 10) || TAB_VALUES.ASSETS
    );
    const [showAssetUpload, setShowAssetUpload] = useState<boolean>(false);
    const [excelData, setExcelData] = useState<Partial<ExcelAsset>[]>([]);

    const setAlert = (message: string, severity = ALERTS.SEVERITIES.SUCCESS) => {
        setSnackbar({ alertMessage: message, alertSeverity: severity as AlertColor });
        setSnackbarIsOpen(true);
    };

    const handleExcelUpload = (event: any) => {
        readXlsxFile(event.target.files[0])
            .then((rows) => {
                const data = formatExcelData(rows);
                setExcelData(data);
                setShowAssetUpload(true);
            })
            .catch((err) => {
                setAlert(UPLOAD_ASSET.ERROR.READ, ALERTS.SEVERITIES.ERROR);
                console.log(err);
            });
    };

    useEffect(() => {
        if (!searchParams.get(SEARCH_PROP)) {
            refreshData();
        }
    }, []);

    useEffect(() => {
        const searchValue = { search: searchParams.get(SEARCH_PROP) };
        if (selectedTab === TAB_VALUES.ASSETS) {
            setAreAssetsLoading(true);
            if (searchValue.search) {
                AssetServices.getAssetsSearch(searchValue, currentPage)
                    .then((assetData) => {
                        setAssets(assetData.data);
                    })
                    .catch(({ response }) => {
                        showErrorAlert(setAlert, response);
                        setDataObtainFailed(true);
                    })
                    .finally(() => {
                        setAreAssetsLoading(false);
                    });
            } else {
                AssetServices.getAssets(currentPage, true)
                    .then((assetData) => {
                        setAssets(assetData.data);
                    })
                    .catch(({ response }) => {
                        showErrorAlert(setAlert, response);
                        setDataObtainFailed(true);
                    })
                    .finally(() => {
                        setAreAssetsLoading(false);
                    });
            }
        }
        if (selectedTab === TAB_VALUES.ACCESSORIES) {
            setAreAccessoriesLoading(true);
            if (searchValue.search) {
                AccessoriesServices.searchAccessories(searchValue, currentPage)
                    .then((accessoryData) => {
                        setAccessories(accessoryData.data);
                    })
                    .catch(({ response }) => {
                        showErrorAlert(setAlert, response);
                        setDataObtainFailed(true);
                    })
                    .finally(() => {
                        setAreAccessoriesLoading(false);
                    });
            } else {
                AccessoriesServices.getAccessories(currentPage, true)
                    .then((accessoryData) => {
                        setAccessories(accessoryData.data);
                    })
                    .catch(({ response }) => {
                        showErrorAlert(setAlert, response);
                        setDataObtainFailed(true);
                    })
                    .finally(() => {
                        setAreAccessoriesLoading(false);
                    });
            }
        }
    }, [currentPage]);

    useEffect(() => {
        if (!areAssetsLoading) {
            if (selectedTab === TAB_VALUES.ACCESSORIES) {
                setAreAssetsLoading(true);
                AssetServices.getAssets(currentPage, true)
                    .then((response) => {
                        setAssets(response.data);
                    })
                    .catch(({ response }) => {
                        showErrorAlert(setAlert, response);
                        setDataObtainFailed(true);
                    })
                    .finally(() => {
                        setAreAssetsLoading(false);
                    });
            }
            if (selectedTab === TAB_VALUES.ASSETS) {
                setAreAccessoriesLoading(true);
                AccessoriesServices.getAccessories(currentPage, true)
                    .then((response) => {
                        setAccessories(response.data);
                    })
                    .catch(({ response }) => {
                        showErrorAlert(setAlert, response);
                        setDataObtainFailed(true);
                    })
                    .finally(() => {
                        setAreAccessoriesLoading(false);
                    });
            }
        }
    }, [selectedTab]);

    useEffect(() => {
        if (searchParams.get(PAGE_PROP)) {
            setCurrentPage(parseInt(searchParams.get(PAGE_PROP) || '', PARSE_INT_BASE_NUMBER));
        }
    }, [location]);

    const handleSnackbarClose = () => {
        setSnackbarIsOpen(false);
    };

    const toggleCreateModal = () => {
        setCreateModalIsOpen(!createModalIsOpen);
    };

    const toggleAssignModal = () => {
        setAssignModalIsOpen(!assignModalIsOpen);
    };

    const createSubmitHandler = (assetFormData: any) => AssetServices.createAsset(assetFormData);

    const onCreationSuccess = (asset: any) => {
        setAreAssetsLoading(true);
        refreshData();
        setCurrentPage(1);
    };

    const onAssignSuccess = (id: string, owner: any) => {
        const updatedData = assets.map((asset: any) => {
            if (asset._id === id) {
                const newAssigment = {
                    assignmentDate: Date(),
                    employeeEmail: owner
                };
                asset.assignments.push(newAssigment);
                asset.owner = owner;
            }
            return asset;
        });
        setAssets(updatedData);
        const previousPage = currentPage;
        setCurrentPage(previousPage);
    };

    const onAssetSearch = (values: any[]) => {
        setAssets(values || []);
    };

    const onAccessorySearch = (values: any[]) => {
        setAccessories(values || []);
    };

    const renderAssetTable = (loading: boolean, failed: boolean) => {
        if (loading) {
            return <Loading />;
        }
        if (failed) {
            return <ServerError />;
        }
        return (
            <Grid item xs={12} sm={12} md={12}>
                <Table
                    columns={ASSET_COLUMNS}
                    rows={formatAllAssetTag(assets.docs)}
                    details
                    detailsLabel={ASSETS.COLUMNS.DETAILS}
                    page={currentPage}
                    updatePage={setCurrentPage}
                    emptyMessage={EMPTY_MESSAGES.ASSETS}
                    emptyIcon={EmptyIcon}
                    detailsPath={PATHS.ASSETS}
                    idField="_id"
                    itemCount={assets.totalPages}
                    searchParam={searchParams.get(SEARCH_PROP) || ''}
                />
            </Grid>
        );
    };

    const renderAccessoryTable = (loading: boolean, failed: boolean) => {
        if (loading) {
            return <Loading />;
        }
        if (failed) {
            return <ServerError />;
        }
        return (
            <Grid item xs={12} sm={12} md={12}>
                <Table
                    columns={ACCESSORY_COLUMNS}
                    rows={accessories.docs}
                    details
                    detailsLabel={ACCESSORIES.COLUMNS.DETAILS}
                    page={currentPage}
                    updatePage={setCurrentPage}
                    emptyMessage={EMPTY_MESSAGES.ACCESSORIES}
                    emptyIcon={EmptyIcon}
                    detailsPath={PATHS.ACCESSORIES}
                    idField="_id"
                    searchParam={searchParams.get(SEARCH_PROP) || ''}
                    itemCount={accessories.totalPages}
                />
            </Grid>
        );
    };

    const tabs = [
        {
            label: 'Assets',
            content: renderAssetTable(areAssetsLoading, dataObtainFailed),
            value: TAB_VALUES.ASSETS
        },
        {
            label: 'Accessories',
            content: renderAccessoryTable(areAccessoriesLoading, dataObtainFailed),
            value: TAB_VALUES.ACCESSORIES
        }
    ];

    const handleTabChange = (value: any) => {
        setCurrentPage(1);
        setSearchParams({ page: '1', tab: value });
        setSelectedTab(value);
    };

    const closeExcelAssetModal = () => {
        setShowAssetUpload(false);
        setExcelData([]);
    };

    const refreshData = () => {
        Promise.all([
            AssetServices.getAssets(currentPage, true),
            AccessoriesServices.getAccessories(currentPage, true)
        ])
            .then(([assetData, accessoryData]) => {
                setAssets(assetData.data);
                setAccessories(accessoryData.data);
            })
            .catch(({ response }) => {
                showErrorAlert(setAlert, response);
                setDataObtainFailed(true);
            })
            .finally(() => {
                setAreAssetsLoading(false);
                setAreAccessoriesLoading(false);
            });
    };

    return (
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={12}>
                <h1 className="ac2-title">{LABELS.ASSETS.TITTLE}</h1>
            </Grid>
            {checkRoles(ADMIN_ROLES, user) && (
                <Grid container item xs={12} justifyContent="space-between" alignItems="center">
                    <Grid item xs={12} sm={6} md={6} sx={{ display: 'flex' }}>
                        <CustomButton
                            label={LABELS.CREATE_ASSET.NEW_BUTTON}
                            uiType="secondary"
                            variant="contained"
                            onClick={toggleCreateModal}
                            classes="ac2-button-asset__creation"
                            startIcon={<AddIcon />}
                        />
                        <CustomButton
                            label={LABELS.ASSIGN_ASSETS.ASSIGN_BUTTON}
                            uiType="secondary"
                            variant="contained"
                            onClick={toggleAssignModal}
                            classes="ac2-button-asset__creation"
                        />
                        <CustomButton
                            label={
                                <>
                                    {LABELS.CREATE_ASSET.UPLOAD}
                                    <Input
                                        id="asset-upload"
                                        type="file"
                                        inputProps={{
                                            multiple: false,
                                            accept: EXCEL_EXTENSION
                                        }}
                                        style={{ display: 'none' }}
                                        onChange={handleExcelUpload}
                                    />
                                </>
                            }
                            variant="contained"
                            uiType="secondary"
                            classes="ac2-button-asset__creation"
                            startIcon={<FileUploadIcon />}
                            component="label"
                        />
                        <DownloadButton filename={EXCEL_FILE_NAME} href={EXCEL_FILE_LOCATION} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        {selectedTab === TAB_VALUES.ASSETS && (
                            <SearchBar
                                setSearchResults={onAssetSearch}
                                searchService={(param) =>
                                    AssetServices.getAssetsSearch(param, currentPage)
                                }
                                setIsLoading={setAreAssetsLoading}
                                setServerError={setDataObtainFailed}
                            />
                        )}
                        {selectedTab === TAB_VALUES.ACCESSORIES && (
                            <SearchBar
                                setSearchResults={onAccessorySearch}
                                searchService={(param) =>
                                    AccessoriesServices.searchAccessories(param, currentPage)
                                }
                                setIsLoading={setAreAccessoriesLoading}
                                setServerError={setDataObtainFailed}
                            />
                        )}
                    </Grid>
                </Grid>
            )}
            <Tabs tabs={tabs} selectedTabValue={selectedTab} updateTab={handleTabChange} />
            {createModalIsOpen && (
                <AssetInputModal
                    title={LABELS.CREATE_ASSET.TITLE}
                    modalIsOpen={createModalIsOpen}
                    handleClose={toggleCreateModal}
                    setAlert={setAlert}
                    formatter={formatCreateValues}
                    onSubmit={createSubmitHandler}
                    onSuccess={onCreationSuccess}
                    successMessage={LABELS.CREATE_ASSET.ALERT_MESSAGES.SUCCESS}
                />
            )}
            {assignModalIsOpen && (
                <AssignAssets
                    modalIsOpen={assignModalIsOpen}
                    toogleModal={setAssignModalIsOpen}
                    setAlert={setAlert}
                    onSuccess={onAssignSuccess}
                    setIsLoading={setAreAssetsLoading}
                    successMessage={LABELS.ASSIGN_ASSETS.ALERT_MESSAGES.SUCCESS}
                    failureMessage={LABELS.ASSIGN_ASSETS.ALERT_MESSAGES.FAILURE}
                />
            )}
            {snackbar && (
                <CustomSnackbar
                    isOpen={snackbarIsOpen}
                    handleClose={handleSnackbarClose}
                    alertSeverity={snackbar.alertSeverity}
                    alertMessage={snackbar.alertMessage}
                />
            )}
            <AssetUploadModal
                data={excelData}
                isOpen={showAssetUpload}
                onClose={closeExcelAssetModal}
                setAlert={setAlert}
                onCreate={onCreationSuccess}
            />
        </Grid>
    );
};

export default Assets;
