export const formatAssetType = (result: any) =>
    result.data.map((fetchedAssetType: any) => ({
        id: fetchedAssetType._id,
        description: fetchedAssetType.description,
        assetCode: fetchedAssetType.assetCode
    }));

export const formatReaderServiceStatus = (result: any) =>
    result.data.map((amountAssetsStatus: any) => {
        amountAssetsStatus.status = amountAssetsStatus.status.toLowerCase();
        let wordsSeparatedArray = amountAssetsStatus.status.split(' ');
        wordsSeparatedArray = wordsSeparatedArray.map(
            (word: string) => word[0].toUpperCase() + word.slice(1)
        );
        amountAssetsStatus.status = wordsSeparatedArray.join(' ');
        return amountAssetsStatus;
    });

export const formatReaderServiceStatusWeeklyCount = (
    result: any,
    searchStatus: string,
    searchOfficeName: string
) => {
    const searchedStatusData = result.data.find(
        (amountAssetsStatus: any) =>
            amountAssetsStatus.status === searchStatus &&
            amountAssetsStatus.officeName === searchOfficeName
    );

    if (searchedStatusData === undefined) {
        return { status: searchStatus, officeName: searchOfficeName, amount: 0 };
    }

    return {
        status: searchedStatusData._id,
        officeName: searchedStatusData.officeName,
        amount: searchedStatusData.amount
    };
};

export const getNumberOfDays = (start: any) => {
    start = new Date(start);
    const end = new Date();

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = start.getTime() - end.getTime();

    // Return the no. of days between two dates
    return Math.round(diffInTime / oneDay);
};

export const isNextDay = (storedAlerts: any) => {
    if (storedAlerts && storedAlerts.length > 0) {
        return getNumberOfDays(storedAlerts[0].date) >= 1;
    }
    return false;
};
