import { API_ROUTES, HTTP_METHODS, PAGE_SIZE } from '@Utils/constants';
import { sendCall } from '@Utils/requestPrep';

const { REPORT } = API_ROUTES;

class ReportService {
    createReport = (params) =>
        sendCall(REPORT.POST, {
            data: params,
            method: HTTP_METHODS.POST
        });

    getReports = (page, pagination) =>
        sendCall(`${REPORT.GET}?page=${page}&limit=${PAGE_SIZE}&pagination=${pagination}`, {
            method: HTTP_METHODS.GET
        });

    getReportsSearch = (params, page) =>
        sendCall(`${REPORT.SEARCH_POST}?page=${page}&limit=${PAGE_SIZE}`, {
            data: params,
            method: HTTP_METHODS.POST
        });

    getReport = (id) => sendCall(`${REPORT.GET}/${id}`, { method: HTTP_METHODS.GET });

    getExcel = (id) =>
        sendCall(`${REPORT.EXCEL}/${id}`, {
            method: HTTP_METHODS.GET,
            responseType: 'blob'
        });

    getReportPageData = (id, page) =>
        sendCall(`${REPORT.PAGE_DATA}/${id}?page=${page}&limit=${PAGE_SIZE}`, {
            method: HTTP_METHODS.GET
        });
}

export default new ReportService();
