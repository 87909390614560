import React, { useEffect, useState } from 'react';

import EmptyIcon from '@Assets/Images/empty-admins.svg';
import AdminService from '@Services/Admin.services';
import CustomButton from '@Shared/Button/Button';
import Loading from '@Shared/Loading/Loading';
import SearchBar from '@Shared/SearchBar/SearchBar';
import ServerError from '@Shared/ServerError/ServerError';
import CustomSnackbar from '@Shared/Snackbar/Snackbar';
import Table from '@Shared/Table/Table';
import { showErrorAlert } from '@Utils/alerts';
import {
    ADMIN_COLUMNS,
    CR_OFFICE,
    FIRST_PAGE_NUMBER,
    GRID_BREAKPOINTS,
    PAGE_PROP,
    PARSE_INT_BASE_NUMBER,
    PATHS,
    SEARCH_PROP,
    TAB_PROP,
    TAB_VALUES,
    USER_COLUMNS
} from '@Utils/constants';
import { LABELS } from '@Utils/labels';
import './Users.scss';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { AlertColor, Grid } from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import AdminModal from './AdminModal';
import { DEFAULT_ADMIN_MODAL_VALUES } from './AdminModal.utils';
import { SnackBarProps } from '@Shared/Snackbar/Snackbar.type';
import { useQuery } from '@apollo/client';
import GET_CR_EMPLOYEES from '@Graph/Queries/user';
import Tabs from '@Shared/Tabs/Tabs';
import { KGUser } from '@Types/User.type';

const { USERS, ALERTS, BUTTONS, EMPTY_MESSAGES } = LABELS;
const PageTitle = USERS.COMPONENT_NAME;

const Users = () => {
    const navigate = useNavigate();
    const [admins, setAdmins] = useState<any>({ docs: [], totalPages: 0 });
    const [adminsAreLoading, setAdminsAreLoading] = useState<boolean>(true);
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [snackbarIsOpen, setSnackbarIsOpen] = useState<boolean>(false);
    const [snackbar, setSnackbar] = useState<SnackBarProps>();
    const [dataObtainFailed, setDataObtainFailed] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [userData, setUserData] = useState<any>();
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState<number>(
        searchParams.has(PAGE_PROP) ? parseInt(searchParams.get(PAGE_PROP) || '', 10) : 1
    );
    const [selectedTab, setSelectedTab] = useState(
        parseInt(searchParams.get(TAB_PROP) || '', 10) || TAB_VALUES.USERS
    );
    const location = useLocation();
    const [userSearch, setUserSearch] = useState<KGUser[] | undefined>(undefined);

    const { loading, error, data } = useQuery(GET_CR_EMPLOYEES, {
        variables: { office: CR_OFFICE }
    });

    const showAlert = (message: string, severity: string) => {
        setSnackbar({
            alertMessage: message,
            alertSeverity: (severity || ALERTS.SEVERITIES.SUCCESS) as AlertColor
        });
        setSnackbarIsOpen(true);
    };

    const getAdmins = () => {
        setAdminsAreLoading(true);
        setDataObtainFailed(false);
        AdminService.getAdmins(currentPage, true)
            .then(({ data }) => {
                setAdmins(data);
            })
            .catch(({ response }) => {
                setSnackbarIsOpen(true);
                showErrorAlert(showAlert, response);

                setDataObtainFailed(true);
            })
            .finally(() => {
                setAdminsAreLoading(false);
            });
    };

    useEffect(() => {
        if (!searchParams.get(SEARCH_PROP)) {
            getAdmins();
        }
    }, [currentPage]);

    useEffect(() => {
        if (searchParams.get(PAGE_PROP)) {
            setCurrentPage(parseInt(searchParams.get(PAGE_PROP) || '', PARSE_INT_BASE_NUMBER));
        }
    }, [location]);

    const toggleModalOpen = () => {
        setIsEditing(false);
        setUserData(DEFAULT_ADMIN_MODAL_VALUES);
        setModalIsOpen(!modalIsOpen);
    };

    const onAdminCreated = () => {
        getAdmins();
    };

    const handleSnackbarClose = () => {
        setSnackbarIsOpen(false);
    };

    const disableHandler = (props: any) => {
        setAdminsAreLoading(true);
        setDataObtainFailed(false);
        AdminService.disableAdmin(props._id)
            .then(() => {
                const previousPage = currentPage;
                getAdmins();
                setCurrentPage(previousPage);
                showAlert(USERS.DISABLE_ADMIN_SUCCESS, ALERTS.SEVERITIES.SUCCESS);
            })
            .catch(({ response }) => {
                showErrorAlert(showAlert, response);
            })
            .finally(() => {
                setAdminsAreLoading(false);
            });
    };

    const editHandler = (props: any) => {
        setUserData(props);
        setModalIsOpen(true);
        setIsEditing(true);
    };

    const onAdminSearch = (values: any[]) => {
        if (values.length === 0) {
            showAlert(LABELS.USERS.NO_RESULTS, ALERTS.SEVERITIES.INFO);
        }
        setAdmins(values);
    };

    const onUserSearch = (values: any[]) => {
        setUserSearch(values);
    };

    const handleUserSearch = async (param: any) => {
        if (param.search) {
            const keyword = param.search.toLowerCase();
            const nameSearch = data.peopleV2.filter((user: KGUser) => {
                const name = user.fullName.toLowerCase();
                return name.indexOf(keyword) > -1;
            });
            return { data: nameSearch };
        }
        return { data: undefined };
    };

    const clickHandler = () => {
        navigate(`${PATHS.ADMIN_LOG}?${PAGE_PROP}=${FIRST_PAGE_NUMBER}`);
    };

    const renderUserTable = () => {
        if (loading) {
            return <Loading />;
        }
        if (error) {
            return <ServerError />;
        }
        return (
            <Grid item xs={12} sm={12} md={12}>
                <Table
                    columns={USER_COLUMNS}
                    rows={userSearch ? userSearch : data.peopleV2}
                    page={currentPage}
                    updatePage={setCurrentPage}
                    emptyMessage={EMPTY_MESSAGES.USERS}
                    emptyIcon={EmptyIcon}
                    idField={'id'}
                    details
                    detailsLabel={USERS.ROWS.DETAILS}
                    detailsPath={PATHS.USERS}
                />
            </Grid>
        );
    };

    const renderAdminTable = (loading: boolean, failed: boolean) => {
        if (loading) {
            return <Loading />;
        }
        if (failed) {
            return <ServerError />;
        }
        return (
            <Grid item xs={12} sm={12} md={12}>
                <Table
                    columns={ADMIN_COLUMNS}
                    rows={admins.docs}
                    disable
                    disableHandler={disableHandler}
                    disableConfirmationMessage={USERS.DISABLE_CONFIRMATION}
                    edit
                    editHandler={editHandler}
                    page={currentPage}
                    updatePage={setCurrentPage}
                    emptyMessage={EMPTY_MESSAGES.ADMIN}
                    emptyIcon={EmptyIcon}
                    itemCount={admins.totalPages}
                />
            </Grid>
        );
    };

    const tabs = [
        {
            label: 'Users',
            content: renderUserTable(),
            value: TAB_VALUES.USERS
        },
        {
            label: 'Admins',
            content: renderAdminTable(adminsAreLoading, dataObtainFailed),
            value: TAB_VALUES.ADMINS
        }
    ];

    const handleTabChange = (value: any) => {
        setCurrentPage(1);
        setSearchParams({ page: '1', tab: value });
        setSelectedTab(value);
    };

    return (
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
            {modalIsOpen && (
                <AdminModal
                    creationHandler={onAdminCreated}
                    modalIsOpen={modalIsOpen}
                    handleClose={toggleModalOpen}
                    editing={isEditing}
                    userInfo={userData}
                    showAlert={showAlert}
                />
            )}
            <Grid item xs={12} sm={12} md={12}>
                <h1 className="ac2-title">{PageTitle}</h1>
            </Grid>
            <Grid item xs={GRID_BREAKPOINTS.XS} sm={GRID_BREAKPOINTS.SM} md={GRID_BREAKPOINTS.MD}>
                <CustomButton
                    uiType="secondary"
                    onClick={toggleModalOpen}
                    label={BUTTONS.NEW}
                    variant="contained"
                    classes="ac2-button-new"
                    startIcon={<AddOutlinedIcon />}
                />
                <CustomButton
                    label={USERS.ADMIN_LOG_BUTTON}
                    uiType="secondary"
                    variant="contained"
                    onClick={clickHandler}
                />
            </Grid>
            <Grid item xs={GRID_BREAKPOINTS.XS} sm={GRID_BREAKPOINTS.SM} md={GRID_BREAKPOINTS.MD}>
                {selectedTab === TAB_VALUES.USERS && (
                    <SearchBar
                        setSearchResults={onUserSearch}
                        setIsLoading={setAdminsAreLoading}
                        searchService={handleUserSearch}
                        setServerError={setDataObtainFailed}
                    />
                )}
                {selectedTab === TAB_VALUES.ADMINS && (
                    <SearchBar
                        setSearchResults={onAdminSearch}
                        setIsLoading={setAdminsAreLoading}
                        searchService={(param) => AdminService.getAdminSearch(param, currentPage)}
                        setServerError={setDataObtainFailed}
                    />
                )}
            </Grid>
            <Tabs tabs={tabs} selectedTabValue={selectedTab} updateTab={handleTabChange} />
            {snackbar && (
                <CustomSnackbar
                    isOpen={snackbarIsOpen}
                    handleClose={handleSnackbarClose}
                    alertMessage={snackbar.alertMessage}
                    alertSeverity={snackbar.alertSeverity}
                />
            )}
        </Grid>
    );
};

export default Users;
