import { useEffect, useState } from 'react';

import CustomButton from '@Shared/Button/Button';
import FormInputCheckbox from '@Shared/Form/FormInputCheckbox';
import FormInputDropdown from '@Shared/Form/FormInputDropdown';
import FormInputMultiTag from '@Shared/Form/FormInputMultiTag';
import FormInputText from '@Shared/Form/FormInputText';
import {
    AUTO_COMPLETE_STYLE,
    FILTER_TYPE,
    LIMIT_TAG,
    REPORT_DATA_RADIO_BUTTON
} from '@Utils/constants';
import { LABELS } from '@Utils/labels';
import CheckIcon from '@mui/icons-material/Check';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { Grid } from '@mui/material';
import { isArray, isObject } from 'lodash';
import { useForm } from 'react-hook-form';

import './ReportInputModal.scss';
import { DATE_NAMES, FIELD_NAMES, FILTERS_OPTIONS, getSimpleDate } from '../Reports.utils';
import { FilterInfoProps, SelectFiltersProps, SelectedOptionProps } from './NewReport.type';

const { IS_REQUIRED, REPORTS } = LABELS;

const SelectFilters = ({
    filterInfo,
    allInfo,
    filterType,
    previousHandler,
    cancelHandler
}: SelectFiltersProps) => {
    const [selectedOption, setSelectedOption] = useState<SelectedOptionProps>();
    const [selectedInfo, setSelectedInfo] = useState<any[]>([]);
    const [filterOptions, setFilterOptions] = useState(FILTERS_OPTIONS);
    const [checkArray, setCheckArray] = useState<any[]>([]);
    const [maxStartDate, setMaxStartDate] = useState(getSimpleDate());
    const [minEndDate, setMinEndDate] = useState(getSimpleDate());

    const {
        handleSubmit,
        control,
        getValues,
        watch,
        setValue,
        resetField,
        formState: { errors }
    } = useForm<FilterInfoProps>({
        defaultValues: filterInfo
    });

    const filterOptionState = watch(FIELD_NAMES.FILTER_OPTION as keyof FilterInfoProps);

    const startDateState =
        selectedOption?.type === FILTER_TYPE.MULTI_DATE
            ? watch(
                  `${FIELD_NAMES[selectedOption.name]}${
                      DATE_NAMES.START_DATE
                  }` as keyof FilterInfoProps
              )
            : '';

    const endDateState =
        selectedOption?.type === FILTER_TYPE.MULTI_DATE
            ? watch(
                  `${FIELD_NAMES[selectedOption.name]}${
                      DATE_NAMES.END_DATE
                  }` as keyof FilterInfoProps
              )
            : '';

    useEffect(() => {
        setCheckArray([]);
        setValue(FIELD_NAMES.FILTER_OPTION as keyof FilterInfoProps, FILTERS_OPTIONS[0].name);
        if (filterType === REPORT_DATA_RADIO_BUTTON[0].VALUE) {
            setFilterOptions(
                FILTERS_OPTIONS.filter((options) => options.label !== REPORTS.MODAL.LOGGED_AT)
            );
        }
        if (filterType === REPORT_DATA_RADIO_BUTTON[1].VALUE) {
            setFilterOptions(FILTERS_OPTIONS.filter((options) => options.log));
        }
    }, []);

    useEffect(() => {
        if (selectedOption) {
            const field = FIELD_NAMES[selectedOption?.name] as keyof FilterInfoProps;
            if (isObject(field) && !isArray(field)) {
                resetField(`${field}.${DATE_NAMES.START_DATE}` as keyof FilterInfoProps);
                resetField(`${field}.${DATE_NAMES.END_DATE}` as keyof FilterInfoProps);
            } else {
                resetField(field);
            }
        }

        const fieldValue = getValues(FIELD_NAMES.FILTER_OPTION as keyof FilterInfoProps);
        if (fieldValue !== REPORTS.MODAL.FILTERS.DEFAULT) {
            const actualFilter = filterOptions.find((option) => option.name === fieldValue);

            setSelectedOption(actualFilter as SelectedOptionProps);

            if (actualFilter?.type === FILTER_TYPE.CHECKBOX_GROUP) {
                setSelectedInfo(allInfo.filter((info) => actualFilter.name === info.name));
            }

            if (actualFilter?.type === FILTER_TYPE.CHECKBOX_GROUP) {
                setSelectedInfo(allInfo.filter((info) => actualFilter.name === info.name));
            }
        }
    }, [filterOptionState]);

    useEffect(() => {
        setMaxStartDate(endDateState);
        setMinEndDate(startDateState);
    }, [startDateState, endDateState]);

    const onHandleCheck = (event: any, checkValue: any, onChange: any) => {
        if (checkArray.includes(checkValue)) {
            checkArray.splice(checkArray.indexOf(checkValue), 1);
        } else {
            checkArray.push(checkValue);
        }
        onChange(checkArray);
    };

    const backHandler = () => {
        if (selectedOption) {
            const field = FIELD_NAMES[selectedOption?.name];
            filterInfo[field as keyof FilterInfoProps] = checkArray;
            previousHandler(control._formValues);
        }
    };

    return (
        <form onSubmit={handleSubmit(backHandler)}>
            <Grid container direction="column" rowSpacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                    <FormInputDropdown
                        name={FIELD_NAMES.FILTER_OPTION.toString()}
                        control={control}
                        label={REPORTS.MODAL.TITLES.FILTERS}
                        fullWidth
                        classname="list"
                        options={filterOptions}
                        idField="name"
                        valueField="label"
                        condition="disabled"
                        rules={{}}
                    />
                </Grid>
                {selectedOption && (
                    <Grid item xs={12} sm={12} md={12}>
                        {(selectedOption.type === FILTER_TYPE.CHECKBOX && (
                            <FormInputCheckbox
                                name={FIELD_NAMES[selectedOption.name]}
                                control={control}
                                label={selectedOption.label}
                            />
                        )) ||
                            (selectedOption.type === FILTER_TYPE.CHECKBOX_GROUP && (
                                <FormInputCheckbox
                                    name={FIELD_NAMES[selectedOption.name]}
                                    control={control}
                                    group
                                    groupOptions={selectedInfo}
                                    idField="_id"
                                    valueField="description"
                                    onHandleCheck={onHandleCheck}
                                />
                            )) ||
                            (selectedOption.type === FILTER_TYPE.MULTI_TAG && (
                                <>
                                    <p>{REPORTS.MODAL.HELPER_TEXT}</p>
                                    <FormInputMultiTag
                                        multiple
                                        name={FIELD_NAMES[selectedOption.name]}
                                        control={control}
                                        limitTags={LIMIT_TAG}
                                        freeSolo
                                        label=""
                                        setValue={setValue}
                                        style={AUTO_COMPLETE_STYLE}
                                        helper={
                                            errors[
                                                FIELD_NAMES[
                                                    selectedOption.name
                                                ] as keyof FilterInfoProps
                                            ]?.message
                                        }
                                        rules={{
                                            required: `${selectedOption.label} ${IS_REQUIRED}`
                                        }}
                                        defaultValue={''}
                                        options={[]}
                                        renderOptionLabel={undefined}
                                        inputValue={''}
                                    />
                                </>
                            )) ||
                            (selectedOption.type === FILTER_TYPE.DATE && (
                                <FormInputText
                                    name={FIELD_NAMES[selectedOption.name]}
                                    control={control}
                                    label={selectedOption.label || ''}
                                    type="date"
                                    fullWidth
                                    shrink
                                    rules={{}}
                                />
                            )) ||
                            (selectedOption.type === FILTER_TYPE.MULTI_DATE && (
                                <div>
                                    <FormInputText
                                        name={`${FIELD_NAMES[selectedOption.name]}.${
                                            DATE_NAMES.START_DATE
                                        }`}
                                        control={control}
                                        label={REPORTS.MODAL.FILTERS.START_DATE}
                                        baseInput={{
                                            max: maxStartDate
                                        }}
                                        classname="start--date"
                                        type="date"
                                        fullWidth
                                        shrink
                                        rules={{ required: endDateState !== '' }}
                                        helper={REPORTS.MODAL.FILTERS.START_DATE_HELPER}
                                    />
                                    <FormInputText
                                        name={`${FIELD_NAMES[selectedOption.name]}.${
                                            DATE_NAMES.END_DATE
                                        }`}
                                        control={control}
                                        label={REPORTS.MODAL.FILTERS.END_DATE}
                                        baseInput={{
                                            min: minEndDate,
                                            max: getSimpleDate()
                                        }}
                                        type="date"
                                        fullWidth
                                        shrink
                                        rules={{ required: startDateState !== '' }}
                                        helper={REPORTS.MODAL.FILTERS.END_DATE_HELPER}
                                    />
                                </div>
                            ))}
                    </Grid>
                )}
                <Grid item xs={12} sm={12} md={12}>
                    <CustomButton
                        startIcon={<DoDisturbIcon />}
                        label={REPORTS.MODAL.BACK_BUTTON}
                        variant="contained"
                        onClick={cancelHandler}
                        uiType="primary--outlined"
                        classes="ac2-button__cancel--filter"
                    />
                    <CustomButton
                        startIcon={<CheckIcon />}
                        label={REPORTS.MODAL.CONFIRM_BUTTON}
                        variant="contained"
                        type="submit"
                        uiType="secondary"
                    />
                </Grid>
            </Grid>
        </form>
    );
};

export default SelectFilters;
