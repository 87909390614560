import CustomButton from '@Shared/Button/Button';
import FormInputCheckbox from '@Shared/Form/FormInputCheckbox';
import FormInputDropdown from '@Shared/Form/FormInputDropdown';
import FormInputText from '@Shared/Form/FormInputText';
import { VALIDATION_RULES } from '@Utils/constants';
import { LABELS } from '@Utils/labels';
import './AssetModals.scss';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import { FIELD_NAMES } from '../Assets.utils';
import { LocationInfoModalProps } from './AssetInputModal.type';

const LocationInfo = ({
    locationInfo,
    locationsInfo,
    nextHandler,
    previousHandler
}: LocationInfoModalProps) => {
    const {
        handleSubmit,
        control,
        formState: { errors }
    } = useForm({ defaultValues: locationInfo });

    const backHandler = () => {
        previousHandler(control._formValues);
    };

    return (
        <form onSubmit={handleSubmit(nextHandler)} className="ac2-form__info ac2-form__location">
            <Grid container columnSpacing={2} rowSpacing={{ xs: 2, sm: 4, md: 6 }}>
                <Grid item xs={12} sm={12} md={12}>
                    <FormInputDropdown
                        name={FIELD_NAMES.OFFICE_ID}
                        control={control}
                        label={LABELS.CREATE_ASSET.LOCATION_INFO.ASSET_LOCATION.OFFICE}
                        classname="office"
                        options={locationsInfo}
                        idField="id"
                        valueField="name"
                        defaultValue={locationInfo.officeId}
                        helper={errors[FIELD_NAMES.OFFICE_ID]?.message}
                        rules={VALIDATION_RULES.LOCATION_INFO_RULES.OFFICE}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <FormInputText
                        name={FIELD_NAMES.LOCATION_DETAIL}
                        control={control}
                        label={LABELS.CREATE_ASSET.LOCATION_INFO.ASSET_LOCATION.LOCATION_DETAILS}
                        classname="location-details"
                        type="text"
                        multiline
                        rows={4}
                        helper={errors[FIELD_NAMES.LOCATION_DETAIL]?.message}
                        rules={VALIDATION_RULES.LOCATION_INFO_RULES.DETAILS}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <FormInputCheckbox
                        name={FIELD_NAMES.IN_OFFICE}
                        control={control}
                        label={LABELS.CREATE_ASSET.LOCATION_INFO.IN_OFFICE}
                        classname="in-office"
                    />
                </Grid>
            </Grid>
            <CustomButton
                variant="contained"
                label={LABELS.CREATE_ASSET.BACK_BUTTON}
                onClick={backHandler}
                uiType="secondary"
                classes="ac2-button__back"
                startIcon={<ArrowBackIcon />}
            />
            <CustomButton
                variant="contained"
                label={LABELS.CREATE_ASSET.NEXT_BUTTON}
                type="submit"
                uiType="secondary"
                classes="ac2-button__next"
                endIcon={<ArrowForwardIcon />}
            />
        </form>
    );
};

export default LocationInfo;
