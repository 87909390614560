import './Unauthorized.scss';
import UnauthorizedSVG from '@Assets/Images/unauthorized.svg';
import { LABELS } from '@Utils/labels';
import { Grid } from '@mui/material';

const { UNAUTHORIZED } = LABELS;

const Unauthorized = () => (
    <Grid container justifyContent="center" alignItems="center" className="ac2-not-found-container">
        <Grid item xs={12} container justifyContent="center">
            <img
                src={UnauthorizedSVG}
                alt={UNAUTHORIZED.ALT_MESSAGE}
                className="ac2-not-found-img"
            />
        </Grid>

        <Grid item xs={12} container justifyContent="center">
            <h1 className="ac2-error-title">{UNAUTHORIZED.TITLE}</h1>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
            <h2 className="ac2-error-subtitle">{UNAUTHORIZED.SUBTITLE}</h2>
        </Grid>
    </Grid>
);

export default Unauthorized;
