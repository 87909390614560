import { MenuItem, TextField } from '@mui/material';
import cx from 'classnames';
import { Controller } from 'react-hook-form';

import { formatLabel } from './FormInput.utils';

import './FormInput.scss';
import { FormInputDropdownProps } from './FormInput.type';

const FormInputDropdown = ({
    name,
    control,
    classname,
    classes,
    inputClasses,
    label,
    fullWidth,
    options,
    idField = '',
    valueField = '',
    defaultValue,
    helper,
    rules,
    condition = ''
}: FormInputDropdownProps) => {
    const generateSelectOptions = () =>
        options.map((option: any) => (
            <MenuItem
                key={option[idField]}
                value={option[idField]}
                disabled={option[condition] || false}
            >
                {option[valueField]}
            </MenuItem>
        ));

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                    label={formatLabel(label, rules)}
                    className={cx(`ac2-input__${classname}`, 'ac2-form-input', classes)}
                    variant="outlined"
                    value={value}
                    fullWidth={fullWidth}
                    select
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? helper : null}
                    inputProps={{ className: inputClasses }}
                >
                    {options ? generateSelectOptions() : <MenuItem />}
                </TextField>
            )}
            rules={rules}
        />
    );
};

export default FormInputDropdown;
