import { getIdentifier } from '../Assets.utils';

export function formatData(result: any[]) {
    return result.map((fetchedAsset, index) => ({
        id: fetchedAsset._id,
        frag: index + 1,
        assetID: `${getIdentifier(fetchedAsset)} - ${fetchedAsset.assetType} `
    }));
}

export function formatAccessoryData(result: any[]) {
    return result.map((accessory, index) => ({
        id: accessory._id,
        frag: index + 1,
        assetID: accessory.name
    }));
}

export const formatResults = (result: any) => {
    result.owner = result.owner.primaryEmail;
};

export const sortByLetter = (users: any[]) =>
    users
        ? users.map((option) => {
              const firstLetter = option.primaryEmail[0].toUpperCase();
              return {
                  firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
                  ...option
              };
          })
        : [];

export const sortByFirstLetter = (firstTerm: any, secondTerm: any) =>
    -secondTerm.firstLetter.localeCompare(firstTerm.firstLetter);
