import CustomButton from '@Shared/Button/Button';
import CustomTextField from '@Shared/TextField/TextField';
import { VALIDATION_RULES } from '@Utils/constants';
import { EMAIL_DATA, LABELS } from '@Utils/labels';
import CheckIcon from '@mui/icons-material/Check';
import { Grid } from '@mui/material';
import { useForm, useWatch } from 'react-hook-form';
import { SettingsFormProps } from '../../Settings.type';
import KG_LOGO from '@Assets/Images/kg-logo.jpeg';

const { EMAIL, BUTTONS } = LABELS;

const { SUBJECT_FIELD, BODY_FIELD, NAME_FIELD } = EMAIL.FORM;
const { EMAIL_RULES } = VALIDATION_RULES;

const imageStyle = {
    width: '74px',
    height: '74px',
    marginLeft: '0px',
    marginTop: '0px',
    transform: 'rotate(0rad) translateZ(0px)',
    WebkitTransform: 'rotate(0rad) translateZ(0px)'
};

const EmailForm = ({ handlerSubmit, defaultValues }: SettingsFormProps) => {
    const {
        control,
        handleSubmit,
        formState: { errors },
        watch,
        register
    } = useForm();

    const textBodyWatch = useWatch({
        control,
        name: BODY_FIELD.NAME,
        defaultValue: defaultValues[BODY_FIELD.DEFAULT_VALUE]
    });

    const subjectWatch = useWatch({
        control,
        name: SUBJECT_FIELD.NAME,
        defaultValue: defaultValues[SUBJECT_FIELD.DEFAULT_VALUE]
    });

    return (
        <form onSubmit={handleSubmit(handlerSubmit)}>
            <Grid
                item
                xs={12}
                container
                alignItems="center"
                justifyContent="space-between"
                className="ac2-asset-toolbar"
            >
                <Grid container item xs={12} md={5}>
                    <CustomTextField
                        name={NAME_FIELD.NAME}
                        label={NAME_FIELD.LABEL}
                        defaultValue={defaultValues[NAME_FIELD.DEFAULT_VALUE]}
                        disabled
                        control={control}
                        fullWidth
                        classname="field"
                        rules={{}}
                    />
                    <CustomTextField
                        name={SUBJECT_FIELD.NAME}
                        control={control}
                        label={SUBJECT_FIELD.LABEL}
                        helper={errors[SUBJECT_FIELD.NAME]?.message}
                        defaultValue={defaultValues[SUBJECT_FIELD.DEFAULT_VALUE]}
                        fullWidth
                        classname="field"
                        rules={EMAIL_RULES.SUBJECT}
                    />
                    <CustomTextField
                        label={BODY_FIELD.LABEL}
                        name={BODY_FIELD.NAME}
                        control={control}
                        helper={errors[BODY_FIELD.NAME]?.message}
                        defaultValue={defaultValues[BODY_FIELD.DEFAULT_VALUE]}
                        fullWidth
                        classname="field"
                        multiline
                        rows={4}
                        rules={EMAIL_RULES.BODY}
                    />

                    <CustomButton
                        label={BUTTONS.CONFIRMATION}
                        type="submit"
                        variant="contained"
                        uiType="secondary"
                        classes="ac2-button-field"
                        startIcon={<CheckIcon />}
                    />
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    md={6}
                    style={{ overflowY: 'auto', display: 'flex', flexDirection: 'column' }}
                >
                    <h3>{subjectWatch}</h3>
                    <p className="c3" style={{ whiteSpace: 'pre-wrap' }}>
                        {textBodyWatch}
                    </p>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <img alt="" src={KG_LOGO} style={imageStyle} title="" />
                        <div className="c7">{EMAIL_DATA.COMPANIES.KONRAD}</div>
                        <div className="c7">{EMAIL_DATA.LOCATIONS.CR}</div>
                    </div>
                </Grid>
            </Grid>
        </form>
    );
};

export default EmailForm;
