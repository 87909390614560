import './AccessoriesForm.scss';
import CustomButton from '@Shared/Button/Button';
import CustomTextField from '@Shared/TextField/TextField';
import { VALIDATION_RULES } from '@Utils/constants';
import { LABELS } from '@Utils/labels';
import CheckIcon from '@mui/icons-material/Check';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { SettingsFormProps } from '../../Settings.type';

const { ACCESSORIES, BUTTONS } = LABELS;
const { DESCRIPTION } = ACCESSORIES.FORM;
const { ACCESSORY_RULES } = VALIDATION_RULES;

const AccessoriesForm = ({ handlerSubmit, defaultValues }: SettingsFormProps) => {
    const {
        handleSubmit,
        control,
        formState: { errors }
    } = useForm();

    return (
        <form onSubmit={handleSubmit(handlerSubmit)}>
            <Grid container>
                <CustomTextField
                    name={DESCRIPTION.FIELD}
                    control={control}
                    label={DESCRIPTION.LABEL}
                    type="text"
                    fullWidth
                    defaultValue={defaultValues[DESCRIPTION.FIELD]}
                    helper={errors[DESCRIPTION.FIELD]?.message}
                    rules={ACCESSORY_RULES}
                    classname="field"
                />

                <CustomButton
                    label={BUTTONS.CONFIRMATION}
                    type="submit"
                    variant="contained"
                    uiType="secondary"
                    classes="ac2-button-field"
                    startIcon={<CheckIcon />}
                />
            </Grid>
        </form>
    );
};

export default AccessoriesForm;
