import { useEffect, useState } from 'react';

import EmptyIcon from '@Assets/Images/empty-settings.svg';
import AssetTypeServices from '@Services/AssetType.services';
import CustomBreadcrumbs from '@Shared/Breadcrumbs/Breadcrumbs';
import CustomButton from '@Shared/Button/Button';
import Loading from '@Shared/Loading/Loading';
import CustomModal from '@Shared/Modal/Modal';
import SearchBar from '@Shared/SearchBar/SearchBar';
import ServerError from '@Shared/ServerError/ServerError';
import CustomSnackbar from '@Shared/Snackbar/Snackbar';
import CustomTable from '@Shared/Table/Table';
import { showErrorAlert } from '@Utils/alerts';
import { ASSET_TYPE_COLUMNS, SETTINGS_BREADCRUMBS } from '@Utils/constants';
import { LABELS } from '@Utils/labels';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { AlertColor, Grid } from '@mui/material';
import './AssetType.scss';

import AssetTypeForm from './AssetTypeForm/AssetTypeForm';
import { SnackBarProps } from '~/Components/Shared/Snackbar/Snackbar.type';

const { BUTTONS, ASSET_TYPE, ALERTS, EMPTY_MESSAGES } = LABELS;
const { PREVIOUS_CRUMB, PREVIOUS_CRUMB_ROUTE, ASSET_TYPE_CRUMB } = SETTINGS_BREADCRUMBS;

const AssetType = () => {
    const [assetTypes, setAssetTypes] = useState<any[]>([]);
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isMessageOpen, setIsMessageOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<SnackBarProps>();
    const [defaultValues, setDefaultValues] = useState<any>();
    const [dataObtainFailed, setDataObtainFailed] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const showMessage = (pMessage: string, pSeverity: string) => {
        setIsMessageOpen(true);
        setMessage({
            alertSeverity: pSeverity as AlertColor,
            alertMessage: pMessage
        });
    };

    const getAssetType = async () => {
        setIsLoading(true);
        setDataObtainFailed(false);
        AssetTypeServices.getAssetTypes(currentPage, true)
            .then(({ data }) => {
                setAssetTypes(data);
            })
            .catch(({ response }) => {
                showErrorAlert(showMessage, response);
                setDataObtainFailed(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getAssetType();
    }, [currentPage]);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleCloseMessage = () => {
        setIsMessageOpen(false);
    };

    const handleCreation = () => {
        setIsUpdate(false);
        setDefaultValues({});
        toggleModal();
    };

    const handleUpdate = (data: any) => {
        setIsUpdate(true);
        setDefaultValues(data);
        toggleModal();
    };

    const updateAssetType = (data: any) => {
        AssetTypeServices.updateAssetType(defaultValues._id, data)
            .then(() => {
                showMessage(ALERTS.UPDATE_SUCCESS, ALERTS.SEVERITIES.SUCCESS);
                setCurrentPage(1);
                getAssetType();
                toggleModal();
            })
            .catch(({ response }) => showErrorAlert(showMessage, response));
    };

    const createAssetType = (data: any) => {
        AssetTypeServices.createAssetType(data)
            .then(() => {
                showMessage(ALERTS.CREATE_SUCCESS, ALERTS.SEVERITIES.SUCCESS);
                setCurrentPage(1);
                getAssetType();
                toggleModal();
            })
            .catch(({ response }) => showErrorAlert(showMessage, response));
    };

    const disableAssetType = (data: any) => {
        AssetTypeServices.disableAssetType(data._id)
            .then(() => {
                showMessage(ALERTS.DISABLED_SUCCESS, ALERTS.SEVERITIES.SUCCESS);
                setCurrentPage(1);
                getAssetType();
            })
            .catch(({ response }) => showErrorAlert(showMessage, response));
    };

    const onSearch = (values: any) => {
        setAssetTypes(values || []);
    };

    const renderMainComponent = (loading: boolean, failed: boolean) => {
        if (loading) {
            return (
                <Grid item xs={12}>
                    <Loading />
                </Grid>
            );
        }
        if (failed) {
            return <ServerError />;
        }
        return (
            <Grid item xs={12}>
                <CustomTable
                    columns={ASSET_TYPE_COLUMNS}
                    rows={assetTypes.docs}
                    edit
                    editHandler={handleUpdate}
                    disable
                    disableHandler={disableAssetType}
                    disableConfirmationMessage={ASSET_TYPE.DISABLE_MSG}
                    emptyMessage={EMPTY_MESSAGES.ASSET_TYPE}
                    emptyIcon={EmptyIcon}
                    itemCount={assetTypes.totalPages}
                    page={currentPage}
                    updatePage={setCurrentPage}
                />
            </Grid>
        );
    };

    return (
        <>
            <CustomBreadcrumbs
                current={ASSET_TYPE_CRUMB}
                previous={{ route: PREVIOUS_CRUMB_ROUTE, name: PREVIOUS_CRUMB }}
            />

            <Grid item xs={12}>
                <h1 className="ac2-title">{ASSET_TYPE.TITLE}</h1>
            </Grid>

            <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
                <Grid item xs={6}>
                    <CustomButton
                        label={BUTTONS.NEW}
                        uiType="secondary"
                        variant="contained"
                        onClick={handleCreation}
                        startIcon={<AddOutlinedIcon />}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <SearchBar
                        setSearchResults={onSearch}
                        searchService={(param) =>
                            AssetTypeServices.searchAssetType(param, currentPage)
                        }
                        setIsLoading={setIsLoading}
                        setServerError={setDataObtainFailed}
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
                {renderMainComponent(isLoading, dataObtainFailed)}
            </Grid>
            <CustomModal
                open={isModalOpen}
                handleClose={toggleModal}
                size="xs"
                title={isUpdate ? ASSET_TYPE.FORM.UPDATE_TITLE : ASSET_TYPE.FORM.CREATE_TITLE}
            >
                <div>
                    {isModalOpen && (
                        <AssetTypeForm
                            handlerSubmit={isUpdate ? updateAssetType : createAssetType}
                            defaultValues={defaultValues}
                        />
                    )}
                </div>
            </CustomModal>
            {message && (
                <CustomSnackbar
                    isOpen={isMessageOpen}
                    handleClose={handleCloseMessage}
                    alertMessage={message.alertMessage}
                    alertSeverity={message.alertSeverity}
                />
            )}
        </>
    );
};

export default AssetType;
