import { useContext, useEffect, useState } from 'react';

import './Home.scss';
import checkInAssetsIcon from '@Assets/Images/assets-check-in.svg';
import checkOutAssetsIcon from '@Assets/Images/assets-check-out.svg';
import assetsStatus from '@Assets/Images/assets-status.svg';
import readerServiceStatusIcon from '@Assets/Images/reader-service-status.svg';
import UserContext from '@Context/userContext';
import AlertServices from '@Services/Alert.services';
import DashboardService from '@Services/Dashboard.services';
import CustomButton from '@Shared/Button/Button';
import CustomIconButton from '@Shared/IconButton/IconButton';
import Loading from '@Shared/Loading/Loading';
import ServerError from '@Shared/ServerError/ServerError';
import CustomSnackbar from '@Shared/Snackbar/Snackbar';
import { showErrorAlert } from '@Utils/alerts';
import {
    ALERT_TYPES,
    AlertTypeProps,
    CANT_COMPARE,
    CARD_TEXT,
    CARD_TYPES,
    CHECK_IN,
    CHECK_OUT,
    DEFAULT_AMOUNT,
    DEFAULT_PERCENTAGE,
    FIRST_PAGE_NUMBER,
    OFFICES,
    PAGE_PROP,
    PATHS,
    POPOVER_ORIGIN,
    READER_API_STATUS,
    READER_STATUS,
    SIZES,
    WELCOME_TEXT
} from '@Utils/constants';
import { LABELS } from '@Utils/labels';
import { Notifications } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
    Alert,
    AlertColor,
    Badge,
    Box,
    Grid,
    IconButton,
    Popover,
    PopoverOrigin
} from '@mui/material';
import cx from 'classnames';
import { Link, useNavigate } from 'react-router-dom';

import { formatReaderServiceStatus, formatReaderServiceStatusWeeklyCount } from './Home.utils';
import { SnackBarProps } from '../Shared/Snackbar/Snackbar.type';
import { ReaderInfoResponse, ReaderStatusWeekly } from './Home.type';

const { ALERTS, MAINSCREEN, BUTTONS, DEFAULT_LINK_ASSET } = LABELS;

const cardsData: any[] = [
    {
        title: CHECK_OUT.CARD_TITLE,
        icon: checkOutAssetsIcon,
        iconAlt: CHECK_OUT.ICON_ALT,
        logTypeId: CARD_TYPES.ASSETS_CHECK_OUT
    },
    {
        title: CHECK_IN.CARD_TITLE,
        icon: checkInAssetsIcon,
        iconAlt: CHECK_IN.ICON_ALT,
        logTypeId: CARD_TYPES.ASSETS_CHECK_IN
    }
];

const Home = () => {
    const [dashboardIsLoading, setDashboardIsLoading] = useState<boolean>(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentPage] = useState<number>(1);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [cards, setCards] = useState<any[]>([]);
    const [assetsByAssetStatusInfo, setAssetsByAssetStatusInfo] = useState<any[]>([]);
    const [readerServiceStatusInfo, setReaderServiceStatusInfo] = useState<ReaderInfoResponse>();
    const [readerServiceFailedWeeklyCount, setReaderServiceFailedWeeklyCount] =
        useState<ReaderStatusWeekly>();
    const [dataObtainFailed, setDataObtainFailed] = useState<boolean>(false);
    const [alerts, setAlerts] = useState<any[]>([]);
    const [alertsLength, setAlertsLength] = useState<number>(0);
    const { user } = useContext(UserContext);
    const [isMessageOpen, setOpenMessage] = useState<boolean>(false);
    const [snackbar, setSnackbar] = useState<SnackBarProps>();
    const [alertsAreLoading, setAlertsAreLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const showMessage = (pMessage: string, pSeverity: string) => {
        setOpenMessage(true);
        setSnackbar({
            alertSeverity: pSeverity as AlertColor,
            alertMessage: pMessage
        });
    };

    const getNotViewedAlerts = (pAlerts: any[]) => {
        const notViewed = pAlerts.filter(
            (notification) => !notification.viewed && !notification.hide
        ).length;
        setAlertsLength(notViewed);
    };

    const togglePopover = () => {
        setIsOpen(!isOpen);
    };

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
        togglePopover();
    };

    const handleCloseMessage = () => {
        setOpenMessage(false);
    };

    const getSeverity = (type: string) => {
        let severity = '';
        Object.keys(ALERT_TYPES).forEach((key) => {
            if (ALERT_TYPES[key as AlertTypeProps].type === type) {
                severity = ALERT_TYPES[key as AlertTypeProps].severity;
            }
        });
        return severity;
    };

    const setAlertState = (id: string) => () => {
        setAlertsAreLoading(true);
        AlertServices.viewAlert(id)
            .then(({ data }) => {
                const newNotifications = alerts;
                newNotifications.unshift(data as never);
                setAlerts(newNotifications);
                getNotViewedAlerts(newNotifications);
            })
            .catch((error) => {
                showErrorAlert(showMessage, error);
            })
            .finally(() => setAlertsAreLoading(false));
    };

    const removeAlert = (id: string) => () => {
        setAlertsAreLoading(true);
        AlertServices.hideAlert(id)
            .then(() => {
                const newNotifications = alerts.filter((alert) => alert._id !== id);
                setAlerts(newNotifications);
                getNotViewedAlerts(newNotifications);
            })
            .catch((error) => {
                showErrorAlert(showMessage, error);
            })
            .finally(() => setAlertsAreLoading(false));
    };

    const getAlerts = () => {
        setAlertsAreLoading(true);
        AlertServices.updateAlerts(null, false)
            .then(({ data }) => {
                const alertsWithoutHidden = data.docs.filter((alert: any) => !alert.hide);
                setAlerts(alertsWithoutHidden);
                setAlertsLength(alertsWithoutHidden.length);
                getNotViewedAlerts(alertsWithoutHidden);
            })
            .catch(({ response }) => showErrorAlert(showMessage, response))
            .finally(() => setAlertsAreLoading(false));
    };

    const clickHandler = () => {
        navigate(`${PATHS.NOTIFICATIONS}?${PAGE_PROP}=${FIRST_PAGE_NUMBER}`);
    };

    useEffect(() => {
        setDashboardIsLoading(true);
        setDataObtainFailed(false);
        const getStatus = async () => {
            Promise.all([
                DashboardService.getReaderServiceStatus(),
                DashboardService.getWeeklyComparisons(),
                DashboardService.getAssetsByAssetStatus(),
                DashboardService.getWeeklyReaderServiceStatusCount()
            ])
                .then(
                    ([
                        readerServiceStatus,
                        weeklyComparisons,
                        amountAssetStatus,
                        countedReaderServiceStatus
                    ]) => {
                        const formattedReaderStatus =
                            formatReaderServiceStatus(readerServiceStatus);
                        setReaderServiceStatusInfo(formattedReaderStatus[0]);
                        const formattedReaderStatusWeeklyCount =
                            formatReaderServiceStatusWeeklyCount(
                                countedReaderServiceStatus,
                                READER_API_STATUS.FAILED,
                                OFFICES.TERRACAMPUS
                            );
                        cardsData.forEach((card: any) => {
                            const logTypesData = weeklyComparisons.data.weeklyComparisons.find(
                                (responseObject: any) => responseObject.logType === card.logTypeId
                            );
                            if (logTypesData) {
                                const cardIndex = cardsData.findIndex(
                                    (cardFind) => cardFind.logTypeId === logTypesData.logType
                                );
                                cardsData[cardIndex].percentage = logTypesData.percentage;
                                cardsData[cardIndex].amount = logTypesData.amount;
                            } else {
                                const cardIndex = cardsData.findIndex(
                                    (indexCard) => card.logTypeId === indexCard.logTypeId
                                );
                                cardsData[cardIndex].percentage = DEFAULT_PERCENTAGE;
                                cardsData[cardIndex].amount = DEFAULT_AMOUNT;
                            }
                        });
                        setCards(cardsData);
                        setAssetsByAssetStatusInfo(amountAssetStatus.data);
                        setReaderServiceFailedWeeklyCount(formattedReaderStatusWeeklyCount);
                    }
                )
                .catch(() => {
                    setDataObtainFailed(true);
                })
                .finally(() => {
                    setDashboardIsLoading(false);
                });
        };
        getStatus();
        getAlerts();
    }, []);

    const renderMainComponent = (loading: boolean, failed: boolean) => {
        if (loading) {
            return <Loading size={SIZES.LARGE} />;
        }
        if (failed) {
            return <ServerError />;
        }
        return (
            <>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={10}>
                        <h1 className="ac2-home__welcome">{`${WELCOME_TEXT} ${
                            user ? user.name : ''
                        }!`}</h1>
                    </Grid>

                    <Grid item xs={2}>
                        <Box display="flex" justifyContent="flex-end">
                            <IconButton onClick={handleClick}>
                                <Badge badgeContent={alertsLength} color="primary">
                                    <Notifications color="action" />
                                </Badge>
                            </IconButton>
                            <Popover
                                open={isOpen}
                                anchorEl={anchorEl}
                                onClose={togglePopover}
                                anchorOrigin={POPOVER_ORIGIN.ANCHOR as PopoverOrigin}
                                transformOrigin={POPOVER_ORIGIN.TRANSFORM as PopoverOrigin}
                            >
                                <Grid container className="ac2-popover" direction="column-reverse">
                                    <Grid item xs={12} className="ac2-popover-container">
                                        {alertsAreLoading ? (
                                            <Loading size={SIZES.EXTRA_SMALL} />
                                        ) : (
                                            alerts.map(({ _id, type, asset, message, viewed }) => (
                                                <Grid item xs={12} key={`${asset}-${type}`}>
                                                    <Alert
                                                        severity={getSeverity(type) as AlertColor}
                                                        className="ac2-alert"
                                                        onClose={removeAlert(_id)}
                                                    >
                                                        <b>{!viewed && `${ALERTS.NEW} - `}</b>
                                                        {message}{' '}
                                                        <Link
                                                            to={`${PATHS.ASSETS}/${asset}`}
                                                            className="ac2-link"
                                                            state={{ currentPage }}
                                                            onClick={setAlertState(_id)}
                                                        >
                                                            {DEFAULT_LINK_ASSET}
                                                        </Link>
                                                    </Alert>
                                                </Grid>
                                            ))
                                        )}
                                    </Grid>
                                    <Grid container alignItems="center" justifyContent="flex-end">
                                        <Grid item xs={8}>
                                            <h1 className="ac2-title">
                                                {LABELS.MAINSCREEN.NOTIFICATIONS}
                                            </h1>
                                        </Grid>
                                        <Grid item container xs={4} justifyContent="flex-end">
                                            <CustomIconButton
                                                onClick={togglePopover}
                                                icon={<CloseIcon />}
                                                tooltip={BUTTONS.CLOSE}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className="ac2-alert-btn">
                                            <CustomButton
                                                label={LABELS.MAINSCREEN.ALL_NOTIFICATIONS_BUTTON}
                                                uiType="secondary"
                                                variant="contained"
                                                onClick={clickHandler}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Popover>
                        </Box>
                    </Grid>
                </Grid>
                <h1 className="ac2-home__title">{MAINSCREEN.SUBTITLES.SCANNER_INFO}</h1>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="stretch"
                    rowSpacing={{ xs: 5, sm: 7, md: 3, lg: 6 }}
                    columnSpacing={{ xs: 1, sm: 6, md: 8, lg: 15, xl: 8 }}
                    className="ac2-home__grid"
                >
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        xl={3}
                        key={MAINSCREEN.READER_STATUS.CARD_TITLE}
                    >
                        <div className="ac2-home__card">
                            <div className="ac2-home__text-icon">
                                <h2 className="ac2-home__info">
                                    {MAINSCREEN.READER_STATUS.CARD_TITLE}
                                </h2>
                                <img
                                    src={readerServiceStatusIcon}
                                    alt={MAINSCREEN.READER_STATUS.ICON_ALT}
                                    className="ac2-home__icon"
                                />
                            </div>
                            <h2 className="ac2-home__amount">
                                <span
                                    className={cx({
                                        'ac2-home__amount--red':
                                            readerServiceStatusInfo?.status ===
                                                READER_STATUS.STOPPED ||
                                            readerServiceStatusInfo?.status ===
                                                READER_STATUS.FAILED ||
                                            readerServiceStatusInfo?.status ===
                                                READER_STATUS.CONNECTION_LOST,
                                        'ac2-home__percentage--green':
                                            readerServiceStatusInfo?.status ===
                                                READER_STATUS.RESTARTING ||
                                            readerServiceStatusInfo?.status ===
                                                READER_STATUS.RUNNING
                                    })}
                                >
                                    {readerServiceStatusInfo?.status}
                                </span>
                            </h2>
                            <p className="ac2-home">
                                {`${MAINSCREEN.READER_STATUS.OFFICE_TEXT}${readerServiceStatusInfo?.officeName}`}
                            </p>
                        </div>
                    </Grid>
                    {cards.map((card) => (
                        <Grid item xs={12} sm={6} md={6} xl={3} key={card.title}>
                            <div className="ac2-home__card">
                                <div className="ac2-home__text-icon">
                                    <h2 className="ac2-home__info">{card.title}</h2>
                                    <img
                                        src={card.icon}
                                        alt={card.iconAlt}
                                        className="ac2-home__icon"
                                    />
                                </div>
                                <h2 className="ac2-home__amount">{card.amount}</h2>
                                {card.percentage === DEFAULT_PERCENTAGE ? (
                                    <p className="ac2-home__percentage">{CANT_COMPARE}</p>
                                ) : (
                                    <p className="ac2-home__percentage">
                                        <span
                                            className={cx({
                                                'ac2-home__percentage--red': card.percentage < 0,
                                                'ac2-home__percentage--green': card.percentage >= 0
                                            })}
                                        >
                                            {`${card.percentage > 0 ? '+' : ''}${
                                                card.percentage
                                            }% `}
                                        </span>
                                        {CARD_TEXT}
                                    </p>
                                )}
                            </div>
                        </Grid>
                    ))}
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        xl={3}
                        key={MAINSCREEN.READER_STATUS_FAILED_COUNT.CARD_TITLE}
                    >
                        <div className="ac2-home__card">
                            <div className="ac2-home__text-icon">
                                <h2 className="ac2-home__info">
                                    {MAINSCREEN.READER_STATUS_FAILED_COUNT.CARD_TITLE}
                                </h2>
                                <img
                                    src={readerServiceStatusIcon}
                                    alt={MAINSCREEN.READER_STATUS_FAILED_COUNT.ICON_ALT}
                                    className="ac2-home__icon"
                                />
                            </div>
                            <h2 className="ac2-home__amount">
                                {readerServiceFailedWeeklyCount?.amount}
                            </h2>
                            <p className="ac2-home">
                                {`${MAINSCREEN.READER_STATUS.OFFICE_TEXT}${readerServiceFailedWeeklyCount?.officeName}`}
                            </p>
                        </div>
                    </Grid>
                </Grid>
                <h1 className="ac2-home__title">{MAINSCREEN.SUBTITLES.ASSETS_INFO}</h1>
                <Grid
                    container
                    rowSpacing={{ xs: 5, sm: 7, md: 8 }}
                    columnSpacing={{ xs: 1, sm: 6, md: 8, xl: 12 }}
                    className="ac2-home__grid"
                    alignItems="stretch"
                    justifyContent="space-between"
                >
                    {assetsByAssetStatusInfo.map((amountOfAssetsByStatus) => (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            xl={4}
                            key={amountOfAssetsByStatus.assetStatusDescription}
                        >
                            <div className="ac2-home__card">
                                <div className="ac2-home__text-icon">
                                    <h2 className="ac2-home__info">
                                        {`${amountOfAssetsByStatus.assetStatusDescription}${MAINSCREEN.ASSETS_BY_STATUS.CARD_TITLE}`}
                                    </h2>
                                    <img
                                        src={assetsStatus}
                                        alt={`${amountOfAssetsByStatus.assetStatusDescription}${MAINSCREEN.ASSETS_BY_STATUS.ICON_ALT}`}
                                        className="ac2-home__icon"
                                    />
                                </div>
                                <h2 className="ac2-home__amount">
                                    {amountOfAssetsByStatus.assetStatusAmount}
                                </h2>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </>
        );
    };

    return (
        <div className="ac2-home__container">
            {renderMainComponent(dashboardIsLoading, dataObtainFailed)}
            {snackbar && (
                <CustomSnackbar
                    isOpen={isMessageOpen}
                    handleClose={handleCloseMessage}
                    alertMessage={snackbar.alertMessage}
                    alertSeverity={snackbar.alertSeverity}
                />
            )}
        </div>
    );
};

export default Home;
