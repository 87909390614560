import { useState, useMemo, createContext, ReactNode } from 'react';

import PropTypes from 'prop-types';

interface UserProviderProps {
    children: ReactNode;
}

interface CurrentUserContextType {
    user: any;
    setUser: (user: any) => void;
}

const initialState: CurrentUserContextType = {
    user: null,
    setUser: () => {}
};

// eslint-disable-next-line prettier/prettier
const UserContext = createContext<CurrentUserContextType>(initialState);

export const UserProvider = ({ children }: UserProviderProps) => {
    const [user, setUser] = useState(initialState.user);
    const value = useMemo(
        () => ({
            user,
            setUser
        }),
        [user]
    );

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

UserProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

export default UserContext;
