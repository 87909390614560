import { API_ROUTES, HTTP_METHODS, PAGE_SIZE } from '@Utils/constants';
import { sendCall } from '@Utils/requestPrep';

const { ASSET_TYPE } = API_ROUTES;

class AssetTypeService {
    createAssetType = (params) =>
        sendCall(ASSET_TYPE.POST, {
            data: params,
            method: HTTP_METHODS.POST
        });

    updateAssetType = (id, params) =>
        sendCall(`${ASSET_TYPE.PUT}/${id}`, { data: params, method: HTTP_METHODS.PUT });

    getAssetTypes = (page, pagination) =>
        sendCall(`${ASSET_TYPE.GET}?page=${page}&limit=${PAGE_SIZE}&pagination=${pagination}`, {
            method: HTTP_METHODS.GET
        });

    disableAssetType = (id) =>
        sendCall(`${ASSET_TYPE.DISABLE}/${id}`, { method: HTTP_METHODS.POST });

    searchAssetType = (params, page) =>
        sendCall(`${ASSET_TYPE.SEARCH_POST}?page=${page}&limit=${PAGE_SIZE}`, {
            data: params,
            method: HTTP_METHODS.POST
        });
}

export default new AssetTypeService();
