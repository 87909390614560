import { useEffect, useState } from 'react';

import EmptyIcon from '@Assets/Images/empty-settings.svg';
import EmailServices from '@Services/Email.services';
import CustomBreadcrumbs from '@Shared/Breadcrumbs/Breadcrumbs';
import CustomButton from '@Shared/Button/Button';
import Loading from '@Shared/Loading/Loading';
import CustomModal from '@Shared/Modal/Modal';
import SearchBar from '@Shared/SearchBar/SearchBar';
import ServerError from '@Shared/ServerError/ServerError';
import CustomSnackbar from '@Shared/Snackbar/Snackbar';
import CustomTable from '@Shared/Table/Table';
import { showErrorAlert } from '@Utils/alerts';
import { EMAIL_COLUMNS, SETTINGS_BREADCRUMBS } from '@Utils/constants';
import { LABELS } from '@Utils/labels';
import { MailOutlined } from '@mui/icons-material';
import { AlertColor, Grid } from '@mui/material';

import './Email.scss';
import EmailForm from './EmailForm/EmailForm';
import WelcomeEmailForm from './WelcomeForm/WelcomeForm';
import { SnackBarProps } from '~/Components/Shared/Snackbar/Snackbar.type';

const { BUTTONS, EMAIL, ALERTS, EMPTY_MESSAGES } = LABELS;
const { PREVIOUS_CRUMB, PREVIOUS_CRUMB_ROUTE, EMAIL_CRUMB } = SETTINGS_BREADCRUMBS;

const Email = () => {
    const [emails, setEmails] = useState<any>({ docs: [], totalItems: 0 });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isEmailModalOpen, setIsEmailModalOpen] = useState<boolean>(false);
    const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState<boolean>(false);
    const [isMessageOpen, setIsMessageOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<SnackBarProps>();
    const [defaultValues, setDefaultValues] = useState<any>();
    const [dataObtainFailed, setDataObtainFailed] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const showMessage = (pMessage: string, pSeverity: string) => {
        setIsMessageOpen(true);
        setMessage({
            alertSeverity: pSeverity as AlertColor,
            alertMessage: pMessage
        });
    };

    const getEmails = async () => {
        setIsLoading(true);
        setDataObtainFailed(false);
        EmailServices.getEmails(currentPage, true)
            .then(({ data }) => {
                setEmails(data);
            })
            .catch(({ response }) => {
                showErrorAlert(showMessage, response);
                setDataObtainFailed(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getEmails();
    }, [currentPage]);

    const toggleEmail = () => {
        setIsEmailModalOpen(!isEmailModalOpen);
    };

    const toggleWelcome = () => {
        setIsWelcomeModalOpen(!isWelcomeModalOpen);
    };

    const handleCloseMessage = () => {
        setIsMessageOpen(false);
    };

    const handleUpdate = (data: any) => {
        setDefaultValues(data);
        toggleEmail();
    };

    const updateEmail = (values: any) => {
        const newValues = { emailID: defaultValues._id, ...values };
        EmailServices.updateEmail(newValues)
            .then(() => {
                showMessage(ALERTS.UPDATE_SUCCESS, ALERTS.SEVERITIES.SUCCESS);
                setCurrentPage(1);
                toggleEmail();
                getEmails();
            })
            .catch(({ response }) => showErrorAlert(showMessage, response));
    };

    const sendWelcomeEmail = (values: any) => {
        EmailServices.welcomeEmail(values)
            .then(() => {
                showMessage(ALERTS.EMAIL_SUCCESS, ALERTS.SEVERITIES.SUCCESS);
                toggleWelcome();
            })
            .catch(({ response }) => showErrorAlert(showMessage, response));
    };

    const onSearch = (values: any) => {
        setEmails(values || []);
    };

    const renderMainComponent = (loading: boolean, failed: boolean) => {
        if (loading) {
            return (
                <Grid item xs={12}>
                    <Loading />
                </Grid>
            );
        }
        if (failed) {
            return <ServerError />;
        }
        return (
            <Grid item xs={12}>
                <CustomTable
                    columns={EMAIL_COLUMNS}
                    rows={emails.docs}
                    edit
                    editHandler={handleUpdate}
                    emptyMessage={EMPTY_MESSAGES.EMAILS}
                    emptyIcon={EmptyIcon}
                    itemCount={emails.totalPages}
                    page={currentPage}
                    updatePage={setCurrentPage}
                />
            </Grid>
        );
    };

    return (
        <>
            <CustomBreadcrumbs
                current={EMAIL_CRUMB}
                previous={{ route: PREVIOUS_CRUMB_ROUTE, name: PREVIOUS_CRUMB }}
            />
            <Grid item xs={12}>
                <h1 className="ac2-title">{EMAIL.TITLE}</h1>
            </Grid>

            <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
                <Grid item xs={6}>
                    <CustomButton
                        label={BUTTONS.WELCOME}
                        uiType="secondary"
                        variant="contained"
                        onClick={toggleWelcome}
                        startIcon={<MailOutlined />}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <SearchBar
                        setSearchResults={onSearch}
                        searchService={(param) => EmailServices.searchEmail(param, currentPage)}
                        setIsLoading={setIsLoading}
                        setServerError={setDataObtainFailed}
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
                {renderMainComponent(isLoading, dataObtainFailed)}
            </Grid>

            <CustomModal
                open={isEmailModalOpen}
                handleClose={toggleEmail}
                size="sm"
                title={EMAIL.FORM.UPDATE_TITLE}
            >
                <div>
                    {isEmailModalOpen && (
                        <EmailForm handlerSubmit={updateEmail} defaultValues={defaultValues} />
                    )}
                </div>
            </CustomModal>

            <CustomModal
                open={isWelcomeModalOpen}
                handleClose={toggleWelcome}
                size="xs"
                title={EMAIL.WELCOME_FORM.TITLE}
            >
                <div>
                    {isWelcomeModalOpen && <WelcomeEmailForm handlerSubmit={sendWelcomeEmail} />}
                </div>
            </CustomModal>
            {message && (
                <CustomSnackbar
                    isOpen={isMessageOpen}
                    handleClose={handleCloseMessage}
                    alertMessage={message.alertMessage}
                    alertSeverity={message.alertSeverity}
                />
            )}
        </>
    );
};

export default Email;
