import { LABELS } from '@Utils/labels';
import { Link } from 'react-router-dom';
import './Table.scss';
import { RowDetailsProps } from './Table.type';

const RowDetails = ({ label, url, idParam, page, searchParam }: RowDetailsProps) => {
    return (
        <Link to={`${url}/${idParam}`} className="ac2-link" state={{ page, searchParam }}>
            {label || LABELS.DEFAULT_LINK_LABEL}
        </Link>
    );
};

export default RowDetails;
