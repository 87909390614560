import React, { useEffect, useState } from 'react';

import EmptyIcon from '@Assets/Images/empty-box.svg';
import AdminLogService from '@Services/AdminLog.services';
import CustomBreadcrumbs from '@Shared/Breadcrumbs/Breadcrumbs';
import Loading from '@Shared/Loading/Loading';
import ServerError from '@Shared/ServerError/ServerError';
import CustomSnackbar from '@Shared/Snackbar/Snackbar';
import Table from '@Shared/Table/Table';
import { showErrorAlert } from '@Utils/alerts';
import {
    ADMIN_LOG_COLUMNS,
    FIRST_PAGE_NUMBER,
    PAGE_PROP,
    PARSE_INT_BASE_NUMBER,
    PATHS
} from '@Utils/constants';
import { getDateString } from '@Utils/dates';
import { LABELS } from '@Utils/labels';
import { AlertColor, Grid } from '@mui/material';
import { useLocation, useSearchParams } from 'react-router-dom';
import { SnackBarProps } from '~/Components/Shared/Snackbar/Snackbar.type';

const { EMPTY_MESSAGES } = LABELS;

const AdminLogs = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isMessageOpen, setIsMessageOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<SnackBarProps>();
    const [logs, setLogs] = useState({ docs: [], totalPages: 0 });
    const [dataObtainFailed, setDataObtainFailed] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const location = useLocation();

    const showMessage = (pMessage: string, pSeverity: string) => {
        setIsMessageOpen(true);
        setMessage({
            alertSeverity: pSeverity as AlertColor,
            alertMessage: pMessage
        });
    };

    const prepAdminLogs = (adminLogs: any) => {
        adminLogs.loggedAt = getDateString(adminLogs.loggedAt);
        return adminLogs;
    };

    const getAdminLogs = () => {
        setDataObtainFailed(false);
        setIsLoading(true);
        AdminLogService.getAdminLogs(currentPage)
            .then(({ data }) => {
                data.docs = data.docs.map(prepAdminLogs);
                setLogs(data);
            })
            .catch(({ response }) => {
                showErrorAlert(showMessage, response);
                setDataObtainFailed(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getAdminLogs();
    }, [currentPage]);

    useEffect(() => {
        if (searchParams.get(PAGE_PROP)) {
            setCurrentPage(parseInt(searchParams.get(PAGE_PROP) || '', PARSE_INT_BASE_NUMBER));
        }
    }, [location]);

    const handleCloseMessage = () => {
        setIsMessageOpen(false);
    };

    const renderMainComponent = (loading: boolean, failed: boolean) => {
        if (loading) {
            return <Loading />;
        }
        if (failed) {
            return <ServerError />;
        }
        return (
            <Grid item xs={12} sm={12} md={12}>
                <Table
                    columns={ADMIN_LOG_COLUMNS}
                    rows={logs.docs}
                    emptyMessage={EMPTY_MESSAGES.ADMIN_LOG}
                    emptyIcon={EmptyIcon}
                    detailsPath={PATHS.ASSETS}
                    page={currentPage}
                    updatePage={setCurrentPage}
                    itemCount={logs.totalPages}
                />
            </Grid>
        );
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
                <CustomBreadcrumbs
                    current={LABELS.ADMIN_LOG.TITLE}
                    previous={{
                        route: `${PATHS.USERS}?${PAGE_PROP}=${FIRST_PAGE_NUMBER}`,
                        name: LABELS.USERS.COMPONENT_NAME
                    }}
                />
                <h1>{LABELS.ADMIN_LOG.TITLE}</h1>
            </Grid>
            {renderMainComponent(isLoading, dataObtainFailed)}
            {message && (
                <CustomSnackbar
                    isOpen={isMessageOpen}
                    handleClose={handleCloseMessage}
                    alertMessage={message.alertMessage}
                    alertSeverity={message.alertSeverity}
                />
            )}
        </Grid>
    );
};

export default AdminLogs;
