import { LABELS } from '@Utils/labels';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import cx from 'classnames';

import './Table.scss';
import RowDetails from './RowDetails';
import TableOptions from './TableOptions';
import { TableProps } from './Table.type';

const { TABLE } = LABELS;

const MobileTable = ({
    rows,
    columns,
    ariaLabel,
    edit,
    info,
    disable,
    download,
    infoHandler,
    editHandler,
    disableHandler,
    disableConfirmationMessage,
    downloadHandler,
    details,
    detailsLabel,
    detailsPath,
    page,
    idField,
    searchParam
}: TableProps) => {
    const hasOptions = disable || edit || download || info;

    const handleInfo = (row: any) => () => infoHandler(row);

    const handleUpdate = (row: any) => () => editHandler(row);

    const handleDisable = (row: any) => () => disableHandler(row);

    const handleDownload = (row: any) => () => downloadHandler(row);

    const renderCell = (row: any, column: any) => (
        <TableRow key={`${row._id}-${column.header}`} className="ac2-mobile-row-column">
            <TableCell className="ac2-column-mobile ac2-table__font--header">
                {column.header}
            </TableCell>
            <TableCell
                className={cx('ac2-row-field', {
                    'ac2-icon-cell': column.icon,
                    'ac2-text-cell--mobile': !column.icon
                })}
            >
                {column.icon ? column.icon[row[column.field]] : row[column.field] || column.default}
            </TableCell>
        </TableRow>
    );

    return (
        <Table
            size="small"
            aria-label={ariaLabel || ''}
            sx={{ tableLayout: 'fixed', marginBottom: '2rem' }}
        >
            <TableBody>
                {rows.map((row) => (
                    <TableRow key={row._id} className="ac2-table__row--division">
                        <TableCell className="ac2-row-mobile">
                            <Table>
                                <TableBody>
                                    {columns.map((column) => renderCell(row, column))}
                                    {details && (
                                        <TableRow
                                            key={`${row._id}-${detailsLabel}`}
                                            className="ac2-mobile-row-column"
                                        >
                                            <TableCell className="ac2-column-mobile ac2-table__font--header">
                                                {detailsLabel}
                                            </TableCell>
                                            <TableCell className="ac2-row-details">
                                                {row[idField] === undefined ? (
                                                    TABLE.NO_ASSET_DETAILS
                                                ) : (
                                                    <RowDetails
                                                        url={detailsPath}
                                                        idParam={row[idField]}
                                                        page={page}
                                                        searchParam={searchParam}
                                                    />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    )}

                                    {hasOptions && (
                                        <TableRow
                                            key={`${row._id}-options`}
                                            className="ac2-mobile-row-column"
                                        >
                                            <TableCell className="ac2-column-mobile ac2-table__font--header">
                                                {download ? TABLE.ALT_ACTION : TABLE.ACTIONS}
                                            </TableCell>
                                            <TableCell className="ac2-row-field">
                                                <TableOptions
                                                    rowId={row._id}
                                                    info={info || false}
                                                    edit={edit || false}
                                                    disable={disable || false}
                                                    download={download || false}
                                                    editHandler={handleUpdate(row)}
                                                    infoHandler={handleInfo(row)}
                                                    disableHandler={handleDisable(row)}
                                                    disableConfirmationMessage={
                                                        disableConfirmationMessage
                                                    }
                                                    downloadHandler={handleDownload}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default MobileTable;
