import React, { useContext } from 'react';

import { NotFound } from '@Components/exporter';
import UserContext from '@Context/userContext';
import { checkRoles } from '@Utils/auth';
import PropTypes from 'prop-types';

export const PrivateRoute = ({ children, roles, redirect = <NotFound /> }) => {
    const { user } = useContext(UserContext);

    return checkRoles(roles, user) ? children : redirect;
};

PrivateRoute.propTypes = {
    children: PropTypes.node,
    roles: PropTypes.array
};
