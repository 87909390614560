import { BTN_SIZE, ButtonSizes } from '@Utils/constants';
import { IconButton, Tooltip } from '@mui/material';
import cx from 'classnames';
import './IconButton.scss';
import { CustomIconButtonProps } from './IconButton.type';

const CustomIconButton = ({
    classes,
    icon,
    onClick,
    size = BTN_SIZE.MEDIUM as ButtonSizes,
    variant,
    tooltip
}: CustomIconButtonProps) => {
    return (
        <Tooltip title={tooltip} placement="bottom" arrow describeChild>
            <IconButton
                className={cx('ac2-icon-btn', `ac2-icon-btn--${variant}`, classes)}
                onClick={onClick}
                size={size}
                disableRipple
            >
                {icon}
            </IconButton>
        </Tooltip>
    );
};

export default CustomIconButton;
