import { Location } from 'react-router';
import {
    ADMIN_ROLES,
    FIRST_PAGE_NUMBER,
    PAGE_PROP,
    PATHS,
    REGULAR_ROLES,
    SUPER_ADMIN_ROLES
} from '@Utils/constants';
import { LABELS } from '@Utils/labels';

const { HEADER } = LABELS;
const { TABS } = HEADER;

export const pages = (accessLevel: number) => ({
    HOME: {
        label: TABS.HOME,
        path: PATHS.HOME,
        roles: ADMIN_ROLES
    },
    ASSETS: {
        label: accessLevel === REGULAR_ROLES[0] ? TABS.MY_ASSETS : TABS.ASSETS,
        path: `${PATHS.ASSETS}?${PAGE_PROP}=${FIRST_PAGE_NUMBER}`,
        roles: REGULAR_ROLES
    },
    REPORTS: {
        label: TABS.REPORTS,
        path: `${PATHS.REPORTS}?${PAGE_PROP}=${FIRST_PAGE_NUMBER}`,
        roles: ADMIN_ROLES
    },
    USERS: {
        label: TABS.USERS,
        path: `${PATHS.USERS}?${PAGE_PROP}=${FIRST_PAGE_NUMBER}`,
        roles: SUPER_ADMIN_ROLES
    },
    SETTINGS: { label: TABS.SETTINGS, path: PATHS.SETTINGS, roles: ADMIN_ROLES }
});

export const findActualPage = (location: Location, accessLevel: number) => {
    const paths = location.pathname.split('/');
    const found = Object.values(pages(accessLevel)).find(
        (page) => page.path.split('?')[0] === `/${paths[1]}`
    );

    if (!found) {
        return { label: HEADER.WEB_TITLE, path: PATHS.HOME };
    }
    return found;
};
