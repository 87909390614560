import { API_ROUTES, HTTP_METHODS, PAGE_SIZE } from '@Utils/constants';
import { sendCall } from '@Utils/requestPrep';

const { USERS } = API_ROUTES;

class AdminService {
    getAdminTypes = () =>
        sendCall(USERS.GET_ADMIN_TYPES, {
            method: HTTP_METHODS.GET
        }).then((result) =>
            result.data.map((fetchedRole) => ({
                // eslint-disable-next-line dot-notation
                id: fetchedRole['_id'],
                description: fetchedRole.Description
            }))
        );

    getAdmins = (page, pagination) =>
        sendCall(`${USERS.GET_ADMIN}?page=${page}&limit=${PAGE_SIZE}&pagination=${pagination}`, {
            method: HTTP_METHODS.GET
        });

    createAdmin = (name, email, role) => {
        const body = {
            Name: name,
            Email: email,
            UserType: role
        };

        return sendCall(USERS.CREATE_ADMIN, {
            method: HTTP_METHODS.POST,
            data: body
        }).then(({ data }) => ({
            // eslint-disable-next-line dot-notation
            _id: data.result['_id'],
            Name: data.result.Name,
            Email: data.result.Email,
            Role: data.result.UserType
        }));
    };

    editAdmin = (id, role) => {
        const body = {
            UserType: role
        };

        return sendCall(`${USERS.EDIT_ADMIN}/${id}`, {
            method: HTTP_METHODS.POST,
            data: body
        }).then(({ data }) => ({
            // eslint-disable-next-line dot-notation
            _id: data.result['_id'],
            Name: data.result.Name,
            Email: data.result.Email,
            Role: data.result.UserType
        }));
    };

    disableAdmin = (id) =>
        sendCall(`${USERS.DISABLE_ADMIN}/${id}`, {
            method: HTTP_METHODS.POST
        });

    getAdminSearch = (params, page) =>
        sendCall(`${USERS.SEARCH_POST}?page=${page}&limit=${PAGE_SIZE}`, {
            data: params,
            method: HTTP_METHODS.POST
        });
}

export default new AdminService();
